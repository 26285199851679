import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@src/components/ui/accordion";
import { cityNames, menuLinks as MenuLinks } from "../data";
import classNames from "clsx";
import { extractHref } from "../data";

export const MobileMenuLinks = ({
  navLinks,
  setIsSheetOpen,
  setIsMobileMenuOpen,
}: {
  navLinks: typeof MenuLinks;
  setIsSheetOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div className=" w-full h-full mt-4">
      <div className="">
        <ul className="flex flex-col text-offWhite font-medium">
          {navLinks.map((link) => {
            if (link.subMenu) {
              return (
                <li key={link.name} className="border-b pb-1 mb-2">
                  <Accordion type="single" collapsible>
                    <AccordionItem
                      value="ourProducts"
                      className="rounded-md border-none"
                    >
                      <AccordionTrigger
                        className={classNames(
                          "text-[#4E4B66] text-[17px] rounded-md !no-underline px-2 !py-2",
                          "data-[state=open]:bg-primary-primary data-[state=open]:text-[#FCFCFC] [&[data-state=open]>svg]:text-white"
                        )}
                      >
                        {link.name}
                      </AccordionTrigger>
                      <AccordionContent className="ml-4 mt-4">
                        {link.subMenu.map((subLink) => {
                          return (
                            <Accordion
                              type="single"
                              //   defaultValue={subLink.name}
                              collapsible
                              className="mb-2"
                            >
                              <AccordionItem
                                value={subLink.name}
                                className="rounded-md border-none"
                              >
                                <AccordionTrigger
                                  className={classNames(
                                    "text-[#4E4B66] text-[17px] rounded-md !no-underline px-2 !py-2",
                                    "data-[state=open]:bg-primary-primary data-[state=open]:text-[#FCFCFC] [&[data-state=open]>svg]:text-white"
                                  )}
                                >
                                  {subLink.name}
                                </AccordionTrigger>
                                <AccordionContent className="mt-4">
                                  <ul>
                                    {cityNames.map((city) => (
                                      <li
                                        key={city}
                                        className="border-b px-2 py-2 hover:bg-[#EEF1FF] hover:text-primary hover:rounded-md flex justify-between"
                                      >
                                        <a
                                          href={subLink.path?.replace(
                                            "[city]",
                                            city === "Other City"
                                              ? "bengaluru"
                                              : city.toLowerCase()
                                          )}
                                          className="p-1 text-[#4E4B66] text-[17px] inline-block w-full"
                                        >
                                          {city}
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </AccordionContent>
                              </AccordionItem>
                            </Accordion>
                          );
                        })}
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </li>
              );
            } else {
              return (
                <li key={link.name} className="border-b px-2 py-2 mb-2">
                  <a
                    href={extractHref(link.path)}
                    className="p-1 text-[#4E4B66] text-[17px] inline-block w-full"
                    onClick={() => {
                      if (extractHref(link.path).includes("#")) {
                        setIsSheetOpen(false);
                        setIsMobileMenuOpen(false);
                      }
                    }}
                  >
                    {link.name}
                  </a>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
};
