export const StepsData = [
  {
    icon: "choice",
    title: "Choose the product that suits you the best",
  },
  {
    icon: "clipboard-tick",
    title: "Book the Perfect Plan for You",
  },
  {
    icon: "notes",
    title: "Submit your details",
  },
  {
    icon: "ruppe-symbol",
    title: "Make the Payment",
  },
  {
    icon: "clock-countdown",
    title: "Get DrinkPrime Installed in 48 hours!",
  },
  {
    icon: "app-icon",
    title: "Connect your device to DrinkPrime app",
  },
] as const;

export const appCarouselImages = [
  { imageName: "app/water-report", alt: "Drinkprime App Water Report Feature" },
  { imageName: "app/recharge", alt: "Drinkprime App Recharge Plans Feature" },
  { imageName: "app/helpdesk", alt: "Drinkprime App Helpdesk Feature" },
  { imageName: "app/plan", alt: "Drinkprime App Subscription Plans Feature" },
  {
    imageName: "app/refer-and-earn",
    alt: "Refer and Earn from Drinkprime App",
  },
] as const;

export type AppCarouselImages = (typeof appCarouselImages)[number]["imageName"];
