//test-url: https://drinkprime.in/app/lp105?utm_source=g&utm_campaign=CT-Search-Exact-Brand-BLR-Sep23&utm_medium=Exact&utm_locationinterest=&utm_searchkeyword=drinkprime&utm_keywordid=kwd-818940458128&gad=1&gclid=EAIaIQobChMIlYndw7n6gQMVgxmDAx03BQG6EAAYASAAEgKb9fD_BwE

import {
  cityValueType,
  getItemFromLocalStorage,
  setItemInLocalStorage,
} from "./plans";
import { pushDataLayer } from "./dataLayer";
import urlService, {
  getFromLocalVisitObject,
  localStorageVisitObject,
} from "./urlLocalServices";
import { getBodyForLeadsquare } from "../sections/LeadFormSection/leadFormApi/getBodyForLeadsquare";

type PageType = "homepage";

export async function lpVisitService(lpName: PageType) {
  setVisitToLocalStorage("first_visit", lpName)
    .then(() => setVisitToLocalStorage("session_visit", lpName))
    .then(() => sendRevisitTracker())
    .then(() => pushDataLayer("landingpage_loaded"));
}

export default async function setVisitToLocalStorage(
  visit: "session_visit" | "first_visit",
  pageName: PageType
) {
  //add validation(with getlPFromURL) if current page is lp of type PageType or not for write access to localstorage
  // if (!urlService().lpFromUrl) return null;
  const duration = visit === "session_visit" ? 3 : 60; // in days
  const durationMs = duration * 24 * 3600 * 1000; // in ms
  const { visit_date } = getFromLocalVisitObject(visit);
  // set/overwrite to localstorage for 2 cases - when object doesn't exist and
  // when more than duration (1day)
  if (
    !visit_date ||
    new Date().getTime() - new Date(visit_date).getTime() > durationMs
  ) {
    const trackRevisit_local = getItemFromLocalStorage("trackRevisit") || "";
    const {
      utmSource,
      utmCampaign,
      utmMedium,
      utmKeyword,
      utmContent,
      gClid,
      fClid,
    } = urlService();
    const visitObject: localStorageVisitObject = {
      visit_date: new Date(),
      landing_page: pageName,
      user_id: trackRevisit_local?.user_id || null,
      utm_params: {
        utm_source: utmSource,
        utm_campaign: utmCampaign,
        utm_medium: utmMedium,
        utm_keyword: utmKeyword,
        utm_content: utmContent,
        g_clid: gClid,
        f_clid: fClid,
      },
    };
    setItemInLocalStorage(visit, visitObject);
  }
}

export type trackRevisitType = {
  user_id: `DP-${trackRevisitType["phone"]}`;
  name: string;
  phone: string;
  city: Exclude<cityValueType, "Choose City">;
  otherCity?: string;
  createdDate: Date;
  revisitDate: Date;
};

export const sendRevisitTracker = async () => {
  const nDays = 180; // No of days to preserve form details in local storage
  const nMs = nDays * 24 * 3600 * 1000; //Equivalent milliseconds
  const tresholdDuration = 72; // minumum time difference (in hours) to send user revisit info to leadsquared
  // in milliseconds
  const tresholdDurationMs = tresholdDuration * 3600 * 1000;
  // const tresholdDurationMs=10;
  const trackRevisit_local = getItemFromLocalStorage("trackRevisit") || "";
  const userDetails: trackRevisitType = trackRevisit_local;

  if (userDetails) {
    const { phone, createdDate, revisitDate } = userDetails;
    //Removing track revisit if more than duration(180 days)
    if (
      phone &&
      createdDate &&
      new Date().getTime() - new Date(createdDate).getTime() > nMs
    ) {
      try {
        localStorage.removeItem("trackRevisit");
      } catch (error) {
        console.log("Error while removing trackRevisit", error);
      }
    } else if (
      phone &&
      revisitDate &&
      new Date().getTime() - new Date(revisitDate).getTime() >
        tresholdDurationMs
    ) {
      pushDataLayer("w_customer_revisted");
      try {
        const data1 = getBodyForLeadsquare(
          // @ts-expect-error types of property types are incompatible
          {
            ...userDetails,
            mobile: userDetails["phone"],
          },
          "revisit-on-pageload"
        );

        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data1),
        };
        const response = await fetch(
          `https://api.drinkprime.in/website/leadSquared`,
          options
        );
        if (response.ok) {
          setItemInLocalStorage("trackRevisit", {
            ...userDetails,
            revisitDate: new Date(),
          });
          console.log("Sent user revisit tracking info");
        } else {
          throw new Error("Response failed");
        }
      } catch (err) {
        console.error(err, "User Revisit Tracking failed");
      }
    }
  }
};

export type session_visit = {
  landing_page: string;
  utm_params: utmParamsType;
};

export type utmParamsType = {
  utm_source: string;
  utm_campaign: string;
  utm_medium: string;
  utm_keyword: string;
  utm_content: string;
  g_clid: string;
  f_clid: string;
};
