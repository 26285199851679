import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
} from "@src/components/ui/popover";
import { Icons } from "@src/components/icons";
import classNames from "clsx";
import { useEffect } from "react";
import "./testimonialCard.css";
import { CarouselApi } from "@src/components/ui/carousel";

export const TestimonialCard = ({
  name,
  position,
  ytVideoId,
  testimonial,
  carouselApi,
}: {
  name: string;
  position: string;
  ytVideoId: string;
  testimonial: string;
  carouselApi: CarouselApi;
}) => {
  useEffect(() => {
    function labnolIframe(div: HTMLElement): void {
      const iframe = document.createElement("iframe");
      const videoId = div.dataset.id;

      if (!videoId) return; // Ensure `videoId` exists

      iframe.setAttribute(
        "src",
        `https://www.youtube.com/embed/${videoId}?autoplay=1`
      );
      iframe.setAttribute("frameborder", "0");
      iframe.setAttribute("allowfullscreen", "1");
      iframe.setAttribute(
        "allow",
        "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      );
      div.parentNode?.replaceChild(iframe, div); // Use optional chaining
    }

    function initYouTubeVideos(): void {
      const playerElements =
        document.querySelectorAll<HTMLElement>(".youtube-player");
      playerElements.forEach((element) => {
        const videoId = element.dataset.id;
        if (!videoId) return; // Ensure `videoId` exists

        const div = document.createElement("div");
        div.setAttribute("data-id", videoId);

        const thumbNode = document.createElement("img");
        thumbNode.src = `//i.ytimg.com/vi/${videoId}/hqdefault.jpg`;
        thumbNode.alt = "YouTube video thumbnail";
        div.appendChild(thumbNode);

        const playButton = document.createElement("div");
        playButton.setAttribute("class", "play");
        div.appendChild(playButton);

        div.onclick = function () {
          labnolIframe(this as HTMLElement);
          if (carouselApi?.plugins()?.autoplay.isPlaying()) {
            carouselApi?.plugins()?.autoplay.stop();
          }
        };
        element.appendChild(div);
      });
    }

    initYouTubeVideos();
  });
  return (
    <div className="max-w-[340px] rounded-t-[30px] border border-primary-primary">
      <div className="rounded-t-[30px] h-[195px]">
        <div className="youtube-player" data-id={ytVideoId}></div>
        {/* <iframe
          className="w-full h-full rounded-t-[30px]"
          src={videoUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          onClick={(e) => console.log(e)}
          loading="lazy"
        ></iframe> */}
      </div>
      <div
        className={classNames(
          "w-full max-w-[345px] p-[72px_20px_20px] md:max-w-[520px] md:p-[60px_32px_20px]",
          "relative h-full select-none rounded-t-none rounded-[30px] bg-primary-background"
        )}
      >
        <div className={classNames("absolute left-4 top-4")}>
          <Icons name="quotation-mark" color="#9FADF4" size={[40, 40]} />
        </div>
        <p className="mb-5 text-[15px] md:text-base h-full max-h-[130px] text-title md:mb-6 md:max-h-[160px]">
          {testimonial.length > 200 ? (
            <>
              {testimonial.slice(0, 200)}{" "}
              <Popover>
                <PopoverTrigger className="cursor-pointer text-primary-primary">
                  ...read more
                </PopoverTrigger>
                <PopoverContent
                  className="overflow-auto rounded-2xl border-none text-sm leading-6 shadow md:text-base z-[9999]"
                  collisionPadding={30}
                >
                  {testimonial}
                  <PopoverArrow className="PopoverArrow fill-white" />
                </PopoverContent>
              </Popover>
            </>
          ) : (
            testimonial
          )}
        </p>
        <div className="flex flex-col items-start justify-start gap-2">
          <div className="text-2xl font-bold capitalize text-black">{name}</div>
          <div className="text-black/60 text-[17px] font-medium  capitalize">
            {position}
          </div>
        </div>
      </div>
    </div>
  );
};
