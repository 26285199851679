import React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@src/lib/utils";

const defaultStyles = {
  heading: [
    "text-2xl md:text-4xl ",
    "text-titleActive font-bold md:text-center",
    "!leading-[30px] md:!leading-[46px]",
  ],
  desc: [
    "text-sm md:text-base",
    "text-labelIconsGrey font-normal md:text-center",
    "!leading-[21px] md:!leading-[24px]",
  ],
};

const headingVariants = cva("", {
  variants: {
    variant: {
      default: defaultStyles.heading,
      light: [
        ...defaultStyles.heading,
        "text-[#FDFDFD] !text-2xl md:!text-[40px] !font-semibold",
      ],
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

const descriptionVariants = cva("mt-2", {
  variants: {
    variant: {
      default: defaultStyles.desc,
      light: [...defaultStyles.desc, "text-inputBackground md:!text-lg"],
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

type SectionHeadingProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof headingVariants> & {
    as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    description?: string | React.ReactNode;
    children: React.ReactNode;
    headingStyle?: string;
    descriptionStyle?: string;
  };

const SectionHeading: React.FC<SectionHeadingProps> = ({
  children,
  className,
  as: Component = "h2",
  variant = "default",
  description,
  headingStyle,
  descriptionStyle,
  ...props
}) => {
  return (
    <div {...props} className={cn("", className)}>
      <Component className={cn(headingVariants({ variant }), headingStyle)}>
        {children}
      </Component>
      {description && (
        <p className={cn(descriptionVariants({ variant }), descriptionStyle)}>
          {description}
        </p>
      )}
    </div>
  );
};

export default SectionHeading;
