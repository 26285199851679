import React from "react";
import { getImageUrl, ImageKeysType } from "./types";
import { cn } from "@src/lib/utils";

type Props = React.ImgHTMLAttributes<HTMLImageElement> & {
  src?: string;
  name?: ImageKeysType;
  isBgImg?: boolean;
  loading?: "lazy" | "eager";
};

export const Images: React.FC<Props> = ({
  src,
  alt,
  className,
  isBgImg,
  name,
  loading = "lazy",
  ...props
}) => {
  if (src === undefined && name === undefined) {
    throw new Error("src or name is required");
  }

  const resolvedSrc = src || (name ? getImageUrl(name) : undefined);
  if (resolvedSrc === undefined) {
    throw new Error("Unable to resolve image source");
  }

  return (
    <img
      className={cn(
        {
          "absolute inset-0 w-full h-full z-[-1] object-cover object-center":
            isBgImg,
        },
        className
      )}
      src={resolvedSrc}
      loading={loading}
      alt={alt}
      {...props}
    />
  );
};
