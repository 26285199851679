import { allImages } from "./imports";

// all images
export const imageKeys = {
  ...allImages,
};

export type ImageKeysType = keyof typeof imageKeys;

export const getImageUrl = (key: ImageKeysType) => {
  return imageKeys[key];
};
