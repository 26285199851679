import { Icons, Logos } from "@src/components/icons";
import { menuLinks as MenuLinks } from "./data";
import { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
} from "@src/components/ui/dropdown-menu";

import { cn } from "../../lib/utils";
import CityToolTip from "./cityPopup.tsx/CityToolTip";

import { Sheet, SheetContent, SheetTrigger } from "@src/components/ui/sheet";
import { LocationButton } from "./components/locationBtn";
import { MobileMenuLinks } from "./components/mobileMenuLinks";
import { SubMenu } from "./components/subMenu";

export const Header = ({
  menuLinks,
  isShowCityPopup = false,
}: {
  menuLinks: typeof MenuLinks;
  isShowCityPopup?: boolean;
}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [activeSubMenuIndex, setActiveSubMenuIndex] = useState(0);
  const [isSheetOpen, setIsSheetOpen] = useState(false);

  return (
    <nav className="w-full sticky top-0 z-[999] shadow-sm bg-primary-primary h-[60px] md:h-[64px] flex justify-between items-center px-5 xl:px-[60px] 2xl:px-24">
      <div>
        <a href="https://drinkprime.in/" className="cursor-pointer">
          <Logos name="dp-white" size={[140, 32]} />
        </a>
      </div>

      {/* desktop */}
      <div className="flex justify-between">
        <ul className=" gap-5 text-offWhite font-medium hidden lg:flex">
          {menuLinks.map((link) => {
            if (link.subMenu) {
              return (
                <li key={link.name} className=" p-1">
                  <DropdownMenu modal={false}>
                    <DropdownMenuTrigger className="flex gap-2 items-center cursor-pointer">
                      {link.name}{" "}
                      <Icons
                        name="select-dropdown"
                        size={[16, 16]}
                        color="#FCFCFC"
                      />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      className={cn(
                        "relative z-[999] w-[560px] h-[445px] p-5",
                        activeSubMenuIndex === 0
                          ? "bg-[linear-gradient(180deg,#FFE7D8_0%,#FFF_100%)]"
                          : activeSubMenuIndex == 1
                          ? "bg-[linear-gradient(180deg,#D9FFFD_0%,#FFF_100%)]"
                          : "bg-[linear-gradient(180deg,#D8D9FF_0%,#FFF_100%)]"
                      )}
                    >
                      {link.subMenu.map((subLink, index) => {
                        return (
                          <SubMenu
                            subLink={subLink}
                            index={index}
                            key={subLink.name}
                            activeSubMenuIndex={activeSubMenuIndex}
                            setActiveSubMenuIndex={setActiveSubMenuIndex}
                          />
                        );
                      })}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </li>
              );
            } else {
              return (
                <li key={link.name} className="flex">
                  <a href={link.path} className="p-1">
                    {link.name}
                  </a>
                </li>
              );
            }
          })}
        </ul>
      </div>

      {isShowCityPopup && (
        <CityToolTip className="-lg:ml-auto">
          <LocationButton className="-lg:hidden mr-2" />

          {/* mobile */}
          <LocationButton className="ml-auto lg:hidden mr-5" />
        </CityToolTip>
      )}

      <Sheet
        modal={false}
        onOpenChange={(open) => {
          setIsSheetOpen(open);
          setIsMobileMenuOpen(!isMobileMenuOpen);
        }}
        open={isSheetOpen}
      >
        <SheetTrigger className="lg:hidden">
          <Icons name="hamburger-menu" size={[24, 24]} color="#FCFCFC" />
        </SheetTrigger>
        <SheetContent className="z-[9999] w-full">
          <MobileMenuLinks
            navLinks={menuLinks}
            setIsSheetOpen={setIsSheetOpen}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
          />
        </SheetContent>
      </Sheet>
    </nav>
  );
};
