import { Images } from "@src/components/images";
import { AppCarouselImages } from "../data";

export const CarouselCard = ({
  imageName,
  alt,
}: {
  imageName: AppCarouselImages;
  alt: string;
}) => {
  return (
    <div className="w-full max-w-[343px] md:max-w-[428px]">
      <Images name={imageName} className="w-full" alt={alt} />
    </div>
  );
};
