import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
} from "@src/components/ui/popover";
import { Icons } from "@src/components/icons";
import { Images } from "@src/components/images";

import classNames from "clsx";

export const BlogCard = ({
  title,
  date,
  imgUrl,
  blogUrl,
}: {
  title: string;
  date: string;
  imgUrl: string;
  blogUrl: string;
}) => {
  return (
    <div
      className={classNames(
        "flex flex-col md:flex-row gap-4",
        "px-4 py-3 md:px-6 md:py-5 rounded-lg md:rounded-[30px]",
        "border bg-white max-w-[535px] "
      )}
    >
      <a
        href={blogUrl}
        className="cursor-pointer w-full h-[172px] md:w-[200px] md:h-[132px] rounded-lg shrink-0"
        target="_blank"
      >
        <Images
          src={imgUrl}
          alt=""
          className="w-full h-full rounded-lg object-cover object-center border"
        />
      </a>{" "}
      <div className="flex flex-col gap-2.5">
        <h2 className="md:text-[22px] text-black font-bold cursor-pointer hidden md:block">
          <>
            {title.length > 50 ? (
              <>
                <a href={blogUrl} className="cursor-pointer" target="_blank">
                  {title.slice(0, 50)}
                </a>{" "}
                <Popover>
                  <PopoverTrigger
                    onClick={(e) => e.stopPropagation()}
                    className="cursor-pointer text-primary-primary text-sm"
                  >
                    ...read more
                  </PopoverTrigger>
                  <PopoverContent
                    className="overflow-auto rounded-2xl border-none text-sm leading-6 shadow md:text-base z-[9999]"
                    collisionPadding={30}
                  >
                    <a
                      href={blogUrl}
                      className="cursor-pointer"
                      target="_blank"
                    >
                      {title}
                    </a>
                    <PopoverArrow className="PopoverArrow fill-white" />
                  </PopoverContent>
                </Popover>
              </>
            ) : (
              <a href={blogUrl} className="cursor-pointer" target="_blank">
                {title}
              </a>
            )}
          </>
        </h2>
        <h2 className="md:text-[22px] text-black font-bold cursor-pointer md:hidden">
          <>
            {title.length > 90 ? (
              <>
                <a href={blogUrl} className="cursor-pointer" target="_blank">
                  {title.slice(0, 90)}
                </a>{" "}
                <Popover>
                  <PopoverTrigger
                    onClick={(e) => e.stopPropagation()}
                    className="cursor-pointer text-primary-primary text-sm"
                  >
                    ...read more
                  </PopoverTrigger>
                  <PopoverContent
                    className="overflow-auto rounded-2xl border-none text-sm leading-6 shadow md:text-base z-[9999]"
                    collisionPadding={30}
                  >
                    <a
                      href={blogUrl}
                      className="cursor-pointer"
                      target="_blank"
                    >
                      {title}
                    </a>
                    <PopoverArrow className="PopoverArrow fill-white" />
                  </PopoverContent>
                </Popover>
              </>
            ) : (
              <a href={blogUrl} className="cursor-pointer" target="_blank">
                {title}
              </a>
            )}
          </>
        </h2>
        <p className="flex gap-1 text-body text-sm md:text-base font-medium items-center">
          <Icons name="clock" size={[20, 20]} />
          {date} By DrinkPrime
        </p>
      </div>
    </div>
  );
};
