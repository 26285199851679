/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "clsx";
import Autoplay from "embla-carousel-autoplay";
import AutoScroll from "embla-carousel-auto-scroll";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselProps,
} from "./carousel";

type AutoplayOptionsType = Parameters<typeof Autoplay>[0];
type AutoScrollOptionsType = Parameters<typeof AutoScroll>[0];

type Props = {
  children?: JSX.Element | null;
  CarouselData?: any;
  CarouselCard: any;
  options?: {
    autoplay?: AutoplayOptionsType;
    autoscroll?: AutoScrollOptionsType;
    opts?: CarouselProps["opts"];
    orientation?: CarouselProps["orientation"];
    setApi?: CarouselProps["setApi"];
  };
  props?: {
    carousel?: React.HTMLAttributes<HTMLDivElement>;
    content?: React.HTMLAttributes<HTMLDivElement> & { overflow?: boolean };
    item?: React.HTMLAttributes<HTMLDivElement>;
  };
};

export const CarouselWrapper = ({
  children = null,
  CarouselData,
  CarouselCard,
  options,
  props,
}: Props) => {
  return (
    <Carousel
      plugins={[
        ...(options?.autoplay ? [Autoplay(options?.autoplay)] : []),
        ...(options?.autoscroll ? [AutoScroll(options?.autoscroll)] : []),
      ]}
      opts={options?.opts}
      orientation={options?.orientation}
      setApi={options?.setApi}
      {...props?.carousel}
      className={classNames("", props?.carousel?.className)}
    >
      <CarouselContent
        {...props?.content}
        className={classNames("", props?.content?.className)}
      >
        {CarouselData.map((item: JSX.IntrinsicAttributes, index: number) => (
          <CarouselItem
            key={index}
            {...props?.item}
            className={classNames("w-full max-w-fit", props?.item?.className)}
          >
            <CarouselCard key={index} {...item} />
          </CarouselItem>
        ))}
      </CarouselContent>
      {children}
    </Carousel>
  );
};
