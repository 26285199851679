import {
  CarouselPrevious,
  CarouselNext,
  CarouselDots,
} from "@src/components/ui/carousel";
import { CarouselWrapper } from "@src/components/ui/carouselWrapper";
import classNames from "clsx";
import { appCarouselImages } from "../data";
import { CarouselCard } from "./carouselCard";
import { Images } from "@src/components/images";

export const AppCarousel = () => {
  return (
    <div className="w-full md:max-w-[430px] lg:mx-16 select-none">
      <CarouselWrapper
        CarouselData={appCarouselImages}
        CarouselCard={CarouselCard}
        options={{
          opts: {
            loop: true,
            align: "center",
          },
          autoplay: {
            delay: 3000,
            stopOnMouseEnter: true,
            stopOnInteraction: false,
          },
        }}
      >
        <div className="relative mt-[18px] md:static">
          <CarouselPrevious
            variant="carouselBtn"
            className={classNames(
              "bottom-0 lg:-left-[80px]",
              "hidden lg:flex lg:h-[60px] lg:w-[60px]"
            )}
          />
          <CarouselNext
            variant="carouselBtn"
            className={classNames(
              "bottom-0 lg:-right-[80px]",
              "hidden lg:flex lg:h-[60px] lg:w-[60px]"
            )}
          />
          <CarouselDots className="lg:hidden" />
        </div>
      </CarouselWrapper>
      <div className="flex gap-4 mt-6 justify-center">
        <a
          href="https://play.google.com/store/apps/details?id=waterwala.com.prime"
          target="_blank"
        >
          <Images
            name="misc/google-playstore"
            className="w-[160px] h-[48px]  lg:w-[200px] lg:h-[60px]"
            alt="google play store logo"
          />
        </a>
        <a
          href="https://apps.apple.com/in/app/drinkprime/id1358111884"
          target="_blank"
        >
          <Images
            name="misc/apple-appstore"
            className="w-[160px] h-[48px]  lg:w-[200px] lg:h-[60px]"
            alt="apple app store logo"
          />
        </a>
      </div>
    </div>
  );
};
