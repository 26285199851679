import { Awards } from "./components/awardsTab";
import { Blogs } from "./components/blogTab";
import { Investors } from "./components/InvestorTab";
// import blogImg from "@src/vitehomepageassets/images/uncategorised/blog_img.webp";

type ValidIndex = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
export const awardsImgsData = [...Array(8).fill(0)].map((_, index) => ({
  name: `awards/${(index + 1) as ValidIndex}` as const,
}));

export type AwardsType = (typeof awardsImgsData)[number]["name"];

type investorsValidIndex = 1 | 2 | 3 | 4 | 5 | 6;
export const investorsImgsData = [...Array(6).fill(0)].map((_, index) => ({
  name: `investors/${(index + 1) as investorsValidIndex}` as const,
}));

export type InvestorsType = (typeof investorsImgsData)[number]["name"];

export const tabs = {
  awards: {
    title: "Awards",
    Content: Awards,
    icon: "trophy",
  },
  investors: {
    title: "Our Investors",
    Content: Investors,
    icon: "team",
  },
  blogs: {
    title: "Top Blogs",
    Content: Blogs,
    icon: "publication",
  },
} as const;

// static blog data

//  = [
//   ...Array(8).fill({
//     title: "Water Purification: A Global Lifeline",
//     date: "August 12, 2024",
//     imgUrl: blogImg,
//     blogUrl: "/",
//   }),
// ] as {
//   title: string;
//   date: string;
//   imgUrl: string;
//   blogUrl: string;
// }[];

export const blogData = [
  {
    title: "Is Drinking Cold Water Bad for Health? Risks and Benefits",
    date: "March 13, 2023",
    imgUrl:
      "https://drinkprime.in/blog/wp-content/uploads/2023/04/Is-Drinking-Cold-Water-Bad-for-Health-Risks-and-Benefits.jpg",
    blogUrl: "https://drinkprime.in/blog/drinking-cold-water/",
  },
  {
    title: "Drinking Water During and After Meals: Benefits and Side-effects",
    date: "Jan 25, 2023",
    imgUrl:
      "https://drinkprime.in/blog/wp-content/uploads/2023/01/Drinking-Water-During-and-After-Meals-Benefits-and-Side-effects.jpg",
    blogUrl: "https://drinkprime.in/blog/drinking-water-after-meal/",
  },
  {
    title: "6 Common RO Water Purifier Problems And Their Solutions",
    date: "Jan 13, 2023",
    imgUrl:
      "https://drinkprime.in/blog/wp-content/uploads/2023/01/Common-RO-water-purifier-problems-and-their-solutions-1.jpg",
    blogUrl: "https://drinkprime.in/blog/ro-water-purifier-problems/",
  },
  {
    title: "What Should be the pH of Drinking Water?",
    date: "March 27, 2023",
    imgUrl:
      "https://drinkprime.in/blog/wp-content/uploads/2023/04/What-should-be-the-pH-of-drinking-water.jpg",
    blogUrl:
      "https://drinkprime.in/blog/what-should-be-the-ph-of-drinking-water/",
  },
  {
    title: "Water Purification And Its Advantage And Disadvantages",
    date: "July 20, 2023",
    imgUrl:
      "https://drinkprime.in/blog/wp-content/uploads/2023/07/Water-Purification-And-Its-Advantages-And-Disadvantages.jpg",
    blogUrl: "https://drinkprime.in/blog/water-purification/",
  },
  {
    title: "Boiled Water vs. Filtered Water: Which is Better?",
    date: "April 10, 2023",
    imgUrl:
      "https://drinkprime.in/blog/wp-content/uploads/2023/04/Boiled-water-vs-Filtered-water-which-is-better-1.jpg",
    blogUrl: "https://drinkprime.in/blog/boiled-water-vs-filtered-water/",
  },
  {
    title: "10 Health Benefits Of Drinking Reverse Osmosis (RO) Water?",
    date: "June 5, 2023",
    imgUrl:
      "https://drinkprime.in/blog/wp-content/uploads/2023/06/10-Health-Benefits-Of-Drinking-Reverse-Osmosis-RO-Water.jpg",
    blogUrl: "https://drinkprime.in/blog/drinking-reverse-osmosis-water/",
  },
  {
    title: "What are The Safest Ways To Purify Water: 10 methods",
    date: "July 10, 2023",
    imgUrl:
      "https://drinkprime.in/blog/wp-content/uploads/2023/07/What-are-The-Safest-Ways-To-Purify-Water-10-methods.jpg",
    blogUrl: "https://drinkprime.in/blog/ways-to-purify-water/",
  },
];
