import {
  CarouselPrevious,
  CarouselNext,
  CarouselDots,
  CarouselApi,
} from "@src/components/ui/carousel";
import { CarouselWrapper } from "@src/components/ui/carouselWrapper";

import { TestimonialCard } from "./testimonialCard";
import classNames from "clsx";
import { testimonialData } from "../data";
import { useState } from "react";
import { JSX } from "react/jsx-runtime";

export const TestimonialCarousel = () => {
  const [carouselApi, setCarouselApi] = useState<CarouselApi>();

  return (
    <section className="xl:ml-10 w-full flex justify-center mt-[138px] md:mt-auto">
      <div className="w-full max-w-[340px] xl:max-w-[700px] xl:w-[700px] lg:mx-16 select-none relative md:-right-4">
        <CarouselWrapper
          CarouselData={testimonialData}
          CarouselCard={(
            args: JSX.IntrinsicAttributes & {
              name: string;
              position: string;
              ytVideoId: string;
              testimonial: string;
              carouselApi: CarouselApi;
            }
          ) => <TestimonialCard {...args} carouselApi={carouselApi} />}
          options={{
            opts: {
              loop: false,
              align: "center",
            },
            autoplay: {
              delay: 3000,
            },
            setApi: setCarouselApi,
          }}
        >
          <div className="relative mt-[48px] md:static">
            <CarouselPrevious
              variant="carouselBtnLight"
              className={classNames(
                "bottom-0 lg:-left-[80px]",
                "md:hidden lg:flex size-12 lg:h-[60px] lg:w-[60px]"
              )}
            />
            <CarouselNext
              variant="carouselBtnLight"
              className={classNames(
                "bottom-0 lg:-right-[80px]",
                "md:hidden lg:flex size-12 lg:h-[60px] lg:w-[60px]"
              )}
            />
            <CarouselDots className="lg:hidden" />
          </div>
        </CarouselWrapper>
      </div>
    </section>
  );
};
