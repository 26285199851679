import { iconsProps, logosProps } from "./types";
import iconsPath from "@src/vitehomepageassets/svgs/sprite.svg";
import logosPath from "@src/vitehomepageassets/svgs/logoSprite.svg";

export const Icons: React.FC<iconsProps> = ({
  name,
  className,
  size = [40, 40],
  color = "#292929",
}) => {
  return (
    <svg width={size?.[0]} height={size?.[1]} className={className}>
      <use style={{ color }} fill={color} xlinkHref={`${iconsPath}#${name}`} />
    </svg>
  );
};

export const Logos: React.FC<logosProps> = ({
  name,
  className,
  size = [120, 28],
  color = "#292929",
}) => {
  return (
    <svg width={size?.[0]} height={size?.[1]} className={className}>
      <use style={{ color }} fill={color} xlinkHref={`${logosPath}#${name}`} />
    </svg>
  );
};
