import { getItemFromLocalStorage } from "./plans";

export default function urlService() {
  const currentUrl = new URL(window.location.href);
  const urlSearchParams = currentUrl.searchParams;
  const origin = currentUrl.origin;
  const domain = currentUrl.hostname;
  const lpFromUrl = currentUrl.pathname.match(/lp\d{1,3}/)?.[0] || null;
  const path = currentUrl.pathname;
  const referralCode = urlSearchParams.get("referral_code");
  const utmSource = urlSearchParams.get("utm_source");
  const utmCampaign = urlSearchParams.get("utm_campaign");
  const utmMedium = urlSearchParams.get("utm_medium");
  const utmKeyword = urlSearchParams.get("utm_searchkeyword");
  const utmContent = urlSearchParams.get("utm_content");
  const gClid = urlSearchParams.get("gclid");
  const fClid = urlSearchParams.get("fbclid");
  return {
    origin,
    domain,
    lpFromUrl,
    path,
    referralCode,
    utmSource,
    utmCampaign,
    utmMedium,
    utmKeyword,
    utmContent,
    gClid,
    fClid,
    utmParams: {
      utmSource,
      utmCampaign,
      utmMedium,
      utmKeyword,
      utmContent,
      gClid,
      fClid,
    },
  };
}

export type localStorageVisitObject = {
  landing_page: string | null;
  visit_date: Date;
  user_id: string;
  utm_params: {
    utm_source: string | null;
    utm_campaign: string | null;
    utm_medium: string | null;
    utm_keyword: string | null;
    utm_content: string | null;
    g_clid: string | null;
    f_clid: string | null;
  };
};

export const getFromLocalVisitObject = (
  visit: "session_visit" | "first_visit"
) => {
  const localVisitObject: localStorageVisitObject | null =
    getItemFromLocalStorage(visit);
  const {
    landing_page = null,
    visit_date = null,
    user_id = null,
    utm_params: {
      utm_source = null,
      utm_campaign = null,
      utm_medium = null,
      utm_keyword = null,
      utm_content = null,
      g_clid = null,
      f_clid = null,
    },
  } = localVisitObject || { utm_params: {} };
  return {
    landing_page,
    visit_date,
    user_id,
    utm_source,
    utm_campaign,
    utm_medium,
    utm_keyword,
    utm_content,
    g_clid,
    f_clid,
    utm_params: {
      utm_source,
      utm_campaign,
      utm_medium,
      utm_keyword,
      utm_content,
      g_clid,
      f_clid,
    },
  };
};

export const objectToQueryString = (paramObj: {
  // eslint-disable-next-line
  [key: string]: any;
}): string => {
  const paramString = new URLSearchParams();
  for (const key in paramObj) {
    if (Object.prototype.hasOwnProperty.call(paramObj, key)) {
      if (Array.isArray(paramObj[key])) {
        const value = paramObj[key].join(",");
        paramString.append(key, value);
      } else if (typeof paramObj[key] === "undefined") {
        continue;
      } else {
        paramString.append(key, String(paramObj[key]).toString());
      }
    }
  }

  return "?" + paramString.toString();
};

function isJSON(localObject: string) {
  try {
    JSON.parse(localObject);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export function parseJSON(localObject: string) {
  return isJSON(localObject) ? JSON.parse(localObject) : localObject;
}
