export const cities = [
    { cityName: "bengaluru" },
    { cityName: "delhi" },
    { cityName: "faridabad" },
    { cityName: "ghaziabad" },
    { cityName: "gurgaon" },
    { cityName: "hyderabad" },
    { cityName: "mumbai" },
    { cityName: "noida" },
    { cityName: "pune" },
  ] as const;
  
  export type CitiesType = typeof cities[number]["cityName"];