import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from "@src/components/ui/popover";
import { PropsWithChildren, useContext } from "react";
import { cn } from "../../../lib/utils";
import { CityPopupContext } from "../../../pages/homepage";
import {
  getItemFromLocalStorage,
  setItemInLocalStorage,
} from "../../../lib/plans";
import { getSelectedCityPopupFromLocalStorage } from "../../../lib/selectedCityInPopup";

export default function CityToolTip({
  className,
  children,
}: PropsWithChildren<{
  className?: string;
}>) {
  const { isCityToolTipOpen } = useContext(CityPopupContext);
  return (
    <Popover open={isCityToolTipOpen}>
      <PopoverTrigger className={cn(className)}>{children}</PopoverTrigger>
      <PopoverContent className="bg-[#F7F7FC] p-2.5 w-fit z-[1000]">
        <p className="text-[13px] md:text-[14px] leading-[20px] text-body font-semibold">
          Update your <br className="md:hidden" /> city here
        </p>
        <PopoverArrow className="PopoverArrow fill-[#F7F7FC] shadow-2xl" />
      </PopoverContent>
    </Popover>
  );
}

export function shouldCityTooltipBeShownByLocalStorage() {
  const aDayInMs = 24 * 3600 * 1000;
  const maxCount = 5;
  const { count, modifiedDate } =
    getItemFromLocalStorage("cityTooltipCounter") || {};
  const localTrackObject = getItemFromLocalStorage("trackRevisit");
  if (localTrackObject !== null) return false;

  else if (getSelectedCityPopupFromLocalStorage() !== "") return false;

  else if (
    modifiedDate == undefined ||
    new Date().getTime() - new Date(modifiedDate).getTime() > aDayInMs
  ) {
    // 1st incognito visit or 1 visit in 2h hrs;
    setItemInLocalStorage("cityTooltipCounter", {
      count: 1,
      modifiedDate: `${new Date(new Date().setHours(0, 0, 0, 0))}`,
    });
    return true;
  } else if (count < maxCount) {
    // subsequent visits within 24 hours and count < 5

    setItemInLocalStorage("cityTooltipCounter", {
      count: count + 1,
      modifiedDate: `${new Date(new Date().setHours(0, 0, 0, 0))}`,
    });
    return true;
  } else return false;
}
