import { IconsType } from "@src/components/icons/types";

export const bannerStripData: {
  icon?: IconsType;
  text: string;
}[] = [
  {
    icon: "community",
    text: "1M+ Strong Community",
  },
  {
    icon: "people",
    text: "2L+ Customer Referrals",
  },
  {
    icon: "iot",
    text: "Built with IoT technology",
  },
];

type FeatureItem = {
  icon: IconsType;
  title: string;
  description: string;
};

export const features: FeatureItem[] = [
  {
    icon: "shield",
    title: "Lifetime Free",
    description: "Maintenance",
  },
  {
    icon: "calendar-day-7",
    title: "7 days",
    description: "Risk-Free Trial",
  },
  {
    icon: "clock-countdown",
    title: "48-hour",
    description: "Installation",
  },
  {
    icon: "ruppe-symbol",
    title: "Plans starting",
    description: "299/month",
  },
];
