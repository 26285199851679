import { Images } from "@src/components/images";
import classNames from "clsx";
import { AwardsType, InvestorsType } from "../data";

export const ImgCard = ({ name }: { name: AwardsType | InvestorsType }) => {
  return (
    <div
      className={classNames("", {
        "!size-[120px] md:!size-[180px]": name.includes("awards"),
        "w-[160px] h-[80px] md:w-[180px] md:h-[90px]":
          name.includes("investors"),
      })}
    >
      <Images name={name} className="" alt={`${name.split("/").join("-")}`} />
    </div>
  );
};
