import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@src/components/ui/tabs";
import classNames from "clsx";
import { Icons } from "@src/components/icons";
import { tabs } from "./data";

export const SpotlightSection = () => {
  return (
    <section className="py-16 md:py-20 overflow-hidden bg-[#FAFAFA]">
      <div className="w-full ">
        <Tabs defaultValue="awards" className="px-4 md:px-0">
          <TabsList className="w-full max-w-[830px] mx-auto flex gap-[14px] md:gap-6">
            {Object.entries(tabs).map(([key, { title, icon }]) => (
              <TabsTrigger
                key={key}
                value={key}
                className={classNames(
                  "data-[state=active]:bg-primary-primary data-[state=active]:text-white data-[state=active]:font-semibold",
                  "border border-labelIconsGrey h-12 md:h-[72px] w-fit font-medium text-title",
                  "p-4 md:px-10  text-xs md:text-xl rounded-lg md:rounded-2xl flex gap-3 items-center",
                  {
                    "w-[74px] md:w-[200px]": key === "awards",
                    "w-[112px] md:w-[254px]": key === "investors",
                    "w-[86px] md:w-[230px]": key === "blogs",
                  }
                )}
              >
                <span className="hidden md:inline-block">
                  <Icons name={icon} size={[40, 40]} />
                </span>
                {title}
              </TabsTrigger>
            ))}
          </TabsList>
          {Object.entries(tabs).map(([key, { Content }]) => (
            <TabsContent key={key} value={key} className="mt-9 md:mt-[68px] ">
              <Content />
              {key === "awards" && (
                <div className="md:hidden mt-6">
                  {<Content direction="backward" />}
                </div>
              )}
            </TabsContent>
          ))}
        </Tabs>
      </div>
    </section>
  );
};
