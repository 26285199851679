import * as Dialog from "@radix-ui/react-dialog";
import LeadFormInputWithErrorWrapper, {
  LeadFormInput,
} from "../leadFormApi/MainLeadFormInputs";
import useLeadForm from "../leadFormApi/useLeadForm";
import {
  LeadFormSubmitButton,
  nextRouteType,
} from "../leadFormApi/LeadFormSubmitButton";
import { cn } from "../../../lib/utils";
import { Cross2Icon } from "@radix-ui/react-icons";
import { PropsWithChildren, useState } from "react";
import Lottie from "lottie-react";
import ThankyouLottie from "../../../vitehomepageassets/svgs/thankYouLottie.json";
import check_circle from "@src/vitehomepageassets/svgs/Others/check-mark.svg";

function LeadFormPopupTriggerButton({
  className,
  children = "Subscribe",
  nextRoute,
  formName = "",
  id,
}: PropsWithChildren<{
  className?: string;
  nextRoute: nextRouteType;
  formName: string;
  id?: string;
}>) {
  const {
    useFormProps,
    setDummyValues,
    handleSubmit,
    isSubmitting,
    isSubmitted,
  } = useLeadForm();
  const [isThankYouToBeShown, setIsThankYouToBeShown] = useState(false);
  const promises = [
    "Lifetime Free Maintenance",
    "7 Day Free Trial",
    "48 Hours Installation - Starting at ₹299/month",
  ];
  return (
    <Dialog.Root modal={false}>
      <Dialog.Trigger id={id} className={cn(className)}>
        {children}
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay
          className={cn(
            "fixed inset-0 z-[20000] bg-black/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
          )}
        />

        <div className={cn("fixed inset-0 bg-black/80 z-[20000]")}>
          <Dialog.Content
            className={cn(
              "fixed left-[50%] top-[50%] z-[20000] w-[90%] max-w-lg translate-x-[-50%] translate-y-[-50%]",
              "bg-background text-center ",
              "border p-5 shadow-lg rounded-xl max-w-[480px]"
            )}
          >
            {isThankYouToBeShown ? (
              <ThankYouLottie />
            ) : (
              <>
                <Dialog.Title className="text-[17px] font-semibold text-black/80">
                  Submit Your Details
                </Dialog.Title>
                <Dialog.Description className="mb-6 relative">
                  <div className="text-sm md:text-base text-black/60">
                    Trusted by{" "}
                    <span className="text-success-success font-medium">
                      1M+ customers
                    </span>{" "}
                    accross 9 cities
                  </div>
                </Dialog.Description>
                <Dialog.Close className="absolute right-4 top-4 rounded-sm opacity-70">
                  <Cross2Icon className="h-4 w-4" />
                  <span className="sr-only">Close</span>
                </Dialog.Close>
                <div className="w-full flex flex-col gap-1.5 !mt-3">
                  <LeadFormInputWithErrorWrapper
                    useFormProps={useFormProps}
                    className={cn("max-w-[450px] w-full")}
                    errorClassName="text-error-error"
                  >
                    <LeadFormInput
                      useFormProps={useFormProps}
                      className={cn(
                        "rounded-full block mx-auto px-4 py-3 w-full max-w-[450px]",
                        "-sm:text-sm text-black"
                      )}
                    />
                  </LeadFormInputWithErrorWrapper>
                </div>
                <ul className="flex flex-wrap gap-2 md:gap-4 my-4 md:my-6">
                  {promises.map((text) => (
                    <li
                      className="flex gap-1 w-full md:w-fit items-center"
                      key={text}
                    >
                      <img
                        src={check_circle}
                        alt="check-circle"
                        className="w-5 h-5"
                      />
                      <h3 className="text-xs md:text-base text-left">{text}</h3>
                    </li>
                  ))}
                </ul>
                <LeadFormSubmitButton
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  isSubmitted={isSubmitted}
                  setDummyValues={setDummyValues}
                  nextRoute={nextRoute}
                  formName={formName}
                  setIsThankYouToBeShown={setIsThankYouToBeShown}
                  className={cn(
                    "w-full max-w-[450px] mt-3",
                    "rounded-full bg-primary-primary py-2.5",
                    "text-sm md:text-lg font-semibold text-off-white",
                    "flex flex-col items-center"
                  )}
                >
                  Get a call back
                </LeadFormSubmitButton>
                <h4
                  className={cn(
                    "text-[12px] md:text-[13px] leading-5 text-center mt-5 mx-4 md:mx-0 font-semibold text-black/80"
                  )}
                >
                  By creating an account on DrinkPrime, you agree to our{" "}
                  <a
                    href="/app/terms-of-use"
                    className="underline cursor-pointer whitespace-nowrap font-bold"
                  >
                    Terms of Use
                  </a>
                </h4>
              </>
            )}
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default LeadFormPopupTriggerButton;

const ThankYouLottie = () => (
  <div className="w-full flex flex-col items-center justify-center">
    {/* <img src={Icon} alt="" /> */}
    <Dialog.Close className="absolute right-4 top-4 rounded-sm opacity-70">
      <Cross2Icon className="h-4 w-4" />
      <span className="sr-only">Close</span>
    </Dialog.Close>
    <Lottie
      animationData={ThankyouLottie}
      loop={true}
      className="w-[180px] h-[180px] md:w-[200px] md:h-[200px]"
    />
    <h3 className="text-base font-bold text-center lg:text-[32px] leading-8 md:mt-[40px]">
      Thank you for submitting details!
    </h3>
    <p className="text-sm md:text-base text-center mt-1 md:mt-3 text-black/60">
      Our team will get in touch with you shortly.
    </p>
  </div>
);
