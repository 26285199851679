import classNames from "clsx";
import {
  CarouselPrevious,
  CarouselNext,
  CarouselDots,
} from "@src/components/ui/carousel";
import { CarouselWrapper } from "@src/components/ui/carouselWrapper";
import { blogData } from "../data";
import { BlogCard } from "./blogCard";

export const Blogs = () => {
  return (
    <div className="md:pl-[120px]">
      <CarouselWrapper
        CarouselData={blogData}
        CarouselCard={BlogCard}
        props={{
          content: {
            overflow: false,
          },
        }}
        options={{
          opts: {
            loop: false,
            align: "center",
          },
        }}
      >
        <div className="relative mt-[18px] md:static">
          <CarouselPrevious
            variant="carouselBtn"
            className={classNames(
              "bottom-0 md:-left-[80px]",
              "hidden md:flex md:h-[60px] md:w-[60px]"
            )}
          />
          <CarouselNext
            variant="carouselBtn"
            className={classNames(
              "bottom-0 md:right-[30px]",
              "hidden md:flex md:h-[60px] md:w-[60px]"
            )}
          />
          <CarouselDots className="md:hidden" />
        </div>
      </CarouselWrapper>
    </div>
  );
};
