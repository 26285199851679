import {
  categoryType,
  AlkalineCategoryType,
  durationType,
  LiteCategoryType,
  AllRechargeIdType,
  cityNamesType,
} from "./plans";
import { getFromLocalVisitObject } from "./urlLocalServices";

const dLayer = () => ({
  event: "w_event_tracked",
  name: "",
  source: "web",
  landing_page:
    getFromLocalVisitObject("session_visit").landing_page ||
    getUTMParams().landing_page,
  landing_page_first_visit:
    getFromLocalVisitObject("first_visit").landing_page ||
    getUTMParams().landing_page_first_visit,
});

//declaration merging
declare global {
  interface Window {
    // eslint-disable-next-line
    dataLayer: any[];
    locationStack: string[];
    setOrganicPopupState: (state: boolean) => void;
  }
}

// conditional args
export type EventsWithOtherFields =
  | "w_checkout_plan_selected"
  | "w_checkout_duration_select_clicked"
  | "w_checkout_payment_success"
  | "w_checkout_payment_failed"
  | "w_checkout_proceeded_to_pay"
  | "w_leadform_submit_clicked";
export type EventsWithoutOtherFields =
  | "landingpage_loaded"
  | "w_customer_revisted"
  | "w_checkout_page_loaded"
  | "w_checkout_helpselectplan_clicked"
  | "w_checkout_plan_confirm_clicked"
  | "w_checkout_retrypayment_clicked"
  | "w_checkout_editaddress_clicked"
  | "w_checkout_plan_faqs_clicked"
  | "w_playstore_button_clicked"
  | "w_iosstore_button_clicked"
  | "w_stickyfooter_checkplans_button_clicked"
  | "w_stickyfooter_callback_button_clicked"
  | "w_whatsappfloating_clicked"
  | "w_gototop_clicked";

export function pushDataLayer(name: EventsWithoutOtherFields): void;
export function pushDataLayer(
  name: "w_checkout_proceeded_to_pay",
  otherFields: { payment_gateway: string }
): void;
export function pushDataLayer(
  name: "w_checkout_plan_selected",
  otherFields: {
    plan_name: categoryType | LiteCategoryType | AlkalineCategoryType;
  }
): void;
export function pushDataLayer(
  name: "w_checkout_duration_select_clicked",
  otherFields: {
    plan_name: categoryType | LiteCategoryType | AlkalineCategoryType;
    duration: durationType;
    recharge_id: AllRechargeIdType;
  }
): void;
export function pushDataLayer(
  name: "w_leadform_submit_clicked",
  otherFields: {
    form_name: string;
    user_phone: string;
    user_name: string;
    user_email: string | null;
    user_city: cityNamesType;
    user_custom_city: string | "";
    user_id: string;
    utm_campaign: string | null;
    referral_code: string;
    dp_fbc: string | null;
  },
  options: { isFormSubmission: true }
): void;
export function pushDataLayer(
  name: "w_checkout_payment_success" | "w_checkout_payment_failed",
  otherFields: {
    pg_transaction_id?: string;
    transaction_id: string;
    pg_transaction_amount: number;
    plan_name: categoryType;
    plan_duration: durationType;
  },
  options: { isTransactionDone: true }
): void;
// Actual Implementation
export function pushDataLayer(
  name: string,
  // eslint-disable-next-line
  otherFields: Record<string, any> = {},
  // options?: {
  //   isFormSubmission?: boolean;
  //   isTransactionDone?: boolean;
  // } = {}
) {
  window.dataLayer.push({ ...dLayer(), ...otherFields, name }); //For all lp events
}

//used in checkout page
export function getUTMParams() {
  const url = new URL(window.location.href);
  const [
    landing_page,
    landing_page_first_visit,
    utm_source,
    utm_campaign,
    utm_medium,
    utm_keyword,
    g_clid,
    f_clid,
  ] = [
    url.searchParams.get("landing_page"),
    url.searchParams.get("landing_page_first_visit"),
    url.searchParams.get("utm_source"),
    url.searchParams.get("utm_campaign"),
    url.searchParams.get("utm_medium"),
    url.searchParams.get("utm_searchkeyword"),
    url.searchParams.get("gclid"),
    url.searchParams.get("fbclid"),
  ];
  const utm_params = {
    landing_page,
    landing_page_first_visit,
    utm_source,
    utm_campaign,
    utm_medium,
    utm_keyword,
    g_clid,
    f_clid,
  };
  return utm_params;
}
