import { cityNamesArray } from "../../../lib/plans";
import { utmParamsType } from "../../../lib/visitObject";

export type cityNamesType = (typeof cityNamesArray)[number];

export const cityNamesArrayForLeadForm = [...cityNamesArray, "Others"] as const;

export const LeadFormInputNamesArray = [
  "name",
  "mobile",
  "coupon",
  "city",
  "otherCity",
] as const;

export type LeadFormInputNamesType = {
  [K in (typeof LeadFormInputNamesArray)[number]]: K extends "city"
    ? (typeof cityNamesArrayForLeadForm)[number]
    : K extends "coupon" | "otherCity"
    ? string | undefined // Make coupon optional
    : string;
};

export type LeadFormData = LeadFormInputNamesType;

export const getPhoneHash = async function (val: string) {
  let hash;
  try {
    hash = await window.crypto.subtle
      // @ts-expect-error adf
      .digest("SHA-256", new TextEncoder("utf-8").encode(val))
      .then((h) => {
        const hexes = [],
          view = new DataView(h);
        for (let i = 0; i < view.byteLength; i += 4)
          hexes.push(("00000000" + view.getUint32(i).toString(16)).slice(-8));
        return hexes.join("");
      });
  } catch (e) {
    console.log("error in getting phone hash: ", e);
    // Sentry.captureException(new Error("error in getting phone hash: " + e));
    hash = "";
  }
  return hash;
};

export function getCookie(name: string) {
  const re = new RegExp(name + "=([^;]+)");
  const value = re.exec(document.cookie);
  return value != null ? decodeURI(value[1]) : null;
}

export type leadFormApiBodyType = {
  firstName: LeadFormData["name"];
  phoneNumber: LeadFormData["mobile"];
  cityName: LeadFormData["city"];
  otherCity?: LeadFormData["otherCity"];
  referralCode: LeadFormData["coupon"];
  apiSource: string;
  landingURL: string;
  landingPage: string | null;
  leadSource: utmParamsType["utm_source"] | null;
  sourceCampaign: utmParamsType["utm_campaign"] | null;
  searchKeyword: utmParamsType["utm_keyword"] | null;
  sourceMedium: utmParamsType["utm_medium"] | null;
  utmSourceContent: utmParamsType["utm_content"] | null;
  gclID: utmParamsType["g_clid"] | null;
  fblID: utmParamsType["f_clid"] | null;
};
