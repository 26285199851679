import { CarouselWrapper } from "@src/components/ui/carouselWrapper";
import SectionHeading from "@src/components/ui/heading";
import { cities } from "./data";
import { CityCard } from "./components/cityCard";
import { Images } from "@src/components/images";

export const CityShowcase = () => {
  return (
    <section className="pt-[60px] md:pt-[100px] pb-[120px] relative select-none">
      <Images isBgImg={true} name="bg/city-showcase-bg-desk" className="object-bottom" alt="city showcase background" />
      <div className="px-5">
        <SectionHeading
          className="max-w-[1050px] mx-auto"
          descriptionStyle="text-[#4E4B66]"
          description="Since our launch in Bengaluru, we've expanded to Hyderabad, Delhi NCR, Mumbai and Pune. Our journey is just beginning, and we will continue to make pure drinking water accessible across the country."
        >
          <span className="md:max-w-[900px] inline-block">
            We’re on a Mission to Bring Clean, Safe Drinking Water to Every
            Home!
          </span>
        </SectionHeading>
      </div>
      <div className=" mt-[66px]">
        <CarouselWrapper
          CarouselData={cities}
          CarouselCard={CityCard}
          options={{
            opts: {
              loop: true,
              align: "start",
            },
            autoscroll: {
              speed: 1,
              stopOnInteraction: false,
              stopOnMouseEnter: true,
            },
          }}
        />
      </div>
    </section>
  );
};
