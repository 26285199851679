import { cityPopupNameType } from "../sections/header/cityPopup.tsx/utils";
import { getItemFromLocalStorage, setItemInLocalStorage } from "./plans";

export type cityPopupLocalObject = {
  city: cityPopupNameType;
  modified_date: string;
};

export function setSelectedCityPopupToLocalStorage(
  city: cityPopupNameType
): boolean {
  return setItemInLocalStorage("selected-city-popup", {
    city,
    modified_date: String(new Date()),
  } as cityPopupLocalObject);
}

export function getSelectedCityPopupFromLocalStorage(): cityPopupNameType | "" {
  const ageInDays = 60;
  const ageInMs = ageInDays * 24 * 3600 * 1000;

  const cityObject: cityPopupLocalObject | null = getItemFromLocalStorage(
    "selected-city-popup"
  );
  if (!cityObject) return "";

  const { city, modified_date } = cityObject;
  if (new Date().getTime() - new Date(modified_date).getTime() > ageInMs) {
    return "";
  }
  return city as cityPopupNameType;
}
