import SOLO from "../../../vitehomepageassets/svgs/Others/SOLO.svg";
import COUPLE from "../../../vitehomepageassets/svgs/Others/COUPLE.svg";
import FAMILY from "../../../vitehomepageassets/svgs/Others/FAMILY.svg";
import UNLIMITED from "../../../vitehomepageassets/svgs/Others/UNLIMITED.svg";

import { createContext } from "react";
import {
  AlkalineCategoryType,
  AllRechargeIdType,
  categoryType,
  durationType,
  LiteCategoryType,
  Model,
  planType,
} from "../../../lib/plans";

export const FormRedirectContext = createContext<{
  path: "whatsapp" | "address" | "thankyou" | null;
  modelState?: "ALIVE" | "LITE" | "ALKALINE";
  planId?: AllRechargeIdType;
}>({ path: null, modelState: "ALIVE", planId: "solo449_tp1" });

export const ModelContext = createContext<{
  modelStateContext: Model;
  setModelStateContext: React.Dispatch<React.SetStateAction<Model>>;
}>({ modelStateContext: "ALIVE", setModelStateContext: () => {} });

export const PlanContext = createContext({
  modelState: "ALIVE",
  categoryState: "SOLO",
  helpPopup: false,
  durationState: "",
  formPopup: false,
} as {
  modelState: Model;
  plan: planType<AllRechargeIdType>;
  categoryState: categoryType | LiteCategoryType | AlkalineCategoryType;
  setCategoryState: React.Dispatch<
    React.SetStateAction<categoryType | LiteCategoryType | AlkalineCategoryType>
  >;
  helpPopup: boolean;
  setHelpPopup: React.Dispatch<React.SetStateAction<boolean>>;
  durationState: "" | durationType;
  setDurationState: React.Dispatch<React.SetStateAction<"" | durationType>>;
  formPopup: boolean;
  setFormPopup: React.Dispatch<React.SetStateAction<boolean>>;
});

export const categoryArray: {
  categoryName: categoryType;
  categoryImage: string;
}[] = [
  {
    categoryName: "SOLO",
    categoryImage: SOLO,
  },
  {
    categoryName: "COUPLE",
    categoryImage: COUPLE,
  },
  {
    categoryName: "FAMILY",
    categoryImage: FAMILY,
  },
  {
    categoryName: "UNLIMITED",
    categoryImage: UNLIMITED,
  },
];
export const liteCategoryArray: {
  categoryName: LiteCategoryType;
  categoryImage: string;
}[] = [
  {
    categoryName: "BASIC",
    categoryImage: COUPLE,
  },
  {
    categoryName: "UNLIMITED",
    categoryImage: UNLIMITED,
  },
];
export const alkalineCategoryArray: {
  categoryName: AlkalineCategoryType;
  categoryImage: string;
}[] = [
  {
    categoryName: "STANDARD",
    categoryImage: COUPLE,
  },
  {
    categoryName: "UNLIMITED",
    categoryImage: UNLIMITED,
  },
];
