import React from "react";
import { features } from "./data";
import { Icons } from "@src/components/icons";

const FeatureHighlights: React.FC = () => {
  return (
    <section className="px-4 pt-10 pb-5 lg:px-[40px] xl:px-[120px] md:py-12">
      <div className="bg-[linear-gradient(105deg,_#F8F0FF_7.28%,_#F2F6FF_58.8%,_#E4F7FF_109.26%)] p-4 md:px-[50px] md:py-5 rounded-[20px]">
        <div className="grid grid-cols-2 lg:grid-cols-4 justify-items-center items-center gap-6 p-2 md:px-6 md:py-4">
          {features.map((feature, index) => (
            <div key={index} className="flex gap-3 items-center">
              <span className="inline-block">
                <Icons
                  name={feature.icon}
                  color="#4548B9"
                  className="w-6 h-6 md:w-12 md:h-12"
                />
              </span>
              <div className="text-[13px] md:text-xl font-medium md:font-semibold text-primary-primary">
                {feature.title} <br />
                {feature.description}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeatureHighlights;
