import classNames from "clsx";
import { useEffect, useRef, useState } from "react";
import { cn } from "../../lib/utils";
import { Icons } from "../../components/icons";

// By default scrolls automatically for mobile view and desktop, but desktop it can be disabled by setting it to false
export default function AutoScrollTextRightToLeft({
  textArray = [],
  enableAutoScrollForDesktop = true,
}: {
  textArray: string[];
  enableAutoScrollForDesktop?: boolean;
}) {
  const scrollRef = useRef<HTMLDivElement>(null);
  // const [scrollWidth, setScrollWidth] = useState(0);

  useEffect(() => {
    const gap = 32; //used here
    // Get the scroll width of the element once the component mounts
    if (scrollRef.current) {
      const elementScrollWidth = scrollRef.current.scrollWidth;
      scrollRef.current.style.setProperty(
        "--scrollWidth",
        `calc(-${elementScrollWidth / 3}px - ${gap / 3}px)`
      );
    }
  }, []);
  return (
    <div
      className={cn(
        "no-scrollbar overflow-hidden w-full bg-[linear-gradient(105deg,_#E7FFEA_7.28%,_#EFFFDF_109.26%)] py-[12px]",
        "rounded-sm"
      )}
    >
      <div
        ref={scrollRef}
        className={cn(
          "flex w-max md:w-auto nth-n:shrink-0",
          !enableAutoScrollForDesktop && "lg:[animation-name:none]",
          // "[animation-name:infiniteScrollWithCSSVariable]",
          // "[animation-duration:20s] [animation-iteration-count:infinite] [animation-timing-function:linear]",
          "justify-around items-center gap-8 text-white text-xs md:text-base"
        )}
      >
        {textArray.length > 1 &&
          textArray.map((text) => (
            <div className={classNames("flex gap-2.5 items-center")} key={text}>
              <Icons
                name="success-tick"
                className="w-4 h-4 max-w-4 max-h-4"
                color="#FFFF"
              />
              <p className="whitespace-nowrap font-semibold text-success-success text-sm">
                {text}
              </p>
            </div>
          ))}
        {/* duplicate 2 more times here to show infinite auto scroll */}
      </div>
    </div>
  );
}

export function AnimatedSlideBottomToTopText({
  textArray = [],
  full_width = true,
}: {
  textArray: string[];
  full_width?: boolean;
}) {
  const [animationText, setAnimationText] = useState(textArray[0]);
  const [textIndex, setTextIndex] = useState(0);
  useEffect(() => {
    setTextIndex(0);
  }, [textArray]);
  const handleAnimationIteration = () => {
    setAnimationText(textArray[textIndex]);
    setTextIndex((prev) => (prev + 1 < textArray.length ? prev + 1 : 0));
  };
  return (
    <div
      className={classNames(
        "flex justify-center items-center gap-1 py-3",
        full_width ? "" : "max-w-[335px] md:max-w-[400px] mx-2 rounded-[40px]",
        "w-full max-w-full bg-[linear-gradient(105deg,#E7FFEA_7.28%,#EFFFDF_109.26%)]",
        "overflow-hidden",
        "text-sm leading-[140%] font-medium text-success-success",
        "rounded-full"
      )}
    >
      <div
        className={classNames(
          "animate-slideInAnime",
          "flex gap-2 items-center"
        )}
        // onAnimationEnd={handleAnimationEnd}
        onAnimationIteration={handleAnimationIteration}
      >
        <Icons
          name="success-tick"
          className="w-4 h-4 max-w-4 max-h-4"
          color="#FFFF"
        />
        <p>{animationText}</p>
      </div>
    </div>
  );
}
