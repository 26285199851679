import { useEffect, useState } from "react";
import classNames from "clsx";
import PurifierButtonStates from "./PurifierModelButtons";
import PlanBlock from "./planblock/MainPlanBlock";
import { Model } from "../../lib/plans";
import { ProductImagesCarousel } from "./ProductImagesCarousel";
import { ModelContext } from "./planblock/planSectionContext";
import AutoScrollTextRightToLeft, {
  AnimatedSlideBottomToTopText,
} from "./AutoScrollPoints";
import SectionHeading from "@src/components/ui/heading";

const textArrayData: Record<Model, string[]> = {
  ALIVE: [
    "Multistage Universal Water purifier",
    "Goodness of copper",
    "RO Purification",
    "In-line UV purification",
  ],
  LITE: [
    "Multistage Universal Water purifier",
    "Mineral cartridge",
    "RO Purification",
    "Intuitive 3 LED Display",
  ],
  ALKALINE: [
    "Multistage Universal Water purifier",
    "Alkaline boost",
    "RO Purification",
    "In-line UV purification",
  ],
};

export function ProductPlanSection() {
  const [modelState, setModelState] = useState<Model>("ALIVE");
  const stateIndex = {
    ALIVE: 0,
    LITE: 1,
    ALKALINE: 2,
  } as const;
  const buttonIndex = stateIndex[modelState];
  const textArray = textArrayData[modelState];
  // const textArray = textArrayData["ALIVE"];
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "-100px 0px 3000px 0px",
      threshold: 0.5,
    };
    const modelButtonElement = document.getElementById(
      "model-buttons-observer"
    );
    const step2Element = document.getElementById("step2-observer");
    const observer = new IntersectionObserver((entries) => {
      const { isIntersecting } = entries[0];
      modelButtonElement?.classList.toggle("-lg:sticky", isIntersecting);
    }, options);
    if (step2Element) observer.observe(step2Element);

    return () => observer.disconnect();
  }, []);

  return (
    <ModelContext.Provider
      value={{
        modelStateContext: modelState,
        setModelStateContext: setModelState,
      }}
    >
      <section
        className="p-4 py-[40px] md:py-[70px] flex flex-col  lg:gap-6"
        id="plans-section"
      >
        <SectionHeading
          className="max-w-[900px] mx-auto mb-4"
          description="Each of our smart water purifiers comes with advanced multi-stage purification and IoT technology."
        >
          Products That Fit{" "}
          <span className="text-primary-primary">
            Every Lifestyle And Budget
          </span>
        </SectionHeading>
        <div
          className={classNames(
            "flex flex-col nth-1:mb-7 nth-2:mb-[18px]",
            "lg:bg-[#FCFCFC] lg:border lg:rounded-[40px] md:shadow-sm",
            "lg:mx-auto lg:py-10 lg:justify-center xl:pl-20 xl:pr-[70px]",
            "max-w-[min(100%,1200px)]"
          )}
        >
          <PurifierButtonStates
            modelState={modelState}
            setModelState={setModelState}
          />
          <>
            {/* mobile view */}
            <div className="lg:hidden">
              <AnimatedSlideBottomToTopText textArray={textArray} />
            </div>
            {/* desktop view */}
            <div className="-lg:hidden">
              <AutoScrollTextRightToLeft textArray={textArray} />
            </div>
          </>

          <div
            className={classNames(
              "flex flex-col lg:flex-row justify-evenly xl:justify-between gap-4 md:gap-[54px] items-center py-4"
            )}
          >
            <ProductImagesCarousel buttonIndex={buttonIndex} />
            <PlanBlock />
          </div>
        </div>
      </section>
    </ModelContext.Provider>
  );
}

// const Header = () => {
//   const h2 = "Products That Fit Every Lifestyle And Budget";
//   const h4 =
//     "Each of our smart water purifiers comes with advanced multi-stage purification and IoT technology.";

//   return (
//     <div className="mx-auto flex flex-col items-start lg:items-center">
//       <h2 className="md:text-center text-[24px] leading-[140%] md:text-[30px] lg:text-[36px] mb-3 font-semibold   text-title-active">
//         {h2}
//       </h2>
//       <h4 className="text-sm md:text-base max-w-[750px] md:text-center text-body leading-[150%]">
//         {h4}
//       </h4>
//     </div>
//   );
// };
