import classNames from "clsx";
import { useRef, useState } from "react";

import { renderToStaticMarkup } from "react-dom/server";

// Function to extract text from JSX
const extractTextFromJSX = (jsx: JSX.Element) => {
  return renderToStaticMarkup(jsx).replace(/<[^>]*>/g, "");
};

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@src/components/ui/accordion";
import { ScrollArea, ScrollBar } from "@src/components/ui/scroll-area";
import { FaqsCategoryData } from "./data";
import { JSX } from "react/jsx-runtime";
import { Icons } from "@src/components/icons";

export const FaqSection = () => {
  const [searchedItems, setSearchedItems] = useState<
    (typeof FaqsCategoryData)["securityDeposit"]["faqs"] | []
  >([]);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const AllFaqs = Object.entries(FaqsCategoryData).reduce(
    (acc: (typeof FaqsCategoryData)["securityDeposit"]["faqs"], value) => {
      return [...acc, ...value[1].faqs];
    },
    []
  );
  const FaqsData = {
    All: {
      title: "All",
      faqs: AllFaqs.reverse(),
    },
    ...FaqsCategoryData,
    Search: {
      title: "Search Result",
      faqs: searchedItems,
    },
  };

  const onSearch = () => {
    if (searchInputRef.current) {
      const value = searchInputRef.current.value;
      setSearchedItems(
        AllFaqs.filter((data) => {
          const descText =
            typeof data.desc === "string"
              ? data.desc
              : extractTextFromJSX(data.desc);
          return (
            data.faq.toLocaleLowerCase().includes(value) ||
            descText.toLocaleLowerCase().includes(value)
          );
        })
      );
      if (value) {
        setActiveFaqCategory("Search");
      } else {
        setActiveFaqCategory("All");
      }
    }
  };

  const [activeFaqCategory, setActiveFaqCategory] =
    useState<keyof typeof FaqsData>("All");

  return (
    <section className="w-full flex justify-center" id="faqs-section">
      <div
        className={classNames(
          "w-full max-w-[1440px] mx-auto bg-white flex-col justify-center md:items-center gap-10 inline-flex",
          "px-4 md:px-[100px] py-[60px] lg:px-[180px] lg:[80px] xl:px-[220px] xl:py-[100px]"
        )}
      >
        <div className="flex flex-col gap-2 md:gap-3">
          <div className=" md:text-center text-black text-2xl md:text-[40px] font-semibold capitalize">
            Frequently asked questions
          </div>
          <div className="md:text-center text-black/60 text-sm md:text-lg font-normal capitalize leading-loose">
            Find Answers To Your Queries
          </div>
        </div>
        <div className="w-full max-w-[800px] flex flex-col gap-5 items-center">
          <div className="w-full flex gap-2 md:gap-4">
            <input
              type="text"
              placeholder="Type a question or keyword"
              ref={searchInputRef}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSearch();
                }
              }}
              className="w-full h-14 max-w-[800px] outline-offset-0 focus-visible:outline-primary outline outline-none py-4 px-5 bg-offWhite rounded-[100px] border border-placeholder"
            />
            <span
              className="cursor-pointer bg-primary-primary block !size-[56px] rounded-full"
              onClick={onSearch}
            >
              <Icons name="search" color="#FCFCFC" size={[56, 56]} />
            </span>
          </div>

          <ScrollArea className="max-w-full" type="auto">
            <div className="flex gap-2 mb-4 select-none">
              {Object.entries(FaqsData).map(([category, value]) => {
                return (
                  <div
                    key={category}
                    style={{ textWrap: "nowrap" }}
                    className={classNames(
                      "text-sm rounded-full px-4 py-2 border cursor-pointer  order-2 text-body",
                      activeFaqCategory === category
                        ? "bg-primary-primary text-white font-semibold"
                        : "!bg-background ",
                      category === "Search" &&
                        !searchInputRef.current?.value &&
                        "hidden",
                      category === "Search" && "!order-1"
                    )}
                    //   @ts-expect-error ...
                    onClick={() => setActiveFaqCategory(category)}
                  >
                    {value.title}
                  </div>
                );
              })}

              <ScrollBar orientation="horizontal" />
            </div>
          </ScrollArea>
        </div>
        <div className="w-full max-w-[1050px]  lg:px-10">
          <ScrollArea className="max-h-[500px] flex flex-col " type="auto">
            <Accordion type="single" collapsible>
              {FaqsData[activeFaqCategory].faqs.map((faqData) => {
                return (
                  <AccordionItem
                    value={faqData.faq}
                    key={faqData.faq}
                    className="w-full bg-white px-4  rounded-2xl mb-4  "
                  >
                    <AccordionTrigger className="text-left text-body md:text-base">
                      {faqData.faq}
                    </AccordionTrigger>
                    <AccordionContent className="font-normal text-body">
                      {faqData.desc}
                    </AccordionContent>
                  </AccordionItem>
                );
              })}

              {FaqsData[activeFaqCategory].faqs.length === 0 && (
                <div className="text-sm max-w-[800px] mx-auto bg-white rounded-md px-4 py-2 border cursor-pointer font-semibold">
                  No Faqs Available
                </div>
              )}
            </Accordion>
            <ScrollBar />
          </ScrollArea>
        </div>
      </div>
    </section>
  );
};
