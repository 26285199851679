import { Images } from "@src/components/images";
import classNames from "clsx";
import { CitiesType } from "../data";

export const CityCard = ({ cityName }: { cityName: CitiesType }) => {
  return (
    <div
      className={classNames(
        "px-6 md:px-14 py-4 rounded-2xl",
        "bg-[linear-gradient(132deg,_#F8F9FF_-11.05%,_#D9F4FC_115.16%)]"
      )}
    >
      <div className="flex flex-col items-center gap-2">
        <Images
          className=" h-[48px] md:h-[74px]"
          name={`city/${cityName}`}
          alt={`${cityName}`}
        />
        <p className="text-sm md:text-base font-bold text-[#4E4B66] capitalize">
          {cityName}
        </p>
      </div>
    </div>
  );
};
