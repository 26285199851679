import { cityNamesType } from "../../../lib/plans";

// Bangalore and other cities --> white product

export type cityPopupNameType=cityNamesType | "Others";

export default function isAssetColorThemeDark(
  cityName: cityPopupNameType | ""= "Bangalore"
): boolean {
  if (cityName == "Bangalore" || cityName == "Others" || cityName == "") return false;
  else return true;
}
