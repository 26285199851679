import { CarouselWrapper } from "@src/components/ui/carouselWrapper";
import { ImgCard } from "./imgCard";
import { investorsImgsData } from "../data";

export const Investors = () => {
  return (
    <div className="">
      <div className="flex flex-wrap justify-evenly gap-4 md:hidden">
        {investorsImgsData.map(({ name }) => (
          <ImgCard key={name} name={name} />
        ))}
      </div>
      <CarouselWrapper
        CarouselData={[...investorsImgsData, ...investorsImgsData]}
        CarouselCard={ImgCard}
        props={{
          carousel: {
            className: "hidden md:block",
          },
          item: {
            className: "md:!pl-6",
          },
        }}
        options={{
          opts: {
            loop: true,
            align: "start",
          },
          autoscroll: {
            speed: 1,
            stopOnInteraction: false,
            stopOnMouseEnter: true,
          },
        }}
      />
    </div>
  );
};
