import { CarouselWrapper } from "@src/components/ui/carouselWrapper";
import { awardsImgsData } from "../data";
import { ImgCard } from "./imgCard";

export const Awards = ({
  direction = "forward",
}: {
  direction?: "forward" | "backward";
}) => {
  const data =
    direction === "backward" ? [...awardsImgsData].reverse() : awardsImgsData;
  return (
    <div>
      <CarouselWrapper
        CarouselData={[...data, ...data]}
        CarouselCard={ImgCard}
        props={{
          item: {
            className: "md:!pl-6",
          },
        }}
        options={{
          opts: {
            loop: true,
            align: "start",
          },
          autoscroll: {
            speed: 1,
            stopOnInteraction: false,
            stopOnMouseEnter: true,
            direction,
          },
        }}
      />
    </div>
  );
};
