import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function scrollToId(id: string, offset: number = 45) {
  const element = document.getElementById(id);
  // element && element.scrollIntoView({ behavior: "smooth",block: 'start'});
  // element && window.scrollBy({ top: -10,
  //   behavior: "smooth",});

  if (element) {
    const headerOffset = offset;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      behavior: "smooth",
      top: offsetPosition,
    });
  }
}
