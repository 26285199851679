import { Icons, Logos } from "@src/components/icons";
import classNames from "clsx";
import { dpAdvantageGridData } from "./data";
import SectionHeading from "@src/components/ui/heading";

import { ScrollArea, ScrollBar } from "@src/components/ui/scroll-area";
import { Images } from "@src/components/images";

export const DpAdvantageGrid = () => {
  return (
    <section
      className="py-10 md:p-[80px_0px_130px_0px] max-w-[1200px] mx-auto bg-white"
      id="drinkprime-advantage-section"
    >
      <SectionHeading
        className="max-w-[1200px] px-4"
        description="Experience the smartest water purification solutions with DrinkPrime."
        descriptionStyle="mt-3"
      >
        <span className="text-primary-primary">The DrinkPrime Advantage</span>{" "}
        <br /> Next-gen Water Purification at Best Costs
      </SectionHeading>
      <ScrollArea className="w-full pl-4 md:pl-0" type="auto">
        <table className="min-w-[750px] w-full md:w-[1200px] mt-12">
          <thead>
            <tr
              className={classNames(
                "[&_th]:h-[160px] [&_td]:w-[150px] md:[&_td]:w-[240px] border-b",
                "[&_td]:px-5 [&_td]:py-[20px] [&_th_p]:text-black/80 [&_th_p]:font-semibold"
              )}
            >
              <th className=" md:table-cell text-2xl font-semibold capitalize leading-8 text-body">
                Choose <br /> Smart
              </th>
              <th>
                <div className="flex flex-col items-center">
                  <Images className="w-[74px] h-[90px]" name="misc/water-can" alt="water can" />{" "}
                  <p className="mt-2 text-xs md:text-[15px]">Water Can</p>
                </div>
              </th>
              <th>
                <div className="flex flex-col items-center">
                  <Images
                    className="w-[74px] h-[90px]"
                    name="misc/other-purifier"
                    alt="other water purifier"
                  />{" "}
                  <p className="mt-2">Other Purifiers</p>
                </div>
              </th>
              <th className="bg-background">
                <div className="flex flex-col items-center relative top-3">
                  <Logos name="dp-initials" size={[47, 36]} />
                  <p className="mt-[38px]">DrinkPrime</p>
                </div>
              </th>
              <th>
                <div className="flex flex-col items-center relative top-3">
                  <Icons name="success-tick" size={[48, 48]} />
                  <p className="mt-[28px]">DrinkPrime Advantage</p>
                </div>
              </th>
            </tr>
          </thead>
          <tbody
            className={classNames(
              "[&_td]:w-[150px] [&_td]:h-[44px] md:[&_td]:w-[240px] md:[&_td]:h-[86px]",
              "[&_td]:p-2 md:[&_td]:px-5 md:[&_td]:py-6 [&_td]:border-b [&_td]:text-body"
            )}
          >
            {dpAdvantageGridData.map((row, index) => (
              <tr key={index}>
                <td
                  className={classNames(
                    "first-letter:text-primary-primary first-letter:text-xl first-letter:font-bold",
                    "capitalize text-[15px] font-semibold  md:table-cell text-xs md:text-sm"
                  )}
                >
                  {row.category}
                </td>
                <td>
                  <div className="flex items-center gap-2.5 text-xs md:text-sm">
                    <span>
                      <Icons
                        className="size-[14px] md:size-4"
                        name={row.waterCan.icon}
                      />
                    </span>{" "}
                    {row.waterCan.text}
                  </div>
                </td>
                <td>
                  <div className="flex items-center gap-2.5 text-xs md:text-sm">
                    <span>
                      <Icons
                        className="size-[14px] md:size-4"
                        name={row.otherPurifiers.icon}
                      />
                    </span>{" "}
                    {row.otherPurifiers.text}
                  </div>
                </td>
                <td className="bg-background">
                  <div className="flex items-center gap-2.5 text-xs md:text-sm">
                    {" "}
                    <span>
                      <Icons
                        className="size-[14px] md:size-4"
                        name={row.drinkPrime.icon}
                      />
                    </span>{" "}
                    {row.drinkPrime.text}
                  </div>
                </td>
                <td>
                  <div className="flex items-center gap-2.5 text-xs md:text-sm">
                    {row.drinkPrimeAdvantage.text}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
    </section>
  );
};
