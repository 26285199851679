import { Homepage } from "./pages/homepage";
import { ErrorBoundary } from "react-error-boundary";

function App() {
  return (
    <ErrorBoundary
      FallbackComponent={() => (
        <div className="flex justify-center items-center">
          <h1 className="text-7xl text-primary-primary">Error Occured</h1>
        </div>
      )}
      onError={() => {
        console.log("Error Occurred in ErrorBoundary");
      }}
    >
      <Homepage />
    </ErrorBoundary>
  );
}

export default App;
