import { cityNamesArray } from "@src/lib/plans";

export const menuLinks = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Our Products",
    subMenu: [
      {
        name: "DrinkPrime Copper",
        path: `/app/[city]/ro-uv-copper-water-purifier-on-rent`,
      },
      {
        name: "DrinkPrime Alkaline",
        path: `/app/[city]/ro-uv-alkaline-water-purifier-on-rent`,
      },
      {
        name: "DrinkPrime Mineral+",
        path: `/app/[city]/ro-uv-mineral-water-purifier-on-rent`,
      },
    ],
  },
  {
    name: "How It Works",
    path: "#how-it-works-section",
  },
  {
    name: "About Us",
    path: "https://drinkprime.in/about",
  },
  {
    name: "Refer & Earn",
    path: "https://drinkprime.in/app/refer_earn",
  },
  {
    name: "Blogs",
    path: "https://drinkprime.in/blog/",
  },
  {
    name: "Contact Us",
    path: "https://drinkprime.in/app/contact-us",
  },
];

export const cityNames = [
  "Bengaluru",
  ...cityNamesArray.slice(1),
  "Other City",
] as const;

export const extractHref = (path: string | string[] | undefined): string => {
  if (path === undefined) return "";
  if (Array.isArray(path)) {
    const filteredPath = path.find((link) => {
      if (link.startsWith("#") && document.getElementById(link.slice(1))) {
        return true;
      } else if (link.startsWith("http")) {
        return true;
      } else if (link.startsWith("/")) {
        return true;
      }
    });

    if (filteredPath) return filteredPath;
  } else if (typeof path === "string") return path;

  return "";
};
