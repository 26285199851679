import AutoScroll from "embla-carousel-auto-scroll";
import classNames from "clsx";

import {
  CarouselContent,
  CarouselItem,
  Carousel,
} from "@src/components/ui/carousel";
import { IconsType } from "@src/components/icons/types";
import { Icons } from "@src/components/icons";

export const BannerStrip = ({
  stripData,
}: {
  stripData: {
    icon?: IconsType;
    text: string;
  }[];
}) => {
  return (
    <div
      className={classNames(
        "text-white text-[10px] md:text-sm py-2 bg-primary-primary"
      )}
    >
      <Carousel
        opts={{
          loop: true,
        }}
        plugins={[
          AutoScroll({
            speed: 0.5,
            stopOnInteraction: false,
            stopOnMouseEnter: true,
          }),
        ]}
      >
        <CarouselContent className="-ml-4 flex md:justify-center">
          {stripData.map((data, index) => (
            <CarouselItem
              key={index}
              className={classNames(
                "max-w-fit border-r px-4 md:px-4 lg:px-12  select-none text-[11px] md:text-base text-[#FCFCFC] font-bold flex gap-2 items-center",
                {
                  "md:border-0": index === stripData.length - 1,
                }
              )}
            >
              {data.icon && (
                <Icons
                  name={data.icon}
                  className="w-6 h-6 md:w-12 md:h-12"
                  color="#fdfdfd"
                />
              )}
              {data.text}
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
};
