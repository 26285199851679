import * as Dialog from "@radix-ui/react-dialog";
import classNames from "clsx";
import {
  ComponentPropsWithoutRef,
  PropsWithChildren,
  useContext,
  useState,
} from "react";
import {
  BengaluruSvg,
  DelhiSvg,
  FaridabadSvg,
  GhaziabadSvg,
  GurgaonSvg,
  HyderabadSvg,
  MumbaiSvg,
  NoidaSvg,
  PuneSvg,
} from "./CityAssets";
import { cn } from "../../../lib/utils";
import { cityNamesArray, cityNamesType } from "../../../lib/plans";
import { CityPopupContext } from "../../../pages/homepage";
import {
  getSelectedCityPopupFromLocalStorage,
  setSelectedCityPopupToLocalStorage,
} from "../../../lib/selectedCityInPopup";
import mainOrganicPopup from "@src/hooks/useOrganicPopup";

export function LocationDialogButtonTrigger({
  children,
  ...delegatedProps
}: PropsWithChildren<ComponentPropsWithoutRef<"button">>) {
  const { isCityPopupOpen } = useContext(CityPopupContext);
  return (
    <Dialog.Root open={isCityPopupOpen}>
      {/* <Dialog.Root open={false}>  */}
      <Dialog.Trigger {...delegatedProps}>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay
          className={classNames(
            "fixed inset-0 bg-black/50 backdrop-blur-sm shadow-xl z-[4999]"
          )}
        />
        <Dialog.Content
          className={cn(
            "fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[5000] max-w-[600px] w-[calc(100%-32px)] rounded-[16px] mx-auto"
          )}
        >
          <CityPopup />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

const popupAssets = {
  Bengaluru: BengaluruSvg,
  Hyderabad: HyderabadSvg,
  Delhi: DelhiSvg,
  Gurgaon: GurgaonSvg,
  Ghaziabad: GhaziabadSvg,
  Faridabad: FaridabadSvg,
  Noida: NoidaSvg,
  Mumbai: MumbaiSvg,
  Pune: PuneSvg,
};

function CityPopup() {
  const { cityPopupSelection, setCityPopupSelection, setIsCityPopupOpen,onDialogCloseCallback } =
    useContext(CityPopupContext);
  const [isShowingError, setIsShowingError] = useState(false);
  const [citySelected, setCitySelected] = useState<
    cityNamesType | "Others" | ""
  >(cityPopupSelection);

  const showOrganicPopup = () => {
    if (getSelectedCityPopupFromLocalStorage() === "") {
      setTimeout(() => {
        mainOrganicPopup();
      }, 5000);
    }
  };

  return (
    <div
      className={cn(
        "px-4 py-6 sm:px-8 sm:py-10 bg-white space-y-6 rounded-[16px] shadow-2xl",
        "text-center"
      )}
    >
      {/* Header */}
      <div className="">
        <Dialog.Title className="text-[24px] leading-[32px] font-bold text-[#0E0F0F]">
          We Offer Customised Solutions For Your City
        </Dialog.Title>
        <Dialog.Description className="text-[16px] leading-[24px] mt-1 text-[#7A7E80]">
          Please select your city to proceed
        </Dialog.Description>
      </div>
      {/* Body */}
      <div className="grid grid-cols-3 gap-y-4 gap-x-2 sm:gap-6 items-end">
        {Object.entries(popupAssets).map(([cityName, AssetSvg], index) => (
          // card
          <label
            className={cn(
              "rounded-[16px] py-2 px-2 sm:px-8 h-full -xs:max-h-[100px]",
              "space-y-1.5 sm:space-y-2",
              "bg-[linear-gradient(132deg,#F8F9FF_-11.05%,#D9F4FC_115.16%)]",
              cityNamesArray[index] == citySelected &&
                "outline outline-[2px] outline-primary-primary scale-105"
            )}
            key={index}
          >
            <input
              type="radio"
              value={cityNamesArray[index]}
              // id={cityName + "-id-citypopup"}
              name="cityName"
              checked={cityNamesArray[index] == citySelected}
              className="hidden"
              onChange={() => {
                setCitySelected(cityNamesArray[index]);
                setIsShowingError(false);
              }}
            />
            <div className="mx-auto w-fit max-w-full">
              <AssetSvg
                fill={
                  cityNamesArray[index] == citySelected ? "#4548B9" : "#4E4B66"
                }
              />
            </div>
            <p
              className={cn(
                "text-[13px] sm:text-[16px] leading-[18px] text-body font-semibold",
                cityNamesArray[index] == citySelected && "text-primary-primary"
              )}
            >
              {cityName}
            </p>
          </label>
        ))}
      </div>
      {/* Button group */}
      {/* <ButtonGroup /> */}

      <div className="flex gap-4 sm:gap-6 nth-n:flex-1">
        <button
          type="button"
          className="rounded-full border border-primary font-medium leading-[20px] sm:leading-[16px] py-3 sm:py-4 text-primary-primary"
          onClick={() => {
            // showOrganicPopup();
            setCityPopupSelection("Others");
            setSelectedCityPopupToLocalStorage("Others");
            setIsCityPopupOpen(false);
            onDialogCloseCallback()
          }}
        >
          Other cities
        </button>
        <button
          type="button"
          className={cn(
            "rounded-full border border-primary-primary font-medium leading-[20px] sm:leading-[16px] py-3 sm:py-4 bg-primary-primary text-white",
            !citySelected &&
              "bg-opacity-20 text-black/30 border-[0px] bg-gray-500 cursor-not-allowed"
          )}
          onClick={() => {
            if (citySelected) {
              showOrganicPopup();
              setCityPopupSelection(citySelected);
              setSelectedCityPopupToLocalStorage(citySelected);
              setIsCityPopupOpen(false);
              onDialogCloseCallback()
            } else {
              setIsShowingError(true);
            }
          }}
        >
          Continue
        </button>
      </div>
      <p
        className={classNames(
          "text-[var(--alert-alert)] !mt-1",
          isShowingError ? "block" : "hidden"
        )}
      >
        Please select one of the city
      </p>
    </div>
  );
}

export default CityPopup;
