import { useForm } from "react-hook-form";
import { LeadFormInputNamesArray, LeadFormInputNamesType } from "./utils";
import * as yup from "yup";
import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

const yupSchemaObject: Record<
  (typeof LeadFormInputNamesArray)[number],
  yup.StringSchema
> = {
  name: yup
    .string()
    .matches(/^[a-zA-Z ]*$/, {
      message: "Enter Only Characters",
      excludeEmptyString: false,
    })
    .required("Name is required"),
  mobile: yup
    .string()
    .matches(/^[6-9]\d{9}$/, {
      message: "Please enter a valid number",
      excludeEmptyString: false,
    })
    .required("Phone Number is required"),
  city: yup
    .string()
    .notOneOf(
      ["Choose City"],
      "Please choose one of the city from the dropdown"
    )
    .required("City required"),
  coupon: yup.string().matches(/^[a-zA-Z0-9]{8}$/, {
    message: "The entered code is invalid. It must be exactly 8 characters",
    excludeEmptyString: true,
  }),
  otherCity: yup.string().when("city", {
    is: "Others",
    then: () => yup.string().required("Other city is required"),
    otherwise: () => yup.string(),
  }),
};
const leadFormSchema = yup.object(yupSchemaObject).required();

function useLeadForm() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitted },
    watch,
    resetField,
    setValue,
  } = useForm<LeadFormInputNamesType>({
    // @ts-expect-error types of property types are incompatible
    resolver: yupResolver(leadFormSchema),
    mode: "onTouched",
  });

  const setDummyValues = () => {
    setValue("name", "dptest", {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("mobile", "9876543210", {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("city", "Bangalore", {
      shouldValidate: true,
      shouldDirty: true,
    });
  };
  const cityWatched = watch("city");
  useEffect(() => {
    if (cityWatched !== "Others") {
      resetField("otherCity");
    }
  }, [cityWatched, resetField]);

  return {
    useFormProps: {
      register,
      handleSubmit,
      formState: { errors },
      resetField,
      cityWatched,
      setValue,
    },
    setDummyValues,
    isSubmitting,
    isSubmitted,
    handleSubmit,
  };
}

export default useLeadForm;
