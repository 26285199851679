export const testimonialData = [
  {
    name: "Shyamanta baruah",
    position: "bangalore",
    ytVideoId: "MB1Qy2tne5c",
    testimonial:
      "It’s just something that you fit and forget. You fit the device, you subscribe to a plan and that's it. And you have an app so I think it's convenient, it's cost effective and it's safe.",
  },
  {
    name: "Varsha",
    position: "IT Professional - Bangalore",
    ytVideoId: "gM865QPvMp0",
    testimonial:
      "We faced a lot of throat issues with corporation water, but after switching to DrinkPrime there are no health issues. It is hassle free with easy subscription, customer support and tracking in app.",
  },
  {
    name: "Bhanu Prasanna",
    position: "Bangalore",
    ytVideoId: "vtuJBINoqso",
    testimonial:
      "In Bangalore I never felt my drinking water was safe. When DrinkPrime was installed, I started noticing like water flowing in different steps of purification.It's about the technology, such startups should be boosted up",
  },
];
