import {
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
  DropdownMenuItem,
} from "@src/components/ui/dropdown-menu";
import { Icons } from "@src/components/icons";
import { cn } from "@src/lib/utils";
import { useState } from "react";
import { cityNames } from "../data";
import classNames from "clsx";

export const SubMenu = ({
  subLink,
  index,
  activeSubMenuIndex = 0,
  setActiveSubMenuIndex,
}: {
  subLink: {
    name: string;
    path: string;
  };
  index: number;
  activeSubMenuIndex: number;
  setActiveSubMenuIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [activeCityIndex, setActiveCityIndex] = useState(-1);
  const offsetValue = [-4, -45, -86];
  return (
    <DropdownMenuSub open={activeSubMenuIndex === index ? true : false}>
      <DropdownMenuSubTrigger
        className={classNames(
          "w-[240px] p-2.5 border-b border-black/20",
          activeSubMenuIndex === index ? "bg-primary-primary text-[#FCFCFC]" : ""
        )}
        onMouseEnter={() => {
          setActiveSubMenuIndex(index);
        }}
      >
        <span>{subLink.name}</span>
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent
          sideOffset={16}
          alignOffset={offsetValue[index]}
          className={cn(
            "z-[99999] w-fit border-none shadow-none",
            "bg-transparent"
          )}
          onMouseLeave={() => {
            setActiveCityIndex(-1);
          }}
        >
          {cityNames.map((city, index) => {
            const path = subLink.path?.replace(
              "[city]",
              city === "Other City" ? "bengaluru" : city.toLowerCase()
            );
            return (
              <DropdownMenuItem
                className="border-b border-black/20 hover:bg-[#EEF1FF] hover:text-primary hover:rounded-md flex justify-between rounded-none w-[255px]"
                key={city}
                onMouseEnter={() => {
                  setActiveCityIndex(index);
                }}
              >
                <a href={path} className="p-1">
                  {city}
                </a>
                {activeCityIndex === index && (
                  <Icons
                    name="select-dropdown"
                    size={[12, 12]}
                    color="#4548B9"
                    className="-rotate-90"
                  />
                )}
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
};
