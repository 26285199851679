/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Dialog from "@radix-ui/react-dialog";
import classNames from "clsx";
import { PropsWithChildren, useContext } from "react";
import { cn } from "../../../lib/utils";
import { Cross2Icon } from "@radix-ui/react-icons";
import {
  alkalineCategoryArray,
  categoryArray,
  liteCategoryArray,
  PlanContext,
} from "./planSectionContext";
import {
  AlkalineCategoryType,
  categoryType,
  LiteCategoryType,
  Model,
} from "../../../lib/plans";
import { Icons } from "../../../components/icons";
export default function CompareAllPlansPopupTriggerButton({
  className,
  children,
}: PropsWithChildren<{ className?: string }>) {
  const { modelState } = useContext(PlanContext);
  return (
    <Dialog.Root>
      <Dialog.Trigger className={cn(className)}>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay
          className={cn(
            "fixed inset-0 z-[20000] bg-black/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
          )}
        />
        <Dialog.Content
          className={cn(
            "fixed left-[50%] top-[50%] z-[20000] w-full max-w-lg translate-x-[-50%] translate-y-[-50%]",
            "bg-background text-center",
            "border p-5 shadow-lg rounded-3xl max-w-[520px]"
          )}
        >
          <>
            <Dialog.Title className="text-xl font-medium lg:text-2xl flex items-center justify-center gap-2">
              <Icons name="popupPlans/comparePlan" />
              Compare our plans
            </Dialog.Title>
            <Dialog.Close className="absolute right-4 top-4 rounded-sm opacity-70">
              <Cross2Icon className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </Dialog.Close>
            <div className="w-full flex flex-col gap-1.5 !mt-3">
              <PopupGridContent modelState={modelState} />
            </div>
          </>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

function PopupGridContent({ modelState }: { modelState: Model }) {
  const { plan } = useContext(PlanContext);

  const mappedKeys = Object.keys(plan) as
    | categoryType[]
    | LiteCategoryType[]
    | AlkalineCategoryType[];
  const { setCategoryState, setHelpPopup } = useContext(PlanContext);
  return (
    // ul.popup > li {flex items-center justify-center}
    <ul
      id="popup"
      className={classNames(
        "grid grid-rows-[repeat(5,minmax(auto))] divide-x divide-y",
        modelState == "ALIVE" ? "grid-cols-5" : "grid-cols-3",
        "border-t border-x rounded-t-md rounded-b-3xl",
        "max-w-[100%] z-50"
      )}
    >
      <li></li>
      {(modelState == "ALIVE"
        ? categoryArray
        : modelState == "LITE"
        ? liteCategoryArray
        : alkalineCategoryArray
      ).map((item, index) => (
        <li key={index} className="!border-t-0 pt-2">
          <div
            className={classNames(
              "flex flex-col justify-items-stretch items-center",
              "w-full h-full"
            )}
          >
            <img
              src={item.categoryImage}
              alt={`${item.categoryName}`}
              className={classNames("w-5 h-5")}
            />
            <h3 className="text-sm font-bold text-primary capitalize">
              {item.categoryName.toLowerCase()}
            </h3>
            <div className="flex flex-col justify-center w-full bg-[#EEF1FF] p-2 h-full">
              <h3 className="text-center text-[12px] capitalize">
                {/* @ts-expect-error  asdf */}
                {plan[item.categoryName]?.[28]?.["litres"]} Litres
              </h3>
              <h4 className="text-[8px] text-center">per month</h4>
            </div>
          </div>
        </li>
      ))}
      <li className="text-xs md:text-sm md:p-4 flex flex-col items-center bg-[#F8F9FF]">
        {/* <img src={CoinIcon} alt="icom" /> */}
        <Icons name="popupPlans/coins" />
        <span className="font-semibold">Price</span>

        <span className="text-xxs">per month</span>
      </li>
      {mappedKeys.map((categoryName, index) => (
        <li className="text-xs md:text-sm font-medium" key={index}>
          ₹{(plan as any)[categoryName]["28"].price}
        </li>
      ))}
      <li className="text-xs md:text-sm md:p-4 flex flex-col items-center bg-[#F8F9FF]">
        {/* <img src={WaterCanIcon} alt="icom" /> */}
        <Icons name="popupPlans/waterCan" />
        <span className="font-semibold">Water cans</span>

        <span className="text-xxs">equal to litres</span>
      </li>
      {modelState == "ALIVE" ? (
        <>
          <li className="text-xs md:text-sm font-medium">6</li>
          <li className="text-xs md:text-sm font-medium">10</li>
          <li className="text-xs md:text-sm font-medium">25</li>
          <li className="text-xs md:text-sm font-medium">40</li>
        </>
      ) : (
        <>
          <li className="text-xs md:text-sm font-medium">25</li>
          <li className="text-xs md:text-sm font-medium">40</li>
        </>
      )}
      <li className="!border-l-0 text-xs md:text-sm md:p-4 flex flex-col items-center bg-[#F8F9FF]">
        {/* <img src={PeopleIcon} alt="icom" /> */}
        <Icons name="popupPlans/people" />
        <span className="font-semibold">People</span>

        <span className="text-xxs">in a house</span>
      </li>
      {modelState == "ALIVE" ? (
        <>
          <li className="text-xs md:text-sm font-medium">1-2</li>
          <li className="text-xs md:text-sm font-medium">2-3</li>
          <li className="text-xs md:text-sm font-medium">5+</li>
          <li className="text-xs md:text-sm font-medium">7+</li>
        </>
      ) : (
        <>
          <li className="text-xs md:text-sm font-medium">5+</li>
          <li className="text-xs md:text-sm font-medium">7+</li>
        </>
      )}
      <li className="!border-l-0"></li>
      {mappedKeys.map((categoryName, index) => (
        <li className="!border-l-0 px-1" key={index}>
          <Dialog.Close className="w-full">
            <button
              type="button"
              className={classNames(
                "w-full my-2",
                "xs:w-[calc(100%_-_12px)]",
                "py-2 border rounded-full border-primary",
                "text-xs text-primary capitalize font-semibold"
              )}
              onClick={() => {
                setCategoryState(categoryName);
                setHelpPopup(false);
              }}
            >
              select
            </button>
          </Dialog.Close>
        </li>
      ))}
    </ul>
  );
}
