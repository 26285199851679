type FillProps = {
  fill: "#4E4B66" | "#4548B9";
};
export function BengaluruSvg({ fill = "#4E4B66" }: FillProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="103"
      height="56"
      viewBox="0 0 103 56"
      fill="none"
      className="max-w-full"
    >
      <path
        d="M8.09472 55.357H6.81055V29.0215H16.0566V52.0675H14.7724V30.3057H8.09472V55.357Z"
        fill={fill}
      />
      <path d="M35.3771 45.2227H7.45117V46.5068H35.3771V45.2227Z" fill={fill} />
      <path d="M102.935 54.7158H0.398438V56H102.935V54.7158Z" fill={fill} />
      <path d="M16.9045 29.0215H5.75195V30.3057H16.9045V29.0215Z" fill={fill} />
      <path d="M27.8409 34.6836H15.4141V35.9678H27.8409V34.6836Z" fill={fill} />
      <path d="M28.4814 29.665H27.1973V52.6024H28.4814V29.665Z" fill={fill} />
      <path
        d="M36.0291 53.2434H7.45117V51.9592H34.7449V35.502H36.0291V53.2434Z"
        fill={fill}
      />
      <path d="M36.6633 29.0215H26.1133V30.3057H36.6633V29.0215Z" fill={fill} />
      <path
        d="M68.805 50.2008H35.3867V48.9167H67.5208V35.502H68.805V50.2008Z"
        fill={fill}
      />
      <path
        d="M50.3934 35.5029H49.1092V33.2803H34.8252V35.5029H33.541V31.9961H50.3934V35.5029Z"
        fill={fill}
      />
      <path
        d="M69.7237 36.145H34.1816V34.8608H52.5948L52.5849 31.9961H69.7336V36.145H69.7237ZM53.8691 34.8608H68.4395V33.2803H53.8691V34.8608Z"
        fill={fill}
      />
      <path d="M61.3967 24.627H41.6797V25.9111H61.3967V24.627Z" fill={fill} />
      <path
        d="M62.8583 29.9507H39.8815L39.753 29.4765C39.7432 29.437 39.4962 28.5282 39.6543 27.491C39.8518 26.2265 40.5828 25.2881 41.7484 24.814V19.0352H61.3371V24.8337C61.7915 25.0411 62.4632 25.4659 62.8979 26.2463C63.3918 27.1452 63.4313 28.2417 63.0065 29.5061L62.8583 29.9507ZM40.9088 28.6665H61.9001C62.0681 27.9552 62.0187 27.3527 61.7619 26.8785C61.3568 26.1278 60.5271 25.9006 60.5172 25.9006L60.043 25.7721V25.2782V20.3292H43.0326V25.7623L42.5683 25.8907C41.6299 26.1574 41.0965 26.7303 40.9384 27.6589C40.8693 28.0343 40.8792 28.3899 40.9088 28.6665Z"
        fill={fill}
      />
      <path
        d="M39.9602 28.8228L36.0723 32.1797L36.9115 33.1517L40.7994 29.7948L39.9602 28.8228Z"
        fill={fill}
      />
      <path
        d="M62.8339 28.8354L61.959 29.7754L65.5311 33.1L66.406 32.16L62.8339 28.8354Z"
        fill={fill}
      />
      <path d="M39.1611 35.502H37.877V49.5588H39.1611V35.502Z" fill={fill} />
      <path d="M45.6885 35.502H44.4043V49.5588H45.6885V35.502Z" fill={fill} />
      <path d="M45.0471 38.5449H38.5176V39.8291H45.0471V38.5449Z" fill={fill} />
      <path d="M45.0471 42.5068H38.5176V43.791H45.0471V42.5068Z" fill={fill} />
      <path d="M49.0186 35.502H47.7344V49.5588H49.0186V35.502Z" fill={fill} />
      <path d="M55.5693 35.502H54.2852V49.5588H55.5693V35.502Z" fill={fill} />
      <path d="M54.9263 38.5449H48.377V39.8291H54.9263V38.5449Z" fill={fill} />
      <path d="M54.9263 42.5068H48.377V43.791H54.9263V42.5068Z" fill={fill} />
      <path d="M58.8564 35.502H57.5723V49.5588H58.8564V35.502Z" fill={fill} />
      <path d="M65.4072 35.502H64.123V49.5588H65.4072V35.502Z" fill={fill} />
      <path d="M64.7661 38.5449H58.2168V39.8291H64.7661V38.5449Z" fill={fill} />
      <path d="M64.7661 42.5068H58.2168V43.791H64.7661V42.5068Z" fill={fill} />
      <path d="M68.8076 49.5596H67.5234V52.2069H68.8076V49.5596Z" fill={fill} />
      <path d="M95.6344 51.96H35.3867V53.2441H95.6344V51.96Z" fill={fill} />
      <path d="M75.9775 29.5254H74.6934V52.6011H75.9775V29.5254Z" fill={fill} />
      <path
        d="M96.2751 55.3586H94.9909V30.4258H88.6787V52.6025H87.3945V29.1416H96.2751V55.3586Z"
        fill={fill}
      />
      <path d="M95.6356 38.5449H68.1641V39.8291H95.6356V38.5449Z" fill={fill} />
      <path d="M95.6356 45.0449H68.1641V46.3291H95.6356V45.0449Z" fill={fill} />
      <path d="M88.0276 34.6133H75.334V35.8975H88.0276V34.6133Z" fill={fill} />
      <path d="M71.8779 52.6025H70.5938V55.3685H71.8779V52.6025Z" fill={fill} />
      <path d="M32.2764 52.6025H30.9922V55.3685H32.2764V52.6025Z" fill={fill} />
      <path d="M97.3353 29.1416H85.916V30.4258H97.3353V29.1416Z" fill={fill} />
      <path d="M77.2222 28.9443H65.6152V30.2285H77.2222V28.9443Z" fill={fill} />
      <path d="M35.3771 38.5449H7.45117V39.8291H35.3771V38.5449Z" fill={fill} />
      <path
        d="M42.0344 20.2102C41.9257 20.1411 39.3475 18.4025 39.5352 15.4489C39.7327 12.3274 42.9333 9.22557 49.0479 6.2127L49.1862 6.14355H54.0661L54.2044 6.2127C54.5501 6.38063 62.7689 10.4999 63.3616 15.3995C63.569 17.148 62.8183 18.7482 61.1192 20.1707L60.2993 19.1829C61.6625 18.0469 62.2453 16.8615 62.0873 15.5576C61.623 11.6458 54.807 7.97104 53.7698 7.43761H49.4925C42.5579 10.8753 40.9477 13.7301 40.8292 15.518C40.681 17.7209 42.7357 19.1236 42.7555 19.1434L42.0344 20.2102Z"
        fill={fill}
      />
      <path d="M41.7981 9.19629H41.0078V12.4364H41.7981V9.19629Z" fill={fill} />
      <path d="M61.7903 9.19629H61V12.4364H61.7903V9.19629Z" fill={fill} />
      <path
        d="M54.6382 6.79626H53.3541V2.99312C52.6922 1.83737 51.9118 1.45211 51.5859 1.33357C50.924 1.66943 50.272 2.53872 49.9658 3.01288V6.79626H48.6816V2.65726L48.7705 2.50909C48.8891 2.31152 49.9559 0.543303 51.3191 0.0592673L51.4772 0L51.6451 0.0296397C51.7143 0.039518 53.3936 0.345737 54.5691 2.52884L54.6481 2.66714V6.79626H54.6382Z"
        fill={fill}
      />
      <path
        d="M16.0566 29.6656H14.7724V27.028C13.6858 25.4673 11.9966 24.4795 11.3842 24.1535C10.7322 24.4202 9.9716 24.9931 9.17146 25.813C8.66766 26.3267 8.28241 26.8107 8.09472 27.0478V29.6656H6.81055V26.623L6.92909 26.4551C7.01799 26.3366 9.08255 23.5114 11.236 22.8397L11.4731 22.7705L11.7003 22.8792C11.809 22.9286 14.4267 24.1633 15.948 26.4847L16.0566 26.6428V29.6656Z"
        fill={fill}
      />
      <path
        d="M36.0192 29.8034H34.7351V27.1659C33.6682 25.6248 32.1667 24.637 31.5839 24.3011C30.3096 24.8346 28.976 26.4151 28.4228 27.1757V29.8034H27.1387V26.7707L27.2473 26.6028C27.3264 26.4843 29.2823 23.6492 31.4456 22.9775L31.6926 22.8984L31.9296 23.017C32.0284 23.0664 34.3893 24.3012 35.9007 26.6127L36.0094 26.7707V29.8034H36.0192Z"
        fill={fill}
      />
      <path d="M31.9208 21.7324H31.3281V23.5698H31.9208V21.7324Z" fill={fill} />
      <path
        d="M68.8076 29.5561H67.5234V26.5827L67.5827 26.4543C67.642 26.3259 69.0052 23.4019 71.2278 22.7104L71.3661 22.6709L71.5044 22.6907C71.5637 22.7005 72.1366 22.7894 72.9565 23.3031C74.0036 23.9551 74.9815 24.9824 75.8706 26.3555L75.9694 26.5136V29.5363H74.6852V26.8988C73.2035 24.696 71.8501 24.123 71.4451 24.0045C70.6647 24.3107 70.0029 25.0417 69.5781 25.6245C69.183 26.1579 68.9163 26.6617 68.7977 26.879V29.5561H68.8076Z"
        fill={fill}
      />
      <path d="M71.7099 21.4746H71.1172V23.312H71.7099V21.4746Z" fill={fill} />
      <path
        d="M96.2751 29.7836H94.9909V27.1461C93.9241 25.6051 92.3633 24.6173 91.7805 24.2814C90.5161 24.8148 89.2121 26.3953 88.6787 27.156V29.7935H87.3945V26.7707L87.5032 26.6127C87.5822 26.4941 89.4788 23.6492 91.6521 22.9775L91.899 22.8984L92.1262 23.017C92.225 23.0664 94.6551 24.3012 96.1763 26.6127L96.285 26.7707V29.7836H96.2751Z"
        fill={fill}
      />
      <path d="M92.1298 21.7324H91.5371V23.5698H92.1298V21.7324Z" fill={fill} />
      <path
        d="M50.3915 32.7261L49.1172 32.5384C49.1271 32.4495 49.4432 30.4343 51.2509 29.0217L51.5966 28.7451L51.972 28.9723C52.051 29.0217 53.8489 30.138 53.8588 32.6273H52.5746C52.5746 31.4617 52.0313 30.7307 51.6559 30.3553C50.5989 31.4221 50.3915 32.7162 50.3915 32.7261Z"
        fill={fill}
      />
      <path d="M36.0283 29.665H34.7441V32.6384H36.0283V29.665Z" fill={fill} />
      <path d="M68.8076 29.5254H67.5234V32.6272H68.8076V29.5254Z" fill={fill} />
      <path d="M11.7294 21.6143H11.1367V23.4516H11.7294V21.6143Z" fill={fill} />
      <path
        d="M54.2142 19.776L52.9498 19.5784C53.3844 16.8125 52.199 15.3011 51.6162 14.7479C50.7667 15.3801 50.3617 16.5161 50.174 17.4052C49.927 18.5807 49.9764 19.6278 49.9764 19.6377L48.6922 19.7068C48.6824 19.5093 48.4749 14.8467 51.3791 13.3847L51.715 13.2168L52.0212 13.4242C52.1398 13.5033 54.9057 15.3801 54.2142 19.776Z"
        fill={fill}
      />
      <path d="M92.5337 32.7666H91.1211V36.1845H92.5337V32.7666Z" fill={fill} />
      <path d="M72.2155 41.9238H70.6152V43.5241H72.2155V41.9238Z" fill={fill} />
      <path d="M72.2155 48.3447H70.6152V49.945H72.2155V48.3447Z" fill={fill} />
      <path d="M92.6315 41.9238H91.0312V43.5241H92.6315V41.9238Z" fill={fill} />
      <path d="M82.6569 41.9541H80.7207V43.1889H82.6569V41.9541Z" fill={fill} />
      <path d="M82.6569 49.1543H80.7207V51.8906H82.6569V49.1543Z" fill={fill} />
      <path d="M92.4355 49.1543H91.2402V51.8906H92.4355V49.1543Z" fill={fill} />
      <path d="M12.1841 32.6387H10.7715V36.0566H12.1841V32.6387Z" fill={fill} />
      <path d="M32.602 32.6387H31.1895V36.0566H32.602V32.6387Z" fill={fill} />
      <path d="M12.3718 41.915H10.7715V43.5153H12.3718V41.915Z" fill={fill} />
      <path d="M32.6921 41.915H31.0918V43.5153H32.6921V41.915Z" fill={fill} />
      <path d="M32.446 48.4238H30.8457V50.0241H32.446V48.4238Z" fill={fill} />
      <path d="M12.1836 49.1748H10.9883V51.9111H12.1836V49.1748Z" fill={fill} />
      <path d="M22.7135 41.915H20.7773V43.1498H22.7135V41.915Z" fill={fill} />
      <path d="M22.7135 49.1748H20.7773V51.9111H22.7135V49.1748Z" fill={fill} />
      <path d="M72.1255 32.6787H70.7129V36.0966H72.1255V32.6787Z" fill={fill} />
    </svg>
  );
}

export function HyderabadSvg({ fill = "#4E4B66" }: FillProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="58"
      height="56"
      viewBox="0 0 58 56"
      fill="none"
      className="max-w-full"
    >
      <path d="M57.2695 54.9248H0.732422V55.999H57.2695V54.9248Z" fill={fill} />
      <path
        d="M16.1768 55.4629H15.1026V39.2256H11.0371V55.4629H9.96289V38.1514H16.1768V55.4629Z"
        fill={fill}
      />
      <path
        d="M43.2393 55.4635H42.1651V38.8379L40.5703 36.2928L41.4793 35.7227L43.2393 38.5322V55.4635Z"
        fill={fill}
      />
      <path
        d="M48.1875 55.4635H47.1133V38.5404L48.8238 35.7227L49.741 36.2846L48.1875 38.8379V55.4635Z"
        fill={fill}
      />
      <path d="M22.8047 35.7969H21.7305V55.4634H22.8047V35.7969Z" fill={fill} />
      <path d="M36.6113 35.7969H35.5371V55.4634H36.6113V35.7969Z" fill={fill} />
      <path
        d="M50.1475 36.3336H7.91406V29.376H50.1475V36.3336ZM8.98828 35.2594H49.0733V30.4502H8.98828V35.2594Z"
        fill={fill}
      />
      <path d="M41.0143 32.9209H17.4062V33.9951H41.0143V32.9209Z" fill={fill} />
      <path d="M22.2566 52.0586H10.498V53.1328H22.2566V52.0586Z" fill={fill} />
      <path d="M47.6428 52.0586H36.0742V53.1328H47.6428V52.0586Z" fill={fill} />
      <path
        d="M8.88244 35.4663L8.0293 36.1191L10.0831 38.8033L10.9363 38.1505L8.88244 35.4663Z"
        fill={fill}
      />
      <path
        d="M15.7569 39.0192L14.9141 38.3499L16.8724 35.9122V29.9131H17.9467V36.2841L15.7569 39.0192Z"
        fill={fill}
      />
      <path d="M41.6758 29.9131H40.6016V35.7965H41.6758V29.9131Z" fill={fill} />
      <path
        d="M36.6096 46.4966L35.5354 46.4305C35.6924 43.9102 31.1476 41.8939 29.1975 41.2659C27.3052 41.7865 24.851 42.7698 23.5619 44.5299C22.7934 45.5793 22.8017 46.4057 22.8017 46.447L21.7275 46.4883C21.7192 46.3148 21.6283 42.1749 29.0735 40.1917L29.2222 40.1504L29.371 40.1917C29.6767 40.2909 36.8492 42.5385 36.6096 46.4966Z"
        fill={fill}
      />
      <path
        d="M15.9174 22.379L14.5547 24.499L15.4583 25.0799L16.821 22.9598L15.9174 22.379Z"
        fill={fill}
      />
      <path
        d="M16.1919 12.6765H9.8457V8.00781H16.1919V12.6765ZM10.9199 11.6023H15.1177V9.08203H10.9199V11.6023Z"
        fill={fill}
      />
      <path d="M12.0195 12.3203H10.9453V20.253H12.0195V12.3203Z" fill={fill} />
      <path d="M15.2422 12.3203H14.168V20.253H15.2422V12.3203Z" fill={fill} />
      <path
        d="M15.2422 8.54426H14.168V4.80102C13.8374 3.82595 13.3582 3.25578 13.0524 2.96657C12.5484 3.52847 12.1683 4.42917 12.0195 4.85059V8.2881H10.9453V4.68533L10.9701 4.6027C10.9949 4.52006 11.5981 2.65257 12.7054 1.83451L12.9863 1.62793L13.2921 1.80146C13.3417 1.83451 14.5729 2.56168 15.2174 4.55312L15.2422 4.63575V8.54426Z"
        fill={fill}
      />
      <path
        d="M48.5122 12.6765H42.166V8.00781H48.5122V12.6765ZM43.2402 11.6023H47.438V9.08203H43.2402V11.6023Z"
        fill={fill}
      />
      <path d="M44.3359 12.3203H43.2617V20.253H44.3359V12.3203Z" fill={fill} />
      <path d="M47.5508 12.3203H46.4766V20.253H47.5508V12.3203Z" fill={fill} />
      <path
        d="M47.5527 8.54426H46.4785V4.80102C46.148 3.82595 45.6687 3.25578 45.363 2.96657C44.8589 3.52847 44.4788 4.42091 44.3301 4.85059V8.2881H43.2559V4.68533L43.2806 4.6027C43.3054 4.52006 43.9086 2.65257 45.0159 1.83451L45.2969 1.62793L45.6026 1.80146C45.6522 1.83451 46.8834 2.56168 47.528 4.55312L47.5527 4.63575V8.54426Z"
        fill={fill}
      />
      <path d="M11.5977 24.7822H10.5234V29.9137H11.5977V24.7822Z" fill={fill} />
      <path d="M15.541 24.7822H14.4668V29.9137H15.541V24.7822Z" fill={fill} />
      <path
        d="M43.8566 29.913H42.7824V25.3186H10.7375L9.2832 22.5835V19.9971H16.8771L16.9845 21.9141H41.3198V19.9971H48.9963V22.8231L47.2693 25.3186H43.8566V29.913ZM43.6913 24.2444H46.7074L47.9221 22.4926V21.0713H42.394V22.319L43.6913 24.2444ZM11.3821 24.2444H42.394L41.5429 22.9884H15.9599L15.8525 21.0713H10.3574V22.3108L11.3821 24.2444Z"
        fill={fill}
      />
      <path d="M47.3789 24.7822H46.3047V29.9137H47.3789V24.7822Z" fill={fill} />
      <path
        d="M18.8777 41.6468C18.5554 41.6468 18.291 41.3824 18.291 41.0601V39.614C18.291 39.2918 18.5554 39.0273 18.8777 39.0273C19.2 39.0273 19.4644 39.2918 19.4644 39.614V41.0601C19.4644 41.3906 19.2 41.6468 18.8777 41.6468Z"
        fill={fill}
      />
      <path
        d="M19.7471 29.6733H18.582V27.8884C18.582 27.5662 18.8465 27.3018 19.1687 27.3018C19.491 27.3018 19.7554 27.5662 19.7554 27.8884V29.6733H19.7471Z"
        fill={fill}
      />
      <path
        d="M13.6729 2.37156H12.5078V0.586698C12.5078 0.264432 12.7722 0 13.0945 0C13.4168 0 13.6812 0.264432 13.6812 0.586698V2.37156H13.6729Z"
        fill={fill}
      />
      <path
        d="M45.7511 2.37156H44.5859V0.586698C44.5859 0.264432 44.8504 0 45.1726 0C45.4949 0 45.7593 0.264432 45.7593 0.586698V2.37156H45.7511Z"
        fill={fill}
      />
      <path
        d="M23.7882 29.6733H22.623V27.8884C22.623 27.5662 22.8875 27.3018 23.2097 27.3018C23.532 27.3018 23.7964 27.5662 23.7964 27.8884V29.6733H23.7882Z"
        fill={fill}
      />
      <path
        d="M27.835 29.6733H26.6699V27.8884C26.6699 27.5662 26.9344 27.3018 27.2566 27.3018C27.5789 27.3018 27.8433 27.5662 27.8433 27.8884V29.6733H27.835Z"
        fill={fill}
      />
      <path
        d="M31.8194 29.6733H30.6543V27.8884C30.6543 27.5662 30.9187 27.3018 31.241 27.3018C31.5633 27.3018 31.8277 27.5662 31.8277 27.8884V29.6733H31.8194Z"
        fill={fill}
      />
      <path
        d="M35.8272 29.6733H34.6621V27.8884C34.6621 27.5662 34.9265 27.3018 35.2488 27.3018C35.5711 27.3018 35.8355 27.5662 35.8355 27.8884V29.6733H35.8272Z"
        fill={fill}
      />
      <path
        d="M39.8604 29.6733H38.6953V27.8884C38.6953 27.5662 38.9597 27.3018 39.282 27.3018C39.6043 27.3018 39.8687 27.5662 39.8687 27.8884V29.6733H39.8604Z"
        fill={fill}
      />
      <path
        d="M39.4949 41.6468C39.1726 41.6468 38.9082 41.3824 38.9082 41.0601V39.614C38.9082 39.2918 39.1726 39.0273 39.4949 39.0273C39.8172 39.0273 40.0816 39.2918 40.0816 39.614V41.0601C40.0733 41.3906 39.8172 41.6468 39.4949 41.6468Z"
        fill={fill}
      />
      <path
        d="M39.4949 46.1995C39.1726 46.1995 38.9082 45.9351 38.9082 45.6128V44.1668C38.9082 43.8445 39.1726 43.5801 39.4949 43.5801C39.8172 43.5801 40.0816 43.8445 40.0816 44.1668V45.6128C40.0733 45.9434 39.8172 46.1995 39.4949 46.1995Z"
        fill={fill}
      />
      <path
        d="M39.4949 50.7523C39.1726 50.7523 38.9082 50.4878 38.9082 50.1656V48.7195C38.9082 48.3972 39.1726 48.1328 39.4949 48.1328C39.8172 48.1328 40.0816 48.3972 40.0816 48.7195V50.1656C40.0733 50.4878 39.8172 50.7523 39.4949 50.7523Z"
        fill={fill}
      />
      <path
        d="M18.9539 46.2249C18.6316 46.2249 18.3672 45.9605 18.3672 45.6382V44.1922C18.3672 43.8699 18.6316 43.6055 18.9539 43.6055C19.2762 43.6055 19.5406 43.8699 19.5406 44.1922V45.6382C19.5323 45.9605 19.2762 46.2249 18.9539 46.2249Z"
        fill={fill}
      />
      <path
        d="M18.8777 50.7943C18.5554 50.7943 18.291 50.5298 18.291 50.2076V48.7615C18.291 48.4392 18.5554 48.1748 18.8777 48.1748C19.2 48.1748 19.4644 48.4392 19.4644 48.7615V50.2076C19.4644 50.5381 19.2 50.7943 18.8777 50.7943Z"
        fill={fill}
      />
    </svg>
  );
}
export function DelhiSvg({ fill = "#4E4B66" }: FillProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="56"
      viewBox="0 0 50 56"
      fill="none"
      className="max-w-full"
    >
      <path
        d="M49.9071 55.0664H0.759766V55.9999H49.9071V55.0664Z"
        fill={fill}
      />
      <path
        d="M47.3716 19.5838H3.37305V16.7832H47.3716V19.5838ZM4.30659 18.6503H46.438V17.7167H4.30659V18.6503Z"
        fill={fill}
      />
      <path
        d="M45.9927 17.0933H45.0592V14.1562H5.77143V17.0933H4.83789V13.2227H45.9927V17.0933Z"
        fill={fill}
      />
      <path
        d="M44.7804 13.6893H43.8468V10.9892H6.98628V13.6893H6.05273V10.0557H44.7804V13.6893Z"
        fill={fill}
      />
      <path
        d="M41.3757 10.5222H40.4421V3.52777H33.4693V2.45061C32.4352 2.54396 31.2791 2.51523 30.1516 2.35007C29.5556 2.2639 28.0978 2.00538 27.4946 1.49552C26.9417 1.02875 24.931 0.526073 23.8323 1.48834C22.6402 2.5296 19.0712 2.45778 17.6924 2.39315V3.52777H10.3964V10.5222H9.46289V2.59423H16.7589V1.39499L17.2616 1.4309C19.1215 1.57452 22.3817 1.51707 23.2147 0.784597C24.7083 -0.522361 27.2217 0.0377567 28.0978 0.777409C28.622 1.22264 31.4155 1.81149 33.8642 1.45243L34.3956 1.37344V2.57987H41.3685V10.5222H41.3757Z"
        fill={fill}
      />
      <path
        d="M30.9562 9.02818H20.0625V5.02832H30.9562V9.02818ZM20.637 8.4537H30.3817V5.60281H20.637V8.4537Z"
        fill={fill}
      />
      <path
        d="M17.9426 8.59798C17.0593 8.59798 16.334 7.87987 16.334 6.98942C16.334 6.09896 17.0521 5.38086 17.9426 5.38086C18.833 5.38086 19.5511 6.09896 19.5511 6.98942C19.5511 7.87987 18.833 8.59798 17.9426 8.59798ZM17.9426 5.81172C17.2963 5.81172 16.7648 6.34312 16.7648 6.98942C16.7648 7.63572 17.2963 8.16712 17.9426 8.16712C18.5889 8.16712 19.1203 7.63572 19.1203 6.98942C19.1203 6.34312 18.596 5.81172 17.9426 5.81172Z"
        fill={fill}
      />
      <path
        d="M33.0969 8.59798C32.2136 8.59798 31.4883 7.87987 31.4883 6.98942C31.4883 6.09896 32.2064 5.38086 33.0969 5.38086C33.9873 5.38086 34.7054 6.09896 34.7054 6.98942C34.7054 7.87987 33.9873 8.59798 33.0969 8.59798ZM33.0969 5.81172C32.4506 5.81172 31.9191 6.34312 31.9191 6.98942C31.9191 7.63572 32.4506 8.16712 33.0969 8.16712C33.7432 8.16712 34.2745 7.63572 34.2745 6.98942C34.2745 6.34312 33.7503 5.81172 33.0969 5.81172Z"
        fill={fill}
      />
      <path d="M5.77143 19.1172H4.83789V55.5325H5.77143V19.1172Z" fill={fill} />
      <path d="M45.9921 19.1172H45.0586V55.5325H45.9921V19.1172Z" fill={fill} />
      <path
        d="M30.9123 55.5332H29.9788V31.1319C29.9501 30.9595 29.7777 29.9039 29.1673 28.877C28.3128 27.4336 27.0202 26.7011 25.3326 26.7011C23.681 26.7011 22.4171 27.4336 21.5913 28.877C20.9952 29.9111 20.8373 30.9667 20.8085 31.1319V55.5332H19.875V31.0744V31.0457C19.8822 30.9954 20.0258 29.7244 20.7583 28.4389C21.7493 26.6939 23.3291 25.7676 25.3254 25.7676C25.3326 25.7676 25.3326 25.7676 25.3398 25.7676C27.3577 25.7676 28.9663 26.6868 29.9788 28.4318C30.7328 29.7172 30.8908 30.9954 30.898 31.0457V31.1031V55.5332H30.9123Z"
        fill={fill}
      />
      <path d="M20.637 19.1172H20.0625V30.7936H20.637V19.1172Z" fill={fill} />
      <path d="M30.7327 19.1172H30.1582V30.7936H30.7327V19.1172Z" fill={fill} />
      <path
        d="M16.2078 55.533H15.4896V30.3705H9.51498V55.533H8.79688V29.6523H16.2078V55.533Z"
        fill={fill}
      />
      <path d="M20.3491 31.9863H5.30469V32.4172H20.3491V31.9863Z" fill={fill} />
      <path d="M20.3491 33.6885H5.30469V34.1193H20.3491V33.6885Z" fill={fill} />
      <path d="M20.3491 39.2256H5.30469V39.6565H20.3491V39.2256Z" fill={fill} />
      <path d="M20.3491 40.9268H5.30469V41.3576H20.3491V40.9268Z" fill={fill} />
      <path d="M45.5347 31.9863H30.4473V32.4172H45.5347V31.9863Z" fill={fill} />
      <path d="M45.5347 33.6885H30.4473V34.1193H45.5347V33.6885Z" fill={fill} />
      <path d="M45.5347 39.2256H30.4473V39.6565H45.5347V39.2256Z" fill={fill} />
      <path d="M45.5347 40.9268H30.4473V41.3576H45.5347V40.9268Z" fill={fill} />
      <path
        d="M41.6357 55.533H40.9176V30.3705H34.8783V55.533H34.1602V29.6523H41.6357V55.533Z"
        fill={fill}
      />
      <path
        d="M12.5009 28.532C10.878 28.532 9.55664 27.2107 9.55664 25.5878C9.55664 23.9649 10.878 22.6436 12.5009 22.6436C14.1238 22.6436 15.4451 23.9649 15.4451 25.5878C15.4451 27.2107 14.1238 28.532 12.5009 28.532ZM12.5009 23.2252C11.1939 23.2252 10.1311 24.288 10.1311 25.595C10.1311 26.9019 11.1939 27.9648 12.5009 27.9648C13.8078 27.9648 14.8706 26.9019 14.8706 25.595C14.8706 24.288 13.8078 23.2252 12.5009 23.2252Z"
        fill={fill}
      />
      <path
        d="M37.8915 28.532C36.2686 28.532 34.9473 27.2107 34.9473 25.5878C34.9473 23.9649 36.2686 22.6436 37.8915 22.6436C39.5144 22.6436 40.8358 23.9649 40.8358 25.5878C40.8358 27.2107 39.5144 28.532 37.8915 28.532ZM37.8915 23.2252C36.5846 23.2252 35.5218 24.288 35.5218 25.595C35.5218 26.9019 36.5846 27.9648 37.8915 27.9648C39.1985 27.9648 40.2613 26.9019 40.2613 25.595C40.2613 24.288 39.1985 23.2252 37.8915 23.2252Z"
        fill={fill}
      />
      <path
        d="M9.86627 20.9276H7.3457V19.4268H7.92019V20.3531H9.29179V19.4268H9.86627V20.9276Z"
        fill={fill}
      />
      <path
        d="M14.7334 20.9276H12.2129V19.4268H12.7874V20.3531H14.159V19.4268H14.7334V20.9276Z"
        fill={fill}
      />
      <path
        d="M19.6026 20.9276H17.082V19.4268H17.6565V20.3531H19.0281V19.4268H19.6026V20.9276Z"
        fill={fill}
      />
      <path
        d="M24.4639 20.9276H21.9434V19.4268H22.5178V20.3531H23.8894V19.4268H24.4639V20.9276Z"
        fill={fill}
      />
      <path
        d="M29.335 20.9276H26.8145V19.4268H27.3889V20.3531H28.7605V19.4268H29.335V20.9276Z"
        fill={fill}
      />
      <path
        d="M34.2022 20.9276H31.6816V19.4268H32.2561V20.3531H33.6277V19.4268H34.2022V20.9276Z"
        fill={fill}
      />
      <path
        d="M39.0635 20.9276H36.543V19.4268H37.1175V20.3531H38.489V19.4268H39.0635V20.9276Z"
        fill={fill}
      />
      <path
        d="M43.9327 20.9276H41.4121V19.4268H41.9866V20.3531H43.3582V19.4268H43.9327V20.9276Z"
        fill={fill}
      />
      <path d="M12.0852 10.5215H11.6543V13.6812H12.0852V10.5215Z" fill={fill} />
      <path d="M39.259 10.5215H38.8281V13.4801H39.259V10.5215Z" fill={fill} />
      <path d="M11.0715 13.6895H10.6406V17.0933H11.0715V13.6895Z" fill={fill} />
      <path d="M40.1906 13.6895H39.7598V17.2585H40.1906V13.6895Z" fill={fill} />
      <path d="M10.1398 17.2578H9.70898V19.1249H10.1398V17.2578Z" fill={fill} />
      <path d="M41.1242 17.2578H40.6934V19.1249H41.1242V17.2578Z" fill={fill} />
    </svg>
  );
}
export function GurgaonSvg({ fill = "#4E4B66" }: FillProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="56"
      viewBox="0 0 60 56"
      fill="none"
      className="max-w-full"
    >
      <path
        d="M59.8166 55.1865H0.144531V55.9997H59.8166V55.1865Z"
        fill={fill}
      />
      <path d="M51.2402 54.5176H8.80859V55.1431H51.2402V54.5176Z" fill={fill} />
      <path d="M52.9783 53.792H6.81836V54.4176H52.9783V53.792Z" fill={fill} />
      <path
        d="M18.329 54.1039H17.5157V45.7214H9.87768V54.1039H9.06445V44.9082H18.329V54.1039Z"
        fill={fill}
      />
      <path
        d="M39.8675 13.4753H21.1758V11.3984H21.8013V12.8497H39.2419V11.3984H39.8675V13.4753Z"
        fill={fill}
      />
      <path d="M40.2428 10.8525H20.7129V11.6658H40.2428V10.8525Z" fill={fill} />
      <path
        d="M31.2156 11.4102L30.49 2.4647L29.8519 11.2726L29.4766 11.2413L30.2898 0H30.6651L31.591 11.3789L31.2156 11.4102Z"
        fill={fill}
      />
      <path
        d="M21.7234 13.149L20.6035 54.0879L21.4164 54.1101L22.5363 13.1712L21.7234 13.149Z"
        fill={fill}
      />
      <path
        d="M39.4008 13.1526L38.5879 13.1748L39.7067 54.1137L40.5196 54.0915L39.4008 13.1526Z"
        fill={fill}
      />
      <path
        d="M23.7467 17.2537L23.1211 13.1813V11.3984H23.4964V13.15L24.1157 17.1974L23.7467 17.2537Z"
        fill={fill}
      />
      <path
        d="M37.2953 17.2526L36.9199 17.1963L37.5392 13.149V11.2598H37.9146V13.1802L37.2953 17.2526Z"
        fill={fill}
      />
      <path
        d="M30.5343 54.4615L24.9668 13.1997L25.4672 13.1309L30.5405 50.7645L35.7452 13.1309L36.2394 13.1997L30.5343 54.4615Z"
        fill={fill}
      />
      <path
        d="M26.3865 19.8738L26.2051 19.4046L30.5965 17.7031L35.2194 19.3108L35.0567 19.78L30.609 18.2348L26.3865 19.8738Z"
        fill={fill}
      />
      <path
        d="M26.7478 23.4835L26.5664 23.0206L30.595 21.4316L34.7425 22.9142L34.5736 23.3834L30.6075 21.9696L26.7478 23.4835Z"
        fill={fill}
      />
      <path
        d="M34.061 27.143L30.5204 25.6604L27.1549 27.0554L26.9609 26.5925L30.5204 25.1162L34.255 26.6801L34.061 27.143Z"
        fill={fill}
      />
      <path
        d="M33.5936 30.6773L30.5284 29.47L27.8447 30.6711L27.6445 30.2144L30.5158 28.9258L33.7813 30.2082L33.5936 30.6773Z"
        fill={fill}
      />
      <path
        d="M33.1114 34.3244L30.5279 33.3235L28.3885 34.3182L28.1758 33.8615L30.5091 32.7793L33.2928 33.8553L33.1114 34.3244Z"
        fill={fill}
      />
      <path
        d="M32.6169 37.9203L30.6088 36.9757L28.8135 37.914L28.582 37.4699L30.5963 36.4189L32.8296 37.4636L32.6169 37.9203Z"
        fill={fill}
      />
      <path d="M23.2292 31.9531H21.6152V32.3285H23.2292V31.9531Z" fill={fill} />
      <path d="M23.9373 34.8125H21.5352V35.1878H23.9373V34.8125Z" fill={fill} />
      <path d="M24.3495 37.5088H21.4531V37.8841H24.3495V37.5088Z" fill={fill} />
      <path d="M25.219 40.4863H21.4531V40.8617H25.219V40.4863Z" fill={fill} />
      <path d="M25.5941 43.3086H21.3027V43.6839H25.5941V43.3086Z" fill={fill} />
      <path d="M26.2134 46.041H21.3027V46.4164H26.2134V46.041Z" fill={fill} />
      <path d="M26.7513 48.9443H21.3027V49.3197H26.7513V48.9443Z" fill={fill} />
      <path d="M27.3332 51.7588H17.2617V52.1341H27.3332V51.7588Z" fill={fill} />
      <path d="M39.4811 31.9531H37.8672V32.3285H39.4811V31.9531Z" fill={fill} />
      <path d="M39.5623 34.8125H37.1602V35.1878H39.5623V34.8125Z" fill={fill} />
      <path d="M39.6444 37.5088H36.748V37.8841H39.6444V37.5088Z" fill={fill} />
      <path d="M39.6448 40.4863H35.8789V40.8617H39.6448V40.4863Z" fill={fill} />
      <path d="M39.7933 43.3086H35.502V43.6839H39.7933V43.3086Z" fill={fill} />
      <path d="M39.7934 46.041H34.8828V46.4164H39.7934V46.041Z" fill={fill} />
      <path d="M39.7924 48.9443H34.3438V49.3197H39.7924V48.9443Z" fill={fill} />
      <path d="M40.0318 51.7588H33.7637V52.1341H40.0318V51.7588Z" fill={fill} />
      <path
        d="M43.8127 47.5111H42.9995V37.6023H40.1094V36.7891H43.8127V47.5111Z"
        fill={fill}
      />
      <path d="M44.2768 40.6992H40.0293V41.0746H44.2768V40.6992Z" fill={fill} />
      <path
        d="M18.3289 54.6796H17.5156V35.7939H21.3003V36.6072H18.3289V54.6796Z"
        fill={fill}
      />
      <path d="M21.401 39.5791H17.2598V39.9544H21.401V39.5791Z" fill={fill} />
      <path d="M21.401 42.1699H17.2598V42.5453H21.401V42.1699Z" fill={fill} />
      <path d="M21.2633 44.7158H17.2598V45.0912H21.2633V44.7158Z" fill={fill} />
      <path d="M21.2008 46.998H17.2598V47.3734H21.2008V46.998Z" fill={fill} />
      <path d="M21.132 49.5195H17.2598V49.8949H21.132V49.5195Z" fill={fill} />
      <path d="M17.055 50.0205H8.35352V50.3958H17.055V50.0205Z" fill={fill} />
      <path
        d="M52.1026 54.1045H51.2893V47.9177H42.1687V54.1045H41.3555V47.1045H52.1026V54.1045Z"
        fill={fill}
      />
      <path d="M45.2542 47.5107H44.8789V54.0979H45.2542V47.5107Z" fill={fill} />
      <path d="M48.6078 47.5107H48.2324V54.0979H48.6078V47.5107Z" fill={fill} />
      <path
        d="M15.6644 45.3149H15.1639V42.9565H11.8735V44.902H11.373V42.4561H15.6644V45.3149Z"
        fill={fill}
      />
    </svg>
  );
}
export function GhaziabadSvg({ fill = "#4E4B66" }: FillProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="94"
      height="56"
      viewBox="0 0 94 56"
      fill="none"
      className="max-w-full"
    >
      <path d="M93.4356 54.8418H0.5625V55.9999H93.4356V54.8418Z" fill={fill} />
      <path
        d="M4.89282 55.4217H4.00195V41.6133H21.4986V54.9674H20.6077V42.5041H4.89282V55.4217Z"
        fill={fill}
      />
      <path
        d="M72.7307 55.4217H71.8398V41.6133H89.3365V54.9674H88.4456V42.5041H72.7307V55.4217Z"
        fill={fill}
      />
      <path d="M72.2874 48.9541H21.4277V49.6668H72.2874V48.9541Z" fill={fill} />
      <path d="M25.3281 27.7959H24.1699V55.4128H25.3281V27.7959Z" fill={fill} />
      <path d="M36.4218 27.7959H35.2637V55.4128H36.4218V27.7959Z" fill={fill} />
      <path d="M42.0058 20.6328H40.8477V55.4212H42.0058V20.6328Z" fill={fill} />
      <path d="M53.0175 20.6328H51.8594V55.4212H53.0175V20.6328Z" fill={fill} />
      <path d="M58.1464 27.7959H56.9883V55.4128H58.1464V27.7959Z" fill={fill} />
      <path d="M69.0955 27.7959H68.3828V55.4128H69.0955V27.7959Z" fill={fill} />
      <path d="M36.7477 33.2559H23.4648V33.9686H36.7477V33.2559Z" fill={fill} />
      <path d="M36.7477 27.4385H23.4648V28.1512H36.7477V27.4385Z" fill={fill} />
      <path d="M53.6501 26.0137H40.1445V26.7264H53.6501V26.0137Z" fill={fill} />
      <path d="M53.6501 20.5781H40.1445V21.2908H53.6501V20.5781Z" fill={fill} />
      <path d="M69.6509 33.2559H56.5195V33.9686H69.6509V33.2559Z" fill={fill} />
      <path d="M69.6509 27.4385H56.5195V28.1512H69.6509V27.4385Z" fill={fill} />
      <path d="M26.9861 49.3096H26.2734V55.1359H26.9861V49.3096Z" fill={fill} />
      <path d="M34.3807 49.3096H33.668V55.1359H34.3807V49.3096Z" fill={fill} />
      <path
        d="M31.5471 55.1362H30.8344V51.154C30.7453 50.5661 30.3266 50.5482 30.3088 50.5482C30.1929 50.5482 30.0682 50.575 29.9524 50.8155C29.8722 50.9848 29.8544 51.1629 29.8455 51.2075V55.1362H29.1328V51.1719C29.1595 50.6997 29.4535 49.8088 30.3177 49.8355C31.1551 49.8534 31.4847 50.6641 31.5382 51.0828V51.1273V55.1362H31.5471Z"
        fill={fill}
      />
      <path d="M59.8143 49.4521H59.1016V55.2784H59.8143V49.4521Z" fill={fill} />
      <path d="M67.2088 49.4521H66.4961V55.2784H67.2088V49.4521Z" fill={fill} />
      <path
        d="M64.3771 55.2782H63.6644V51.296C63.5754 50.7081 63.1567 50.6902 63.1388 50.6902C63.0319 50.6902 62.8983 50.717 62.7825 50.9575C62.7023 51.1268 62.6845 51.3049 62.6756 51.3495V55.2782H61.9629V51.3138C61.9896 50.8506 62.2747 49.9775 63.121 49.9775C63.1299 49.9775 63.1388 49.9775 63.1477 49.9775C63.9852 49.9954 64.3148 50.806 64.3682 51.2248V51.2693V55.2782H64.3771Z"
        fill={fill}
      />
      <path
        d="M33.0278 47.919H32.3151V43.8388C32.3151 43.821 32.4843 41.8076 31.5846 40.7742C31.2639 40.4 30.854 40.213 30.3195 40.1773C29.7939 40.1506 29.3752 40.2931 29.0456 40.6228C28.1191 41.5314 28.1904 43.5092 28.1904 43.5359V43.5537V47.9279H27.4777V43.5626C27.4688 43.3845 27.4153 41.2375 28.5378 40.1239C29.0189 39.6517 29.6246 39.4379 30.3552 39.4735C31.0768 39.5181 31.6737 39.7943 32.1191 40.3199C33.1703 41.5404 33.0367 43.6695 33.0189 43.8923V47.919H33.0278Z"
        fill={fill}
      />
      <path
        d="M31.5477 47.9191H30.8351V45.9325C30.8172 45.8167 30.6925 45.202 30.256 45.202C29.8195 45.202 29.6769 45.8077 29.6502 45.9325V47.9102H28.9375V45.8701V45.8434C29.0088 45.3712 29.3829 44.4893 30.256 44.4893C31.1379 44.4893 31.4854 45.3801 31.5388 45.8612V45.9057V47.9191H31.5477Z"
        fill={fill}
      />
      <path d="M32.6683 43.1885H27.8398V43.9012H32.6683V43.1885Z" fill={fill} />
      <path
        d="M49.9262 41.3369H49.2135V37.2568C49.2135 37.2389 49.3828 35.2256 48.483 34.1922C48.1623 33.818 47.7525 33.6309 47.218 33.5953C46.6924 33.5686 46.2736 33.7111 45.944 34.0407C45.0175 34.9494 45.0888 36.9271 45.0888 36.9539V36.9717V41.3459H44.3761V36.9806C44.3672 36.8024 44.3137 34.6554 45.4362 33.5418C45.9173 33.0697 46.5231 32.8559 47.2536 32.8915C47.9752 32.9361 48.5721 33.2122 49.0175 33.7378C50.0687 34.9583 49.9351 37.0875 49.9173 37.3102V41.3369H49.9262Z"
        fill={fill}
      />
      <path
        d="M48.4462 41.337H47.7335V39.3503C47.7157 39.2345 47.5909 38.6198 47.1544 38.6198C46.7179 38.6198 46.5754 39.2256 46.5486 39.3503V41.328H45.8359V39.2969V39.2701C45.9072 38.798 46.2814 37.916 47.1544 37.916C48.0364 37.916 48.3838 38.8069 48.4373 39.288V39.3325V41.337H48.4462Z"
        fill={fill}
      />
      <path d="M49.5707 36.5977H44.7422V37.3104H49.5707V36.5977Z" fill={fill} />
      <path
        d="M65.9184 47.9191H65.2057V43.8389C65.2057 43.8211 65.375 41.8077 64.4752 40.7743C64.1545 40.4002 63.7447 40.2131 63.2102 40.1774C62.6845 40.1507 62.2658 40.2933 61.9362 40.6229C61.0097 41.5316 61.081 43.5093 61.081 43.536V43.5538V47.928H60.3683V43.5538C60.3594 43.3757 60.3059 41.2287 61.4284 40.1151C61.9095 39.6429 62.5153 39.4291 63.2458 39.4648C63.9674 39.5093 64.5643 39.7855 65.0097 40.3111C66.0609 41.5316 65.9273 43.6607 65.9095 43.8835V47.9191H65.9184Z"
        fill={fill}
      />
      <path
        d="M64.4481 47.9191H63.7354V45.9325C63.7176 45.8167 63.5929 45.202 63.1564 45.202C62.7199 45.202 62.5773 45.8077 62.5506 45.9325V47.9102H61.8379V45.8701V45.8434C61.9092 45.3712 62.2833 44.4893 63.1564 44.4893C64.0383 44.4893 64.3858 45.3801 64.4392 45.8612V45.9057V47.9191H64.4481Z"
        fill={fill}
      />
      <path d="M65.5609 43.1885H60.7324V43.9012H65.5609V43.1885Z" fill={fill} />
      <path
        d="M33.0454 32.1785H27.4863V29.3545H33.0454V32.1785ZM28.199 31.4659H32.3327V30.0672H28.199V31.4659Z"
        fill={fill}
      />
      <path
        d="M66.0512 32.116H60.4922V29.292H66.0512V32.116ZM61.2049 31.4033H65.3385V30.0047H61.2049V31.4033Z"
        fill={fill}
      />
      <path
        d="M49.7016 25.0604H44.1426V22.2363H49.7016V25.0604ZM44.8553 24.3477H48.9889V22.949H44.8553V24.3477Z"
        fill={fill}
      />
      <path
        d="M31.5479 22.165H28.6348V16.4189H31.5479V22.165ZM29.3564 21.4524H30.8441V17.1316H29.3564V21.4524Z"
        fill={fill}
      />
      <path
        d="M24.1713 27.6268C24.1446 27.2615 23.5299 18.6201 26.5143 13.7471L27.5032 14.3529C26.3629 16.2237 25.6234 19.0834 25.3651 22.629C25.1691 25.3462 25.3295 27.5199 25.3295 27.5377L24.1713 27.6268Z"
        fill={fill}
      />
      <path
        d="M36.5174 27.6268L35.3593 27.5377C35.3593 27.5199 35.5196 25.3462 35.3236 22.629C35.0653 19.0834 34.3259 16.2237 33.1855 14.3529L34.1744 13.7471C35.4305 15.805 36.2056 18.7716 36.4818 22.5578C36.6778 25.3551 36.5174 27.5288 36.5174 27.6268Z"
        fill={fill}
      />
      <path d="M34.6831 13.6924H25.8457V14.4051H34.6831V13.6924Z" fill={fill} />
      <path
        d="M28.4903 13.6836V12.3384C28.4903 12.3384 28.4547 11.3317 30.0493 10.4765C30.0493 10.4765 30.3344 10.3517 30.4057 10.1201C30.4057 10.1201 30.468 10.4052 30.6729 10.4943C30.8778 10.5834 32.1607 11.109 32.2676 12.1157V13.737"
        fill={fill}
      />
      <path
        d="M30.3792 9.05079C30.1654 9.05079 29.9961 8.88152 29.9961 8.66772V7.68776C29.9961 7.47395 30.1654 7.30469 30.3792 7.30469C30.593 7.30469 30.7622 7.47395 30.7622 7.68776V8.66772C30.7622 8.88152 30.593 9.05079 30.3792 9.05079Z"
        fill={fill}
      />
      <path
        d="M30.0967 26.4414C29.3751 26.4414 28.7871 25.8534 28.7871 25.1318C28.7871 24.4102 29.3751 23.8223 30.0967 23.8223C30.8183 23.8223 31.4063 24.4102 31.4063 25.1318C31.4063 25.8534 30.8183 26.4414 30.0967 26.4414ZM30.0967 24.5261C29.7671 24.5261 29.4998 24.7933 29.4998 25.1229C29.4998 25.4526 29.7671 25.7198 30.0967 25.7198C30.4263 25.7198 30.6936 25.4526 30.6936 25.1229C30.6936 24.7933 30.4263 24.5261 30.0967 24.5261Z"
        fill={fill}
      />
      <path
        d="M64.7237 22.165H61.8105V16.4189H64.7237V22.165ZM62.5322 21.4524H64.0199V17.1316H62.5322V21.4524Z"
        fill={fill}
      />
      <path
        d="M57.062 27.6268C57.0352 27.2615 56.4205 18.6201 59.4049 13.7471L60.3938 14.3529C59.2535 16.2237 58.5141 19.0834 58.2557 22.629C58.0597 25.3462 58.2201 27.5199 58.2201 27.5377L57.062 27.6268Z"
        fill={fill}
      />
      <path
        d="M69.3983 27.6268L68.2401 27.5377C68.2401 27.5199 68.4005 25.3462 68.2045 22.629C67.9461 19.0834 67.2067 16.2237 66.0664 14.3529L67.0553 13.7471C68.3114 15.805 69.0865 18.7716 69.3626 22.5578C69.5675 25.3551 69.4072 27.5288 69.3983 27.6268Z"
        fill={fill}
      />
      <path d="M67.5738 13.6924H58.7363V14.4051H67.5738V13.6924Z" fill={fill} />
      <path
        d="M61.3829 13.6836V12.3384C61.3829 12.3384 61.3472 11.3317 62.9419 10.4765C62.9419 10.4765 63.227 10.3517 63.2982 10.1201C63.2982 10.1201 63.3606 10.4052 63.5655 10.4943C63.7704 10.5834 65.0533 11.109 65.1602 12.1157V13.737"
        fill={fill}
      />
      <path
        d="M63.2698 9.05079C63.056 9.05079 62.8867 8.88152 62.8867 8.66772V7.68776C62.8867 7.47395 63.056 7.30469 63.2698 7.30469C63.4836 7.30469 63.6529 7.47395 63.6529 7.68776V8.66772C63.6529 8.88152 63.4836 9.05079 63.2698 9.05079Z"
        fill={fill}
      />
      <path
        d="M63.2725 26.4414C62.5509 26.4414 61.9629 25.8534 61.9629 25.1318C61.9629 24.4102 62.5509 23.8223 63.2725 23.8223C63.9941 23.8223 64.582 24.4102 64.582 25.1318C64.582 25.8534 63.9941 26.4414 63.2725 26.4414ZM63.2725 24.5261C62.9428 24.5261 62.6756 24.7933 62.6756 25.1229C62.6756 25.4526 62.9428 25.7198 63.2725 25.7198C63.6021 25.7198 63.8694 25.4526 63.8694 25.1229C63.8694 24.7933 63.6021 24.5261 63.2725 24.5261Z"
        fill={fill}
      />
      <path
        d="M48.376 15.2518H45.4629V9.37207H48.376V15.2518ZM46.1845 14.5391H47.6722V10.0848H46.1845V14.5391Z"
        fill={fill}
      />
      <path
        d="M40.8298 20.8542C40.803 20.4801 40.1884 11.6159 43.1639 6.61816L44.1616 7.21505C43.0124 9.13932 42.273 12.0792 42.0235 15.7228C41.8275 18.5113 41.9879 20.7473 41.9879 20.7652L40.8298 20.8542Z"
        fill={fill}
      />
      <path
        d="M53.1756 20.8542L52.0175 20.7652C52.0175 20.7473 52.1778 18.5113 51.9818 15.7228C51.7235 12.0792 50.9841 9.13041 49.8438 7.21505L50.8415 6.61816C52.0976 8.72952 52.8727 11.7674 53.14 15.6605C53.3449 18.5291 53.1845 20.7652 53.1756 20.8542Z"
        fill={fill}
      />
      <path d="M51.3433 6.56543H42.5059V7.27812H51.3433V6.56543Z" fill={fill} />
      <path
        d="M45.1583 6.54819V5.16735C45.1583 5.16735 45.1226 4.13394 46.7173 3.25198C46.7173 3.25198 47.0024 3.12725 47.0736 2.88672C47.0736 2.88672 47.136 3.1807 47.3409 3.26979C47.5458 3.35888 48.8286 3.90231 48.9355 4.93572V6.60164"
        fill={fill}
      />
      <path
        d="M47.0393 1.79064C46.8255 1.79064 46.6562 1.62138 46.6562 1.40757V0.383072C46.6562 0.169264 46.8255 0 47.0393 0C47.2531 0 47.4224 0.169264 47.4224 0.383072V1.40757C47.4313 1.61247 47.2531 1.79064 47.0393 1.79064Z"
        fill={fill}
      />
      <path
        d="M46.9229 19.6345C46.2013 19.6345 45.6133 19.0376 45.6133 18.2982C45.6133 17.5588 46.2013 16.9619 46.9229 16.9619C47.6445 16.9619 48.2324 17.5588 48.2324 18.2982C48.2324 19.0376 47.6445 19.6345 46.9229 19.6345ZM46.9229 17.6746C46.5932 17.6746 46.326 17.9508 46.326 18.2982C46.326 18.6457 46.5932 18.9218 46.9229 18.9218C47.2525 18.9218 47.5197 18.6457 47.5197 18.2982C47.5197 17.9508 47.2525 17.6746 46.9229 17.6746Z"
        fill={fill}
      />
      <path d="M43.6893 42.0576H42.9766V55.1267H43.6893V42.0576Z" fill={fill} />
      <path d="M51.0389 42.0576H50.3262V55.4206H51.0389V42.0576Z" fill={fill} />
      <path
        d="M48.2146 55.4217H47.5019V43.9117C47.4662 43.8048 47.3504 43.493 47.003 43.493C46.6555 43.493 46.5664 44.0186 46.5486 44.1166V55.199H45.8359V44.072V44.0542C45.8805 43.6088 46.1834 42.7803 46.9941 42.7803C47.7869 42.7803 48.1255 43.4395 48.2056 43.7959L48.2146 43.8315V55.4217Z"
        fill={fill}
      />
      <path
        d="M9.56044 55.1545H9.02592V47.6534C8.99029 45.9073 10.7631 44.571 11.5293 44.0721C11.8143 43.885 12.0816 43.4574 12.2598 43.1723C12.4558 42.8694 12.5449 42.718 12.7498 42.7358C12.8567 42.7447 12.9547 42.816 12.9903 42.9229C13.0616 43.101 13.4803 44.0275 14.8255 44.8382C16.3756 45.7736 16.3934 47.4485 16.3934 47.5108V54.8516H15.8589V47.5108C15.8589 47.4574 15.8322 46.0676 14.5493 45.2926C13.4981 44.6512 12.9458 43.9474 12.6874 43.493C12.4825 43.8137 12.1885 44.277 11.8143 44.5086C9.68517 45.8805 9.55153 47.2436 9.55153 47.6356L9.56044 55.1545Z"
        fill={fill}
      />
      <path
        d="M19.6098 46.3432H17.5519V44.1072C17.5162 43.3945 18.3715 42.8778 18.4695 42.8154L18.5942 42.7441L18.71 42.8243C19.6276 43.4479 19.6276 44.0448 19.6098 44.1784V46.3432ZM17.9973 45.8978H19.1643V44.1339C19.1643 44.1339 19.1911 43.7508 18.5764 43.2787C18.3447 43.4479 17.9795 43.7686 17.9973 44.0893V44.0983V45.8978Z"
        fill={fill}
      />
      <path
        d="M20.9104 41.5232C20.848 41.2292 20.6966 40.7571 20.5273 40.4898C20.358 40.6947 20.1175 41.0689 20.162 41.452L19.7166 41.4965C19.6275 40.6947 20.3491 40.0177 20.3758 39.9909L20.5184 39.8662L20.6698 39.982C21.0885 40.3116 21.3202 41.3183 21.3469 41.4341L20.9104 41.5232Z"
        fill={fill}
      />
      <path
        d="M19.5998 41.5242C19.5375 41.2302 19.386 40.7581 19.2167 40.4908C19.0475 40.6957 18.8069 41.0699 18.8515 41.4529L18.4061 41.4975C18.317 40.6957 19.0386 40.0186 19.0653 39.9919L19.2078 39.8672L19.3593 39.983C19.778 40.3126 20.0096 41.3193 20.0363 41.4351L19.5998 41.5242Z"
        fill={fill}
      />
      <path
        d="M18.299 41.5242C18.2367 41.2302 18.0852 40.7581 17.916 40.4908C17.7467 40.6957 17.5062 41.0699 17.5507 41.4529L17.1053 41.4975C17.0162 40.6957 17.7378 40.0186 17.7645 39.9919L17.9071 39.8672L18.0585 39.983C18.4772 40.3126 18.7088 41.3193 18.7356 41.4351L18.299 41.5242Z"
        fill={fill}
      />
      <path
        d="M16.9982 41.5242C16.9359 41.2302 16.7844 40.7581 16.6152 40.4908C16.4459 40.6957 16.2054 41.0699 16.2499 41.4529L15.8045 41.4975C15.7154 40.6957 16.437 40.0186 16.4637 39.9919L16.6063 39.8672L16.7577 39.983C17.1764 40.3126 17.408 41.3193 17.4348 41.4351L16.9982 41.5242Z"
        fill={fill}
      />
      <path
        d="M15.6975 41.5242C15.6351 41.2302 15.4837 40.7581 15.3144 40.4908C15.1451 40.6957 14.9046 41.0699 14.9491 41.4529L14.5037 41.4975C14.4146 40.6957 15.1362 40.0186 15.163 39.9919L15.3055 39.8672L15.4569 39.983C15.8757 40.3126 16.1073 41.3193 16.134 41.4351L15.6975 41.5242Z"
        fill={fill}
      />
      <path
        d="M14.3889 41.5242C14.3265 41.2302 14.1751 40.7581 14.0058 40.4908C13.8365 40.6957 13.596 41.0699 13.6405 41.4529L13.1951 41.4975C13.106 40.6957 13.8276 40.0186 13.8544 39.9919L13.9969 39.8672L14.1483 39.983C14.567 40.3126 14.7987 41.3193 14.8254 41.4351L14.3889 41.5242Z"
        fill={fill}
      />
      <path
        d="M13.0901 41.5242C13.0277 41.2302 12.8762 40.7581 12.707 40.4908C12.5377 40.6957 12.2972 41.0699 12.3417 41.4529L11.8963 41.4975C11.8072 40.6957 12.5288 40.0186 12.5555 39.9919L12.6981 39.8672L12.8495 39.983C13.2682 40.3126 13.4998 41.3193 13.5266 41.4351L13.0901 41.5242Z"
        fill={fill}
      />
      <path
        d="M11.7893 41.5242C11.7269 41.2302 11.5755 40.7581 11.4062 40.4908C11.2369 40.6957 10.9964 41.0699 11.0409 41.4529L10.5955 41.4975C10.5064 40.6957 11.228 40.0186 11.2547 39.9919L11.3973 39.8672L11.5487 39.983C11.9674 40.3126 12.1991 41.3193 12.2258 41.4351L11.7893 41.5242Z"
        fill={fill}
      />
      <path
        d="M10.4787 41.5242C10.4164 41.2302 10.2649 40.7581 10.0956 40.4908C9.92638 40.6957 9.68585 41.0699 9.73039 41.4529L9.28496 41.4975C9.19587 40.6957 9.91748 40.0186 9.9442 39.9919L10.0867 39.8672L10.2382 39.983C10.6569 40.3126 10.8885 41.3193 10.9153 41.4351L10.4787 41.5242Z"
        fill={fill}
      />
      <path
        d="M9.17794 41.5232C9.11558 41.2292 8.96413 40.7571 8.79486 40.4898C8.6256 40.6947 8.38507 41.0689 8.42961 41.452L7.98418 41.4965C7.89509 40.6947 8.61669 40.0177 8.64342 39.9909L8.78596 39.8662L8.9374 39.982C9.35611 40.3116 9.58774 41.3183 9.61447 41.4341L9.17794 41.5232Z"
        fill={fill}
      />
      <path
        d="M7.87716 41.5232C7.8148 41.2292 7.66335 40.7571 7.49408 40.4898C7.32482 40.6947 7.08429 41.0689 7.12883 41.452L6.68339 41.4965C6.59431 40.6947 7.31591 40.0177 7.34264 39.9909L7.48518 39.8662L7.63662 39.982C8.05533 40.3116 8.28695 41.3183 8.31368 41.4341L7.87716 41.5232Z"
        fill={fill}
      />
      <path
        d="M6.57637 41.5232C6.51401 41.2292 6.36257 40.7571 6.1933 40.4898C6.02404 40.6947 5.7835 41.0689 5.82805 41.452L5.38261 41.4965C5.29353 40.6947 6.01512 40.0177 6.04185 39.9909L6.1844 39.8662L6.33584 39.982C6.75455 40.3116 6.98617 41.3183 7.0129 41.4341L6.57637 41.5232Z"
        fill={fill}
      />
      <path
        d="M5.26583 41.5242C5.20347 41.2302 5.05202 40.7581 4.88275 40.4908C4.71349 40.6957 4.47296 41.0699 4.5175 41.4529L4.07207 41.4975C3.98298 40.6957 4.70459 40.0186 4.73131 39.9919L4.87385 39.8672L5.0253 39.983C5.44401 40.3126 5.67563 41.3193 5.70236 41.4351L5.26583 41.5242Z"
        fill={fill}
      />
      <path
        d="M16.0478 40.6949C16.0389 40.6771 14.7293 38.5301 12.7427 38.4588C10.7561 38.3875 9.40195 40.6147 9.38413 40.6325L8.84961 40.3118C8.86743 40.2851 9.2416 39.6704 9.90084 39.0557C10.7917 38.2272 11.7806 37.8085 12.7605 37.8352C13.7316 37.8708 14.7026 38.3163 15.5668 39.1448C16.2082 39.7506 16.5645 40.3474 16.5824 40.3742L16.0478 40.6949Z"
        fill={fill}
      />
      <path
        d="M13.304 37.1761H12.3864C12.235 37.1761 12.1191 37.0514 12.1191 36.9089C12.1191 36.7574 12.2439 36.6416 12.3864 36.6416H13.304C13.4554 36.6416 13.5712 36.7663 13.5712 36.9089C13.5712 37.0603 13.4465 37.1761 13.304 37.1761Z"
        fill={fill}
      />
      <path
        d="M13.1146 35.2609V36.1785C13.1146 36.3299 12.9899 36.4457 12.8473 36.4457C12.6959 36.4457 12.5801 36.321 12.5801 36.1785V35.2609C12.5801 35.1094 12.7048 34.9936 12.8473 34.9936C12.9899 34.9847 13.1146 35.1094 13.1146 35.2609Z"
        fill={fill}
      />
      <path
        d="M84.3862 55.1533H83.8516V47.6522C83.8605 47.2602 83.718 45.8972 81.5888 44.5252C81.2236 44.2847 80.9207 43.8303 80.7158 43.5096C80.4485 43.9551 79.9051 44.6678 78.8539 45.3092C77.5621 46.0931 77.5443 47.474 77.5443 47.5274V54.8682H77.0098V47.5274C77.0098 47.4562 77.0276 45.7902 78.5777 44.8548C79.9229 44.0441 80.3416 43.1176 80.4129 42.9395C80.4574 42.8326 80.5465 42.7613 80.6534 42.7524C80.8583 42.7346 80.9563 42.886 81.1434 43.1889C81.3216 43.474 81.5977 43.9016 81.8739 44.0887C82.6401 44.5876 84.4129 45.9239 84.3772 47.67L84.3862 55.1533Z"
        fill={fill}
      />
      <path
        d="M75.8602 46.3432H73.8023V44.1784C73.7934 44.0448 73.7934 43.4479 74.7021 42.8243L74.8179 42.7441L74.9426 42.8154C75.0406 42.8689 75.8959 43.3856 75.8602 44.1072V46.3432ZM74.2477 45.8978H75.4148V44.0893C75.4326 43.7686 75.0763 43.4479 74.8357 43.2787C74.2121 43.7508 74.2477 44.1339 74.2477 44.1339V44.1517V44.1606V45.8978Z"
        fill={fill}
      />
      <path
        d="M72.501 41.5233L72.0645 41.4254C72.0912 41.3095 72.3139 40.3029 72.7415 39.9732L72.893 39.8574L73.0355 39.9821C73.0622 40.0089 73.7838 40.6859 73.6947 41.4877L73.2493 41.4432C73.2938 41.0601 73.0533 40.6859 72.884 40.481C72.7148 40.7661 72.5722 41.2294 72.501 41.5233Z"
        fill={fill}
      />
      <path
        d="M73.8115 41.5233L73.375 41.4254C73.4017 41.3095 73.6244 40.3029 74.0521 39.9732L74.2035 39.8574L74.346 39.9821C74.3728 40.0089 75.0944 40.6859 75.0053 41.4877L74.5599 41.4432C74.6044 41.0601 74.3639 40.6859 74.1946 40.481C74.0164 40.7661 73.8739 41.2294 73.8115 41.5233Z"
        fill={fill}
      />
      <path
        d="M75.1123 41.5233L74.6758 41.4254C74.7025 41.3095 74.9252 40.3029 75.3528 39.9732L75.5043 39.8574L75.6468 39.9821C75.6736 40.0089 76.3952 40.6859 76.3061 41.4877L75.8606 41.4432C75.9052 41.0601 75.6647 40.6859 75.4954 40.481C75.3172 40.7661 75.1747 41.2294 75.1123 41.5233Z"
        fill={fill}
      />
      <path
        d="M76.4131 41.5233L75.9766 41.4254C76.0033 41.3095 76.226 40.3029 76.6536 39.9732L76.8051 39.8574L76.9476 39.9821C76.9743 40.0089 77.6959 40.6859 77.6069 41.4877L77.1614 41.4432C77.206 41.0601 76.9654 40.6859 76.7962 40.481C76.6269 40.7661 76.4755 41.2294 76.4131 41.5233Z"
        fill={fill}
      />
      <path
        d="M77.7217 41.5233L77.2852 41.4254C77.3119 41.3095 77.5346 40.3029 77.9622 39.9732L78.1137 39.8574L78.2562 39.9821C78.2829 40.0089 79.0045 40.6859 78.9154 41.4877L78.47 41.4432C78.5145 41.0601 78.274 40.6859 78.1048 40.481C77.9266 40.7661 77.784 41.2294 77.7217 41.5233Z"
        fill={fill}
      />
      <path
        d="M79.0225 41.5233L78.5859 41.4254C78.6127 41.3095 78.8354 40.3029 79.263 39.9732L79.4144 39.8574L79.557 39.9821C79.5837 40.0089 80.3053 40.6859 80.2162 41.4877L79.7708 41.4432C79.8153 41.0601 79.5748 40.6859 79.4055 40.481C79.2274 40.7661 79.0848 41.2294 79.0225 41.5233Z"
        fill={fill}
      />
      <path
        d="M80.3232 41.5233L79.8867 41.4254C79.9134 41.3095 80.1362 40.3029 80.5638 39.9732L80.7152 39.8574L80.8578 39.9821C80.8845 40.0089 81.6061 40.6859 81.517 41.4877L81.0716 41.4432C81.1161 41.0601 80.8756 40.6859 80.7063 40.481C80.5371 40.7661 80.3856 41.2294 80.3232 41.5233Z"
        fill={fill}
      />
      <path
        d="M81.6221 41.5233L81.1855 41.4254C81.2123 41.3095 81.435 40.3029 81.8626 39.9732L82.0141 39.8574L82.1566 39.9821C82.1833 40.0089 82.9049 40.6859 82.8158 41.4877L82.3704 41.4432C82.415 41.0601 82.1744 40.6859 82.0052 40.481C81.8359 40.7661 81.6844 41.2294 81.6221 41.5233Z"
        fill={fill}
      />
      <path
        d="M82.9326 41.5233L82.4961 41.4254C82.5228 41.3095 82.7455 40.3029 83.1732 39.9732L83.3246 39.8574L83.4671 39.9821C83.4939 40.0089 84.2155 40.6859 84.1264 41.4877L83.6809 41.4432C83.7255 41.0601 83.485 40.6859 83.3157 40.481C83.1375 40.7661 82.995 41.2294 82.9326 41.5233Z"
        fill={fill}
      />
      <path
        d="M84.2334 41.5233L83.7969 41.4254C83.8236 41.3095 84.0463 40.3029 84.4739 39.9732L84.6254 39.8574L84.7679 39.9821C84.7946 40.0089 85.5163 40.6859 85.4272 41.4877L84.9817 41.4432C85.0263 41.0601 84.7857 40.6859 84.6165 40.481C84.4383 40.7572 84.2958 41.2294 84.2334 41.5233Z"
        fill={fill}
      />
      <path
        d="M85.5342 41.5233L85.0977 41.4254C85.1244 41.3095 85.3471 40.3029 85.7747 39.9732L85.9261 39.8574L86.0687 39.9821C86.0954 40.0089 86.817 40.6859 86.7279 41.4877L86.2825 41.4432C86.327 41.0601 86.0865 40.6859 85.9173 40.481C85.748 40.7661 85.5965 41.2294 85.5342 41.5233Z"
        fill={fill}
      />
      <path
        d="M86.835 41.5233L86.3984 41.4254C86.4252 41.3095 86.6479 40.3029 87.0755 39.9732L87.2269 39.8574L87.3695 39.9821C87.3962 40.0089 88.1178 40.6859 88.0287 41.4877L87.5833 41.4432C87.6278 41.0601 87.3873 40.6859 87.218 40.481C87.0488 40.7661 86.9062 41.2294 86.835 41.5233Z"
        fill={fill}
      />
      <path
        d="M88.1455 41.5233L87.709 41.4254C87.7357 41.3095 87.9584 40.3029 88.3861 39.9732L88.5375 39.8574L88.68 39.9821C88.7068 40.0089 89.4284 40.6859 89.3393 41.4877L88.8938 41.4432C88.9384 41.0601 88.6979 40.6859 88.5286 40.481C88.3504 40.7661 88.2079 41.2294 88.1455 41.5233Z"
        fill={fill}
      />
      <path
        d="M77.3666 40.6957L76.832 40.375C76.8498 40.3483 77.2062 39.7514 77.8476 39.1456C78.7118 38.326 79.6828 37.8717 80.6538 37.836C81.6338 37.8004 82.6227 38.228 83.5135 39.0565C84.1728 39.6712 84.547 40.2859 84.5648 40.3127L84.0303 40.6334C84.0124 40.6156 82.6494 38.3884 80.6717 38.4597C78.685 38.5309 77.3844 40.669 77.3666 40.6957Z"
        fill={fill}
      />
      <path
        d="M80.1091 37.1761H81.0267C81.1781 37.1761 81.2939 37.0514 81.2939 36.9089C81.2939 36.7574 81.1692 36.6416 81.0267 36.6416H80.1091C79.9576 36.6416 79.8418 36.7663 79.8418 36.9089C79.8418 37.0603 79.9665 37.1761 80.1091 37.1761Z"
        fill={fill}
      />
      <path
        d="M80.2969 35.2609V36.1785C80.2969 36.3299 80.4216 36.4457 80.5641 36.4457C80.7156 36.4457 80.8314 36.321 80.8314 36.1785V35.2609C80.8314 35.1094 80.7067 34.9936 80.5641 34.9936C80.4216 34.9847 80.2969 35.1094 80.2969 35.2609Z"
        fill={fill}
      />
      <path
        d="M27.2701 35.6352C27.5358 35.6352 27.7512 35.3679 27.7512 35.0383C27.7512 34.7086 27.5358 34.4414 27.2701 34.4414C27.0044 34.4414 26.7891 34.7086 26.7891 35.0383C26.7891 35.3679 27.0044 35.6352 27.2701 35.6352Z"
        fill={fill}
      />
      <path
        d="M30.1022 35.6352C30.3679 35.6352 30.5832 35.3679 30.5832 35.0383C30.5832 34.7086 30.3679 34.4414 30.1022 34.4414C29.8365 34.4414 29.6211 34.7086 29.6211 35.0383C29.6211 35.3679 29.8365 35.6352 30.1022 35.6352Z"
        fill={fill}
      />
      <path
        d="M32.9381 35.6352C33.2038 35.6352 33.4192 35.3679 33.4192 35.0383C33.4192 34.7086 33.2038 34.4414 32.9381 34.4414C32.6724 34.4414 32.457 34.7086 32.457 35.0383C32.457 35.3679 32.6724 35.6352 32.9381 35.6352Z"
        fill={fill}
      />
      <path
        d="M44.1686 28.7133C44.4342 28.7133 44.6496 28.4461 44.6496 28.1164C44.6496 27.7868 44.4342 27.5195 44.1686 27.5195C43.9029 27.5195 43.6875 27.7868 43.6875 28.1164C43.6875 28.4461 43.9029 28.7133 44.1686 28.7133Z"
        fill={fill}
      />
      <path
        d="M47.0045 28.7133C47.2702 28.7133 47.4856 28.4461 47.4856 28.1164C47.4856 27.7868 47.2702 27.5195 47.0045 27.5195C46.7388 27.5195 46.5234 27.7868 46.5234 28.1164C46.5234 28.4461 46.7388 28.7133 47.0045 28.7133Z"
        fill={fill}
      />
      <path
        d="M49.8365 28.7133C50.1022 28.7133 50.3176 28.4461 50.3176 28.1164C50.3176 27.7868 50.1022 27.5195 49.8365 27.5195C49.5709 27.5195 49.3555 27.7868 49.3555 28.1164C49.3555 28.4461 49.5709 28.7133 49.8365 28.7133Z"
        fill={fill}
      />
      <path
        d="M60.3229 35.7592C60.5886 35.7592 60.8039 35.492 60.8039 35.1623C60.8039 34.8327 60.5886 34.5654 60.3229 34.5654C60.0572 34.5654 59.8418 34.8327 59.8418 35.1623C59.8418 35.492 60.0572 35.7592 60.3229 35.7592Z"
        fill={fill}
      />
      <path
        d="M63.1549 35.7592C63.4206 35.7592 63.636 35.492 63.636 35.1623C63.636 34.8327 63.4206 34.5654 63.1549 34.5654C62.8892 34.5654 62.6738 34.8327 62.6738 35.1623C62.6738 35.492 62.8892 35.7592 63.1549 35.7592Z"
        fill={fill}
      />
      <path
        d="M65.9869 35.7592C66.2526 35.7592 66.468 35.492 66.468 35.1623C66.468 34.8327 66.2526 34.5654 65.9869 34.5654C65.7212 34.5654 65.5059 34.8327 65.5059 35.1623C65.5059 35.492 65.7212 35.7592 65.9869 35.7592Z"
        fill={fill}
      />
    </svg>
  );
}
export function FaridabadSvg({ fill = "#4E4B66" }: FillProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="92"
      height="56"
      viewBox="0 0 92 56"
      fill="none"
      className="max-w-full"
    >
      <path
        d="M91.1057 54.8613H0.925781V56.0001H91.1057V54.8613Z"
        fill={fill}
      />
      <path d="M19.1016 41.748H17.9629V49.947H19.1016V41.748Z" fill={fill} />
      <path
        d="M35.745 42.3178H16.2812V39.287L17.9631 38.1658V36.3613H34.3435V39.1294H59.4923V40.2681H33.2048V37.5001H19.1018V38.7702L17.42 39.9002V41.1791H35.745V42.3178Z"
        fill={fill}
      />
      <path d="M36.0684 39.6992H34.9297V50.3333H36.0684V39.6992Z" fill={fill} />
      <path d="M44.629 39.9521H43.4902V49.9468H44.629V39.9521Z" fill={fill} />
      <path d="M50.6622 39.6992H49.5234V49.9392H50.6622V39.6992Z" fill={fill} />
      <path d="M58.7735 39.6992H57.6348V49.9392H58.7735V39.6992Z" fill={fill} />
      <path d="M75.1016 42.0723H73.9629V49.9384H75.1016V42.0723Z" fill={fill} />
      <path d="M88.6809 43.1416H74.832V44.2803H88.6809V43.1416Z" fill={fill} />
      <path d="M18.1908 42.9912H3.20312V44.13H18.1908V42.9912Z" fill={fill} />
      <path d="M33.3814 37.7441H19.0332V38.8829H33.3814V37.7441Z" fill={fill} />
      <path
        d="M59.4665 40.0931L58.6344 39.3135L59.2213 38.6828V36.6769L57.6358 35.836V34.0841H36.3675V35.7046L33.9061 37.3251L33.2754 36.3703L35.2288 35.0914V32.9453H58.7745V35.1527L60.36 35.9936V39.1383L59.4665 40.0931Z"
        fill={fill}
      />
      <path d="M57.7841 34.793H36.2793V35.9317H57.7841V34.793Z" fill={fill} />
      <path
        d="M75.9092 42.3168H58.5039V41.178H75.2347L75.4975 40.6962V39.4173L74.2624 38.629V37.1749H60.2383V36.0361H75.4011V38.007L76.6362 38.7954V40.9853L75.9092 42.3168Z"
        fill={fill}
      />
      <path d="M74.8296 37.7441H60.1836V38.8829H74.8296V37.7441Z" fill={fill} />
      <path d="M88.6796 40.4697H76.1621V41.6085H88.6796V40.4697Z" fill={fill} />
      <path
        d="M75.5122 39.234C74.8464 36.3784 77.3166 27.1809 77.4217 26.7867L77.5356 26.3662H81.3198L81.4862 26.5239C81.7928 26.8217 82.7388 27.7239 83.6936 33.6016C84.1578 36.431 84.4031 39.0939 84.4031 39.1202L83.2644 39.234C82.914 35.3973 81.9592 29.0116 80.8292 27.5137H78.4028C77.6495 30.3781 76.1428 36.9565 76.6159 38.98L75.5122 39.234Z"
        fill={fill}
      />
      <path
        d="M80.3392 32.6383H78.6836V30.4396C78.6836 29.9841 79.0515 29.6162 79.507 29.6162C79.9625 29.6162 80.3304 29.9841 80.3304 30.4396V32.6383H80.3392Z"
        fill={fill}
      />
      <path
        d="M80.2974 33.7153H78.7995C78.5981 33.7153 78.4316 33.5489 78.4316 33.3474C78.4316 33.1459 78.5981 32.9795 78.7995 32.9795H80.2974C80.4989 32.9795 80.6653 33.1459 80.6653 33.3474C80.6653 33.5489 80.4989 33.7153 80.2974 33.7153Z"
        fill={fill}
      />
      <path
        d="M79.5441 36.1419C79.0886 36.1419 78.7207 35.774 78.7207 35.3185C78.7207 34.863 79.0886 34.4951 79.5441 34.4951C79.9996 34.4951 80.3675 34.863 80.3675 35.3185C80.3675 35.774 79.9996 36.1419 79.5441 36.1419ZM79.5441 34.8455C79.2813 34.8455 79.0711 35.0557 79.0711 35.3185C79.0711 35.5813 79.2813 35.7915 79.5441 35.7915C79.8069 35.7915 80.0171 35.5813 80.0171 35.3185C80.0171 35.0557 79.8069 34.8455 79.5441 34.8455Z"
        fill={fill}
      />
      <path
        d="M77.0733 39.6115L76.5039 39.1998L78.5098 36.458H80.5245L82.4429 39.2085L81.8735 39.6027L80.1566 37.1588H78.8602L77.0733 39.6115Z"
        fill={fill}
      />
      <path
        d="M70.5542 36.413C70.5366 36.2991 68.6884 24.9905 67.2343 21.2764H64.6239C64.037 22.9845 61.672 30.2024 61.3829 36.3429L60.2441 36.2903C60.577 29.1338 63.5553 20.856 63.6779 20.5056L63.8181 20.1289H67.9876L68.1365 20.4705C69.6695 23.913 71.5966 35.721 71.6842 36.229L70.5542 36.413Z"
        fill={fill}
      />
      <path
        d="M66.8064 28.5124H64.6953V25.4816C64.6953 25.0261 65.0632 24.6582 65.5187 24.6582H65.9742C66.4297 24.6582 66.7976 25.0261 66.7976 25.4816V28.5124H66.8064Z"
        fill={fill}
      />
      <path
        d="M66.7088 29.8259H64.7992C64.5364 29.8259 64.3262 29.6157 64.3262 29.3529C64.3262 29.0901 64.5364 28.8799 64.7992 28.8799H66.7088C66.9716 28.8799 67.1818 29.0901 67.1818 29.3529C67.1818 29.6157 66.9716 29.8259 66.7088 29.8259Z"
        fill={fill}
      />
      <path
        d="M65.7547 32.7428C65.2379 32.7428 64.8262 32.3223 64.8262 31.8143C64.8262 31.3062 65.2466 30.8857 65.7547 30.8857C66.2628 30.8857 66.6832 31.3062 66.6832 31.8143C66.6832 32.3223 66.2715 32.7428 65.7547 32.7428ZM65.7547 31.4113C65.5269 31.4113 65.3517 31.5953 65.3517 31.8143C65.3517 32.042 65.5357 32.2172 65.7547 32.2172C65.9824 32.2172 66.1576 32.0332 66.1576 31.8143C66.1576 31.5865 65.9824 31.4113 65.7547 31.4113Z"
        fill={fill}
      />
      <path
        d="M62.4083 36.817L61.8477 36.4053L64.3704 32.9453H66.8406L69.267 36.4053L68.6976 36.8083L66.4727 33.6461H64.7295L62.4083 36.817Z"
        fill={fill}
      />
      <path
        d="M67.1827 19.5336H64.3184V19.0518C64.3184 18.2635 64.9578 17.624 65.7462 17.624C66.5345 17.624 67.174 18.2635 67.174 19.0518V19.5336H67.1827Z"
        fill={fill}
      />
      <path
        d="M66.5861 16.5634H64.9218C64.7554 16.5634 64.6152 16.432 64.6152 16.2568C64.6152 16.0903 64.7466 15.9502 64.9218 15.9502H66.5861C66.7526 15.9502 66.8927 16.0816 66.8927 16.2568C66.8927 16.4232 66.7526 16.5634 66.5861 16.5634Z"
        fill={fill}
      />
      <path
        d="M66.0624 13.3925V15.0568C66.0624 15.2233 65.931 15.3634 65.7558 15.3634C65.5894 15.3634 65.4492 15.232 65.4492 15.0568V13.3925C65.4492 13.2261 65.5806 13.0859 65.7558 13.0859C65.9222 13.0947 66.0624 13.2261 66.0624 13.3925Z"
        fill={fill}
      />
      <path
        d="M32.31 36.413C32.2925 36.2991 30.4442 24.9905 28.9901 21.2764H26.3798C25.7929 22.9845 23.4278 30.2024 23.1387 36.3429L22 36.2903C22.3329 29.1338 25.3111 20.856 25.4338 20.5056L25.5739 20.1289H29.7435L29.8924 20.4705C31.4253 23.913 33.3524 35.721 33.44 36.229L32.31 36.413Z"
        fill={fill}
      />
      <path
        d="M28.5701 28.5124H26.459V25.4816C26.459 25.0261 26.8269 24.6582 27.2824 24.6582H27.7379C28.1934 24.6582 28.5613 25.0261 28.5613 25.4816V28.5124H28.5701Z"
        fill={fill}
      />
      <path
        d="M28.4744 29.8259H26.5648C26.302 29.8259 26.0918 29.6157 26.0918 29.3529C26.0918 29.0901 26.302 28.8799 26.5648 28.8799H28.4744C28.7372 28.8799 28.9474 29.0901 28.9474 29.3529C28.9474 29.6157 28.7372 29.8259 28.4744 29.8259Z"
        fill={fill}
      />
      <path
        d="M27.5184 32.7428C27.0016 32.7428 26.5898 32.3223 26.5898 31.8143C26.5898 31.3062 27.0103 30.8857 27.5184 30.8857C28.0264 30.8857 28.4469 31.3062 28.4469 31.8143C28.4469 32.3223 28.0264 32.7428 27.5184 32.7428ZM27.5184 31.4113C27.2906 31.4113 27.1154 31.5953 27.1154 31.8143C27.1154 32.042 27.2994 32.2172 27.5184 32.2172C27.7461 32.2172 27.9213 32.0332 27.9213 31.8143C27.9213 31.5865 27.7374 31.4113 27.5184 31.4113Z"
        fill={fill}
      />
      <path
        d="M24.1739 36.817L23.6133 36.4053L26.136 32.9453H28.6062L31.0326 36.4053L30.4545 36.8083L28.2383 33.6461H26.4864L24.1739 36.817Z"
        fill={fill}
      />
      <path
        d="M28.9464 19.5336H26.082V19.0518C26.082 18.2635 26.7215 17.624 27.5098 17.624C28.2982 17.624 28.9377 18.2635 28.9377 19.0518V19.5336H28.9464Z"
        fill={fill}
      />
      <path
        d="M28.3518 16.5634H26.6874C26.521 16.5634 26.3809 16.432 26.3809 16.2568C26.3809 16.0903 26.5123 15.9502 26.6874 15.9502H28.3518C28.5182 15.9502 28.6584 16.0816 28.6584 16.2568C28.6496 16.4232 28.5182 16.5634 28.3518 16.5634Z"
        fill={fill}
      />
      <path
        d="M27.8182 13.3925V15.0568C27.8182 15.2233 27.6869 15.3634 27.5117 15.3634C27.3452 15.3634 27.2051 15.232 27.2051 15.0568V13.3925C27.2051 13.2261 27.3365 13.0859 27.5117 13.0859C27.6869 13.0947 27.8182 13.2261 27.8182 13.3925Z"
        fill={fill}
      />
      <path
        d="M53.9544 33.1905C53.9281 33.024 51.239 16.5911 49.1454 11.4229H45.081C44.3452 13.534 40.8151 24.1331 40.3946 33.1292L39.2559 33.0766C39.7201 22.9155 44.0911 10.7835 44.1349 10.6608L44.2751 10.2842H49.8812L50.0301 10.6258C52.2025 15.5049 54.9443 32.297 55.0581 33.0153L53.9544 33.1905Z"
        fill={fill}
      />
      <path
        d="M48.3854 23.7296H45.3809V19.7352C45.3809 18.903 46.0554 18.2373 46.8788 18.2373C47.7109 18.2373 48.3766 18.9118 48.3766 19.7352V23.7296H48.3854Z"
        fill={fill}
      />
      <path
        d="M48.2444 25.4818H45.5202C45.1435 25.4818 44.8457 25.1752 44.8457 24.8073C44.8457 24.4306 45.1523 24.1328 45.5202 24.1328H48.2444C48.6211 24.1328 48.9189 24.4394 48.9189 24.8073C48.9189 25.184 48.6123 25.4818 48.2444 25.4818Z"
        fill={fill}
      />
      <path
        d="M46.8786 28.5208C46.1866 28.5208 45.6172 27.9602 45.6172 27.2594C45.6172 26.5587 46.1778 25.998 46.8786 25.998C47.5793 25.998 48.14 26.5587 48.14 27.2594C48.14 27.9602 47.5706 28.5208 46.8786 28.5208ZM46.8786 26.62C46.5194 26.62 46.2304 26.909 46.2304 27.2682C46.2304 27.6273 46.5194 27.9164 46.8786 27.9164C47.2377 27.9164 47.5268 27.6273 47.5268 27.2682C47.5268 26.909 47.2377 26.62 46.8786 26.62Z"
        fill={fill}
      />
      <path
        d="M42.8223 33.6017L42.2617 33.19L45.3451 29.0029H48.3058L51.2666 33.19L50.6972 33.5929L47.9379 29.7037H45.7042L42.8223 33.6017Z"
        fill={fill}
      />
      <path
        d="M48.9189 9.18907H44.8457V7.89265C44.8457 7.10429 45.4852 6.46484 46.2735 6.46484H47.4911C48.2795 6.46484 48.9189 7.10429 48.9189 7.89265V9.18907Z"
        fill={fill}
      />
      <path
        d="M48.1923 4.94825H45.5644C45.398 4.94825 45.2578 4.81686 45.2578 4.64166V4.38764C45.2578 4.22121 45.3892 4.08105 45.5644 4.08105H48.1923C48.3587 4.08105 48.4989 4.21245 48.4989 4.38764V4.64166C48.4989 4.81686 48.3587 4.94825 48.1923 4.94825Z"
        fill={fill}
      />
      <path
        d="M47.3066 0.306585V2.93446C47.3066 3.10089 47.1752 3.24104 47.0001 3.24104H46.746C46.5796 3.24104 46.4395 3.10965 46.4395 2.93446V0.306585C46.4395 0.140153 46.5708 0 46.746 0H47.0001C47.1752 0.00875958 47.3066 0.140153 47.3066 0.306585Z"
        fill={fill}
      />
      <path
        d="M80.7264 25.98H78.3613V25.5859C78.3613 24.9377 78.8869 24.4033 79.5439 24.4033C80.1921 24.4033 80.7264 24.9289 80.7264 25.5859V25.98Z"
        fill={fill}
      />
      <path
        d="M80.2251 23.5286H78.8586C78.7184 23.5286 78.6133 23.4148 78.6133 23.2834C78.6133 23.1432 78.7272 23.0381 78.8586 23.0381H80.2251C80.3652 23.0381 80.4703 23.152 80.4703 23.2834C80.4791 23.4235 80.3652 23.5286 80.2251 23.5286Z"
        fill={fill}
      />
      <path
        d="M79.7894 20.9181V22.2846C79.7894 22.4248 79.6755 22.5299 79.5441 22.5299C79.4039 22.5299 79.2988 22.416 79.2988 22.2846V20.9181C79.2988 20.778 79.4127 20.6729 79.5441 20.6729C79.6843 20.6729 79.7894 20.7867 79.7894 20.9181Z"
        fill={fill}
      />
      <path
        d="M10.3937 39.4262L9.28125 39.1985C9.36885 38.7955 11.3573 29.2301 12.4785 26.8387L12.6362 26.5059H16.14L16.2539 26.9263C16.359 27.2942 18.7591 36.0626 18.7591 38.7868H17.6204C17.6204 36.5268 15.7546 29.4403 15.264 27.6446H13.372C12.277 30.3951 10.4113 39.3299 10.3937 39.4262Z"
        fill={fill}
      />
      <path
        d="M13.3281 32.6812H14.9837V30.4826C14.9837 30.0271 14.6158 29.6592 14.1603 29.6592C13.7048 29.6592 13.3369 30.0271 13.3369 30.4826V32.6812H13.3281Z"
        fill={fill}
      />
      <path
        d="M13.3738 33.7602H14.8717C15.0731 33.7602 15.2396 33.5938 15.2396 33.3923C15.2396 33.1908 15.0731 33.0244 14.8717 33.0244H13.3738C13.1723 33.0244 13.0059 33.1908 13.0059 33.3923C13.0059 33.5938 13.1723 33.7602 13.3738 33.7602Z"
        fill={fill}
      />
      <path
        d="M14.1261 36.099C13.6706 36.099 13.3027 35.731 13.3027 35.2755C13.3027 34.8201 13.6706 34.4521 14.1261 34.4521C14.5816 34.4521 14.9495 34.8201 14.9495 35.2755C14.9495 35.731 14.5816 36.099 14.1261 36.099ZM14.1261 34.8025C13.8633 34.8025 13.6531 35.0128 13.6531 35.2755C13.6531 35.5383 13.8633 35.7486 14.1261 35.7486C14.3889 35.7486 14.5992 35.5383 14.5992 35.2755C14.5992 35.0128 14.3889 34.8025 14.1261 34.8025Z"
        fill={fill}
      />
      <path
        d="M89.2482 50.9017H2.53711V39.1463H11.2003L13.0223 36.1943H15.2998L17.9277 40.2851H3.67585V49.7717H88.1095V40.2763H76.5556V39.1376H89.2482V50.9017ZM12.5318 39.1463H15.8342L14.6691 37.3331H13.6443L12.5318 39.1463Z"
        fill={fill}
      />
      <path
        d="M12.9423 26.2007H15.2987V25.8066C15.2987 25.1584 14.7731 24.624 14.1161 24.624C13.4679 24.624 12.9336 25.1496 12.9336 25.8066V26.2007H12.9423Z"
        fill={fill}
      />
      <path
        d="M13.4449 23.6595H14.8114C14.9516 23.6595 15.0567 23.5456 15.0567 23.4142C15.0567 23.2741 14.9428 23.1689 14.8114 23.1689H13.4449C13.3048 23.1689 13.1997 23.2828 13.1997 23.4142C13.1909 23.5544 13.3048 23.6595 13.4449 23.6595Z"
        fill={fill}
      />
      <path
        d="M13.873 21.2248V22.5913C13.873 22.7314 13.9869 22.8365 14.1183 22.8365C14.2585 22.8365 14.3636 22.7227 14.3636 22.5913V21.2248C14.3636 21.0846 14.2497 20.9795 14.1183 20.9795C13.9869 20.9795 13.873 21.0934 13.873 21.2248Z"
        fill={fill}
      />
      <path d="M40.0814 36.5098H39.3105V38.8135H40.0814V36.5098Z" fill={fill} />
      <path d="M44.558 36.5098H43.7871V38.8135H44.558V36.5098Z" fill={fill} />
      <path d="M49.0326 36.5098H48.2617V38.8135H49.0326V36.5098Z" fill={fill} />
      <path d="M53.5091 36.5098H52.7383V38.8135H53.5091V36.5098Z" fill={fill} />
      <path d="M57.9935 36.5098H57.2227V38.8135H57.9935V36.5098Z" fill={fill} />
      <path
        d="M37.595 38.5428H37.3848C37.0694 38.5428 36.8066 38.2888 36.8066 37.9647V37.3428C36.8066 37.0274 37.0607 36.7646 37.3848 36.7646H37.595C37.9103 36.7646 38.1731 37.0187 38.1731 37.3428V37.9647C38.1731 38.2888 37.9191 38.5428 37.595 38.5428Z"
        fill={fill}
      />
      <path
        d="M19.2508 54.6067H19.0756C18.6727 54.6067 18.3398 54.2739 18.3398 53.8709V53.1089C18.3398 52.7059 18.6727 52.373 19.0756 52.373H19.2508C19.6538 52.373 19.9866 52.7059 19.9866 53.1089V53.8709C19.9866 54.2826 19.6625 54.6067 19.2508 54.6067Z"
        fill={fill}
      />
      <path
        d="M22.1864 54.6067H22.0112C21.6082 54.6067 21.2754 54.2739 21.2754 53.8709V53.1089C21.2754 52.7059 21.6082 52.373 22.0112 52.373H22.1864C22.5893 52.373 22.9222 52.7059 22.9222 53.1089V53.8709C22.9222 54.2826 22.5981 54.6067 22.1864 54.6067Z"
        fill={fill}
      />
      <path
        d="M25.12 54.6067H24.9448C24.5418 54.6067 24.209 54.2739 24.209 53.8709V53.1089C24.209 52.7059 24.5418 52.373 24.9448 52.373H25.12C25.5229 52.373 25.8558 52.7059 25.8558 53.1089V53.8709C25.8558 54.2826 25.5317 54.6067 25.12 54.6067Z"
        fill={fill}
      />
      <path
        d="M28.0555 54.6067H27.8803C27.4774 54.6067 27.1445 54.2739 27.1445 53.8709V53.1089C27.1445 52.7059 27.4774 52.373 27.8803 52.373H28.0555C28.4585 52.373 28.7913 52.7059 28.7913 53.1089V53.8709C28.7913 54.2826 28.4672 54.6067 28.0555 54.6067Z"
        fill={fill}
      />
      <path
        d="M30.9872 54.6067H30.812C30.409 54.6067 30.0762 54.2739 30.0762 53.8709V53.1089C30.0762 52.7059 30.409 52.373 30.812 52.373H30.9872C31.3901 52.373 31.723 52.7059 31.723 53.1089V53.8709C31.723 54.2826 31.3901 54.6067 30.9872 54.6067Z"
        fill={fill}
      />
      <path
        d="M33.9247 54.6067H33.7495C33.3465 54.6067 33.0137 54.2739 33.0137 53.8709V53.1089C33.0137 52.7059 33.3465 52.373 33.7495 52.373H33.9247C34.3276 52.373 34.6605 52.7059 34.6605 53.1089V53.8709C34.6605 54.2826 34.3276 54.6067 33.9247 54.6067Z"
        fill={fill}
      />
      <path
        d="M36.8563 54.6067H36.6811C36.2782 54.6067 35.9453 54.2739 35.9453 53.8709V53.1089C35.9453 52.7059 36.2782 52.373 36.6811 52.373H36.8563C37.2592 52.373 37.5921 52.7059 37.5921 53.1089V53.8709C37.5921 54.2826 37.2592 54.6067 36.8563 54.6067Z"
        fill={fill}
      />
      <path
        d="M39.7918 54.6067H39.6167C39.2137 54.6067 38.8809 54.2739 38.8809 53.8709V53.1089C38.8809 52.7059 39.2137 52.373 39.6167 52.373H39.7918C40.1948 52.373 40.5277 52.7059 40.5277 53.1089V53.8709C40.5277 54.2826 40.1948 54.6067 39.7918 54.6067Z"
        fill={fill}
      />
      <path
        d="M42.7254 54.6067H42.5502C42.1473 54.6067 41.8145 54.2739 41.8145 53.8709V53.1089C41.8145 52.7059 42.1473 52.373 42.5502 52.373H42.7254C43.1284 52.373 43.4613 52.7059 43.4613 53.1089V53.8709C43.4613 54.2826 43.1284 54.6067 42.7254 54.6067Z"
        fill={fill}
      />
      <path
        d="M45.661 54.6067H45.4858C45.0829 54.6067 44.75 54.2739 44.75 53.8709V53.1089C44.75 52.7059 45.0829 52.373 45.4858 52.373H45.661C46.0639 52.373 46.3968 52.7059 46.3968 53.1089V53.8709C46.3968 54.2826 46.0639 54.6067 45.661 54.6067Z"
        fill={fill}
      />
      <path
        d="M48.5868 54.6067H48.4116C48.0087 54.6067 47.6758 54.2739 47.6758 53.8709V53.1089C47.6758 52.7059 48.0087 52.373 48.4116 52.373H48.5868C48.9897 52.373 49.3226 52.7059 49.3226 53.1089V53.8709C49.3313 54.2826 48.9985 54.6067 48.5868 54.6067Z"
        fill={fill}
      />
      <path
        d="M51.5223 54.6067H51.3471C50.9442 54.6067 50.6113 54.2739 50.6113 53.8709V53.1089C50.6113 52.7059 50.9442 52.373 51.3471 52.373H51.5223C51.9253 52.373 52.2581 52.7059 52.2581 53.1089V53.8709C52.2581 54.2826 51.934 54.6067 51.5223 54.6067Z"
        fill={fill}
      />
      <path
        d="M54.454 54.6067H54.2788C53.8758 54.6067 53.543 54.2739 53.543 53.8709V53.1089C53.543 52.7059 53.8758 52.373 54.2788 52.373H54.454C54.8569 52.373 55.1898 52.7059 55.1898 53.1089V53.8709C55.1898 54.2826 54.8657 54.6067 54.454 54.6067Z"
        fill={fill}
      />
      <path
        d="M57.3915 54.6067H57.2163C56.8133 54.6067 56.4805 54.2739 56.4805 53.8709V53.1089C56.4805 52.7059 56.8133 52.373 57.2163 52.373H57.3915C57.7944 52.373 58.1273 52.7059 58.1273 53.1089V53.8709C58.1273 54.2826 57.8032 54.6067 57.3915 54.6067Z"
        fill={fill}
      />
      <path
        d="M60.3231 54.6067H60.1479C59.745 54.6067 59.4121 54.2739 59.4121 53.8709V53.1089C59.4121 52.7059 59.745 52.373 60.1479 52.373H60.3231C60.7261 52.373 61.0589 52.7059 61.0589 53.1089V53.8709C61.0589 54.2826 60.7348 54.6067 60.3231 54.6067Z"
        fill={fill}
      />
      <path
        d="M63.2587 54.6067H63.0835C62.6805 54.6067 62.3477 54.2739 62.3477 53.8709V53.1089C62.3477 52.7059 62.6805 52.373 63.0835 52.373H63.2587C63.6616 52.373 63.9945 52.7059 63.9945 53.1089V53.8709C63.9945 54.2826 63.6616 54.6067 63.2587 54.6067Z"
        fill={fill}
      />
      <path
        d="M66.1923 54.6067H66.0171C65.6141 54.6067 65.2812 54.2739 65.2812 53.8709V53.1089C65.2812 52.7059 65.6141 52.373 66.0171 52.373H66.1923C66.5952 52.373 66.9281 52.7059 66.9281 53.1089V53.8709C66.9281 54.2826 66.5952 54.6067 66.1923 54.6067Z"
        fill={fill}
      />
      <path
        d="M69.1278 54.6067H68.9526C68.5497 54.6067 68.2168 54.2739 68.2168 53.8709V53.1089C68.2168 52.7059 68.5497 52.373 68.9526 52.373H69.1278C69.5307 52.373 69.8636 52.7059 69.8636 53.1089V53.8709C69.8636 54.2826 69.5307 54.6067 69.1278 54.6067Z"
        fill={fill}
      />
      <path
        d="M72.0634 54.6067H71.8882C71.4852 54.6067 71.1523 54.2739 71.1523 53.8709V53.1089C71.1523 52.7059 71.4852 52.373 71.8882 52.373H72.0634C72.4663 52.373 72.7991 52.7059 72.7991 53.1089V53.8709C72.7991 54.2826 72.4663 54.6067 72.0634 54.6067Z"
        fill={fill}
      />
      <path
        d="M74.9969 54.6067H74.8218C74.4188 54.6067 74.0859 54.2739 74.0859 53.8709V53.1089C74.0859 52.7059 74.4188 52.373 74.8218 52.373H74.9969C75.3999 52.373 75.7327 52.7059 75.7327 53.1089V53.8709C75.7327 54.2826 75.3999 54.6067 74.9969 54.6067Z"
        fill={fill}
      />
      <path
        d="M77.9305 54.6067H77.7553C77.3524 54.6067 77.0195 54.2739 77.0195 53.8709V53.1089C77.0195 52.7059 77.3524 52.373 77.7553 52.373H77.9305C78.3335 52.373 78.6663 52.7059 78.6663 53.1089V53.8709C78.6663 54.2826 78.3335 54.6067 77.9305 54.6067Z"
        fill={fill}
      />
      <path
        d="M80.8661 54.6067H80.6909C80.288 54.6067 79.9551 54.2739 79.9551 53.8709V53.1089C79.9551 52.7059 80.288 52.373 80.6909 52.373H80.8661C81.269 52.373 81.6019 52.7059 81.6019 53.1089V53.8709C81.6019 54.2826 81.269 54.6067 80.8661 54.6067Z"
        fill={fill}
      />
      <path
        d="M83.7899 54.6067H83.6147C83.2118 54.6067 82.8789 54.2739 82.8789 53.8709V53.1089C82.8789 52.7059 83.2118 52.373 83.6147 52.373H83.7899C84.1928 52.373 84.5257 52.7059 84.5257 53.1089V53.8709C84.5257 54.2826 84.2016 54.6067 83.7899 54.6067Z"
        fill={fill}
      />
      <path
        d="M86.7254 54.6067H86.5502C86.1473 54.6067 85.8145 54.2739 85.8145 53.8709V53.1089C85.8145 52.7059 86.1473 52.373 86.5502 52.373H86.7254C87.1284 52.373 87.4612 52.7059 87.4612 53.1089V53.8709C87.4612 54.2826 87.1371 54.6067 86.7254 54.6067Z"
        fill={fill}
      />
      <path
        d="M16.3954 54.6067H16.2202C15.8173 54.6067 15.4844 54.2739 15.4844 53.8709V53.1089C15.4844 52.7059 15.8173 52.373 16.2202 52.373H16.3954C16.7983 52.373 17.1312 52.7059 17.1312 53.1089V53.8709C17.1312 54.2826 16.7983 54.6067 16.3954 54.6067Z"
        fill={fill}
      />
      <path
        d="M13.5321 54.6067H13.3569C12.9539 54.6067 12.6211 54.2739 12.6211 53.8709V53.1089C12.6211 52.7059 12.9539 52.373 13.3569 52.373H13.5321C13.935 52.373 14.2679 52.7059 14.2679 53.1089V53.8709C14.2679 54.2826 13.935 54.6067 13.5321 54.6067Z"
        fill={fill}
      />
      <path
        d="M10.6668 54.6067H10.4917C10.0887 54.6067 9.75586 54.2739 9.75586 53.8709V53.1089C9.75586 52.7059 10.0887 52.373 10.4917 52.373H10.6668C11.0698 52.373 11.4027 52.7059 11.4027 53.1089V53.8709C11.4027 54.2826 11.0698 54.6067 10.6668 54.6067Z"
        fill={fill}
      />
      <path
        d="M7.80357 54.6067H7.62837C7.22543 54.6067 6.89258 54.2739 6.89258 53.8709V53.1089C6.89258 52.7059 7.22543 52.373 7.62837 52.373H7.80357C8.20651 52.373 8.53936 52.7059 8.53936 53.1089V53.8709C8.53936 54.2826 8.21527 54.6067 7.80357 54.6067Z"
        fill={fill}
      />
      <path
        d="M4.9364 54.6067H4.76121C4.35827 54.6067 4.02539 54.2739 4.02539 53.8709V53.1089C4.02539 52.7059 4.35827 52.373 4.76121 52.373H4.9364C5.33934 52.373 5.6722 52.7059 5.6722 53.1089V53.8709C5.68096 54.2826 5.3481 54.6067 4.9364 54.6067Z"
        fill={fill}
      />
      <path
        d="M42.0364 38.5428H41.8262C41.5108 38.5428 41.248 38.2888 41.248 37.9647V37.3428C41.248 37.0274 41.5021 36.7646 41.8262 36.7646H42.0364C42.3518 36.7646 42.6145 37.0187 42.6145 37.3428V37.9647C42.6145 38.2888 42.3605 38.5428 42.0364 38.5428Z"
        fill={fill}
      />
      <path
        d="M46.4739 38.5428H46.2637C45.9483 38.5428 45.6855 38.2888 45.6855 37.9647V37.3428C45.6855 37.0274 45.9396 36.7646 46.2637 36.7646H46.4739C46.7893 36.7646 47.052 37.0187 47.052 37.3428V37.9647C47.052 38.2888 46.7893 38.5428 46.4739 38.5428Z"
        fill={fill}
      />
      <path
        d="M50.9153 38.5428H50.7051C50.3897 38.5428 50.127 38.2888 50.127 37.9647V37.3428C50.127 37.0274 50.381 36.7646 50.7051 36.7646H50.9153C51.2307 36.7646 51.4935 37.0187 51.4935 37.3428V37.9647C51.4935 38.2888 51.2307 38.5428 50.9153 38.5428Z"
        fill={fill}
      />
      <path
        d="M55.3567 38.5428H55.1465C54.8312 38.5428 54.5684 38.2888 54.5684 37.9647V37.3428C54.5684 37.0274 54.8224 36.7646 55.1465 36.7646H55.3567C55.6721 36.7646 55.9349 37.0187 55.9349 37.3428V37.9647C55.9349 38.2888 55.6721 38.5428 55.3567 38.5428Z"
        fill={fill}
      />
      <path
        d="M81.3485 50.017H80.8229V47.0826L80.8492 47.03C80.9105 46.8899 80.8842 46.811 80.8755 46.7935C80.8229 46.6709 80.6477 46.5833 80.5952 46.5657L80.4375 46.5044L80.42 46.3292C80.4112 46.1891 80.3674 46.0927 80.2973 46.0314C80.1835 45.9351 80.0083 45.9526 80.0083 45.9526L79.7893 45.9789L79.7192 45.7686C79.6842 45.6548 79.5528 45.4358 79.4126 45.4358C79.3601 45.4358 79.2287 45.611 79.1673 45.7949L79.0885 46.0489L78.8345 45.9701C78.7381 45.9351 78.7118 45.9613 78.6943 45.9701C78.6155 46.0227 78.5629 46.2066 78.5542 46.3205L78.5367 46.5482L78.3089 46.5657C78.1337 46.5745 78.0899 46.6446 78.0724 46.6621C77.9936 46.7847 78.0461 47.0388 78.0812 47.1439L78.0987 47.1877V49.9557H77.5731V47.2753C77.5293 47.1264 77.4242 46.6971 77.6344 46.3818C77.7045 46.2679 77.8359 46.1453 78.0636 46.084C78.1074 45.9088 78.195 45.681 78.3965 45.5409C78.4753 45.4883 78.5892 45.4358 78.7469 45.4358C78.852 45.2168 79.0622 44.9189 79.4038 44.9189C79.7717 44.9189 80.0083 45.1993 80.1397 45.4445C80.2798 45.462 80.455 45.5058 80.6127 45.6372C80.7178 45.7248 80.8492 45.8825 80.9105 46.1453C81.0507 46.2241 81.2434 46.3643 81.3485 46.592C81.4098 46.7234 81.4448 46.9336 81.3397 47.2052V50.017H81.3485Z"
        fill={fill}
      />
      <path
        d="M16.6571 50.017H16.1315V47.0826L16.1578 47.03C16.2191 46.8899 16.1928 46.811 16.184 46.7935C16.1315 46.6709 15.9563 46.5833 15.9037 46.5657L15.7461 46.5044L15.7286 46.3292C15.7198 46.1891 15.676 46.0927 15.6059 46.0314C15.4921 45.9351 15.3169 45.9526 15.3169 45.9526L15.0979 45.9789L15.0278 45.7686C14.9927 45.6548 14.8614 45.4358 14.7212 45.4358C14.6686 45.4358 14.5372 45.611 14.4759 45.7949L14.3971 46.0489L14.1431 45.9701C14.0467 45.9351 14.0204 45.9613 14.0029 45.9701C13.9241 46.0227 13.8715 46.2066 13.8628 46.3205L13.8452 46.5482L13.6175 46.5657C13.4423 46.5745 13.3985 46.6446 13.381 46.6621C13.3021 46.7847 13.3547 47.0388 13.3898 47.1439L13.4073 47.1877V47.2315V49.9557H12.8817V47.2753C12.8379 47.1264 12.7328 46.6971 12.943 46.3818C13.0131 46.2679 13.1445 46.1453 13.3722 46.084C13.416 45.9088 13.5036 45.681 13.7051 45.5409C13.7839 45.4883 13.8978 45.4358 14.0555 45.4358C14.1606 45.2168 14.3708 44.9189 14.7124 44.9189C15.0803 44.9189 15.3169 45.1993 15.4483 45.4445C15.5884 45.462 15.7636 45.5058 15.9213 45.6372C16.0264 45.7248 16.1578 45.8825 16.2191 46.1453C16.3593 46.2241 16.552 46.3643 16.6571 46.592C16.7184 46.7234 16.7534 46.9336 16.6483 47.2052V50.017H16.6571Z"
        fill={fill}
      />
      <path
        d="M68.2065 50.0174H67.6809V46.4347L67.7072 46.3821C67.7685 46.2507 67.7773 46.1369 67.7335 46.0405C67.6459 45.839 67.3568 45.7252 67.3568 45.7252L67.1904 45.6726L67.1816 45.4974C67.1641 45.3135 67.1116 45.1733 67.0152 45.0945C66.8575 44.9631 66.621 44.9894 66.6122 44.9894L66.3933 45.0156L66.3232 44.8054C66.2794 44.6565 66.113 44.3587 65.894 44.3587C65.7713 44.3587 65.6049 44.639 65.5348 44.8404L65.456 45.0945L65.202 45.0156C65.0618 44.9718 65.0005 45.0069 64.983 45.0244C64.8516 45.112 64.799 45.3835 64.7903 45.4974L64.7727 45.7252L64.545 45.7427C64.2997 45.7602 64.2297 45.8566 64.2121 45.8916C64.0895 46.0755 64.1859 46.4435 64.2121 46.531L64.2296 46.5748V49.956H63.7041V46.6712C63.6515 46.5048 63.5201 45.9967 63.7742 45.6113C63.8617 45.4799 64.0194 45.331 64.2997 45.2609C64.3435 45.0594 64.4486 44.7616 64.6939 44.6039C64.7903 44.5426 64.9304 44.4813 65.1231 44.4901C65.2458 44.236 65.491 43.8594 65.894 43.8594C66.3319 43.8594 66.6123 44.2098 66.7524 44.4988C66.9101 44.5076 67.1378 44.5601 67.3393 44.7178C67.5232 44.8667 67.6371 45.077 67.6809 45.3397C67.8474 45.4273 68.0926 45.5938 68.2153 45.8741C68.2853 46.0318 68.3291 46.277 68.2065 46.5924V50.0174Z"
        fill={fill}
      />
      <path
        d="M29.7612 50.0174H29.2356V46.4347L29.2619 46.3821C29.3232 46.2507 29.332 46.1369 29.2882 46.0405C29.2006 45.839 28.9115 45.7252 28.9115 45.7252L28.7451 45.6726L28.7363 45.4974C28.7188 45.3135 28.6662 45.1733 28.5699 45.0945C28.4122 44.9631 28.1757 44.9894 28.1669 44.9894L27.9479 45.0156L27.8779 44.8054C27.8341 44.6565 27.6676 44.3587 27.4487 44.3587C27.326 44.3587 27.1596 44.6477 27.0895 44.8404L27.0107 45.0945L26.7566 45.0156C26.6165 44.9718 26.5552 45.0069 26.5376 45.0244C26.4063 45.112 26.3537 45.3835 26.3449 45.4974L26.3274 45.7252L26.0997 45.7427C25.8544 45.7602 25.7843 45.8566 25.7668 45.8916C25.6442 46.0755 25.7405 46.4435 25.7668 46.531L25.7843 46.5748V49.956H25.2588V46.6712C25.2062 46.5048 25.0748 45.9967 25.3288 45.6113C25.4164 45.4799 25.5741 45.331 25.8544 45.2609C25.8982 45.0594 26.0033 44.7616 26.2486 44.6039C26.345 44.5426 26.4851 44.4813 26.6778 44.4901C26.8004 44.236 27.0457 43.8594 27.4487 43.8594C27.8866 43.8594 28.1669 44.2098 28.3071 44.4988C28.4648 44.5076 28.6925 44.5601 28.894 44.7178C29.0779 44.8667 29.1918 45.077 29.2356 45.3397C29.402 45.4273 29.6473 45.5938 29.7699 45.8741C29.84 46.0318 29.8838 46.277 29.7612 46.5924V50.0174Z"
        fill={fill}
      />
      <path
        d="M57.0386 50.3323H56.4254V45.6722L56.4517 45.6109C56.5393 45.427 56.548 45.2693 56.4867 45.1379C56.3904 44.9014 56.0838 44.7437 55.9699 44.7087L55.7772 44.6386L55.7597 44.4371C55.7421 44.1831 55.6633 43.9904 55.5232 43.8765C55.3042 43.7013 54.9888 43.7276 54.9801 43.7276L54.726 43.7627L54.6472 43.5174C54.5859 43.3159 54.3581 42.9042 54.0515 42.9042C53.8676 42.9042 53.6398 43.2809 53.5522 43.5612L53.4559 43.8503L53.1668 43.7539C52.9653 43.6838 52.8778 43.7451 52.8515 43.7627C52.6588 43.894 52.5887 44.2795 52.5799 44.4109L52.5624 44.6824L52.2909 44.6999C51.9492 44.7174 51.8617 44.8576 51.8266 44.9014C51.6602 45.1467 51.7741 45.6197 51.8266 45.7686L51.8441 45.8212V45.8737V50.2097H51.231V45.9263C51.1696 45.7248 50.9944 45.0591 51.3098 44.5773C51.4149 44.4109 51.6251 44.2094 51.993 44.1306C52.0456 43.8765 52.177 43.4823 52.4923 43.2721C52.615 43.1845 52.7989 43.1057 53.0617 43.1232C53.2106 42.7991 53.526 42.2998 54.0428 42.2998C54.6034 42.2998 54.9625 42.7641 55.1377 43.1232C55.348 43.132 55.6458 43.1933 55.8998 43.4035C56.1363 43.5962 56.2765 43.859 56.3378 44.2006C56.548 44.3057 56.8721 44.5247 57.0298 44.8839C57.1174 45.0766 57.1787 45.3919 57.0123 45.7949V50.3323H57.0386Z"
        fill={fill}
      />
      <path
        d="M42.6753 49.8685H42.0621V45.2084L42.0884 45.1471C42.176 44.9631 42.1847 44.8054 42.1234 44.674C42.0271 44.4375 41.7205 44.2799 41.6066 44.2448L41.4139 44.1747L41.3964 43.9733C41.3789 43.7192 41.3 43.5353 41.1599 43.4214C40.9409 43.2375 40.6168 43.2725 40.6168 43.2725L40.3627 43.2988L40.2839 43.0535C40.2226 42.852 39.9948 42.4403 39.6883 42.4403C39.5043 42.4403 39.2766 42.817 39.189 43.0973L39.0926 43.3864L38.8035 43.29C38.6021 43.2199 38.5145 43.2813 38.4882 43.2988C38.2955 43.4302 38.2254 43.8156 38.2166 43.947L38.1991 44.2185L37.9276 44.2361C37.586 44.2536 37.4984 44.3937 37.4633 44.4375C37.2969 44.6828 37.4108 45.1558 37.4633 45.3047L37.4809 45.3573V45.4098V49.7458H36.8677V45.4624C36.8064 45.2609 36.6312 44.5952 36.9465 44.1134C37.0516 43.947 37.2619 43.7455 37.6298 43.6667C37.6823 43.4127 37.8137 43.0185 38.1291 42.8082C38.2517 42.7207 38.4356 42.6418 38.6984 42.6593C38.8473 42.3352 39.1627 41.8359 39.6795 41.8359C40.2401 41.8359 40.5992 42.3002 40.7744 42.6593C40.9847 42.6681 41.2825 42.7294 41.5365 42.9396C41.773 43.1324 41.9132 43.4039 41.9745 43.7368C42.1847 43.8419 42.5088 44.0609 42.6665 44.42C42.7541 44.6127 42.8154 44.9281 42.649 45.331V49.8685H42.6753Z"
        fill={fill}
      />
      <path
        d="M49.1732 50.017H48.56V46.9336L48.5863 46.8723C48.6476 46.7322 48.6214 46.6708 48.6126 46.6446C48.56 46.5219 48.3936 46.4431 48.3411 46.4168L48.1571 46.3467L48.1396 46.1453C48.1308 46.0051 48.0958 45.9088 48.0257 45.8562C47.9381 45.7774 47.7892 45.7774 47.7454 45.7861L47.4914 45.8124L47.4125 45.5671C47.3775 45.4533 47.2549 45.243 47.1235 45.243C47.0709 45.2518 46.957 45.427 46.8957 45.6022L46.7994 45.8912L46.5103 45.7949C46.4753 45.7861 46.4227 45.7686 46.3964 45.7861C46.3264 45.8387 46.2738 46.0314 46.265 46.1277L46.2475 46.3993L45.976 46.4168C45.8621 46.4256 45.792 46.4518 45.757 46.5044C45.6869 46.6095 45.7219 46.846 45.7657 46.9774L45.7833 47.03V47.0825V49.9294H45.1701V47.1351C45.1175 46.9687 45.0124 46.5219 45.2402 46.1715C45.319 46.0577 45.4504 45.9175 45.6869 45.8474C45.7307 45.6635 45.8358 45.427 46.0461 45.2781C46.1336 45.2255 46.2475 45.1642 46.414 45.1642C46.5366 44.9364 46.7643 44.6211 47.1235 44.6211C47.5176 44.6211 47.7717 44.9102 47.9118 45.1729C48.0607 45.1905 48.2447 45.243 48.4111 45.3744C48.525 45.4708 48.6652 45.6372 48.7265 45.9088C48.8754 45.9963 49.0769 46.1453 49.182 46.3818C49.2433 46.5219 49.2871 46.7497 49.182 47.0387V50.017H49.1732Z"
        fill={fill}
      />
      <path d="M16.4559 40.6182H3.35156V41.7569H16.4559V40.6182Z" fill={fill} />
      <path d="M3.66804 50.333H2.5293V55.4311H3.66804V50.333Z" fill={fill} />
      <path d="M89.2481 50.333H88.1094V55.4311H89.2481V50.333Z" fill={fill} />
    </svg>
  );
}

export function NoidaSvg({ fill = "#4E4B66" }: FillProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="48"
      viewBox="0 0 65 48"
      fill="none"
      className="max-w-full"
    >
      <path
        d="M64.6194 47.1895H0.712891V48.0001H64.6194V47.1895Z"
        fill={fill}
      />
      <path
        d="M13.3897 47.5941H12.5791V44.545H7.47857V47.5941H6.66797V43.7344H13.3897V47.5941Z"
        fill={fill}
      />
      <path
        d="M13.3897 38.4898H6.66797V35.5654H13.3959V38.4898H13.3897ZM7.47857 37.6792H12.5853V36.376H7.47857V37.6792Z"
        fill={fill}
      />
      <path d="M8.25201 38.2285H7.44141V44.1459H8.25201V38.2285Z" fill={fill} />
      <path d="M10.4356 37.9414H9.625V44.1394H10.4356V37.9414Z" fill={fill} />
      <path d="M13.3887 38.085H12.5781V44.1395H13.3887V38.085Z" fill={fill} />
      <path
        d="M55.6671 30.0412H9.625V25.957H55.6671V30.0412ZM10.4356 29.2306H54.8565V26.7676H10.4356V29.2306Z"
        fill={fill}
      />
      <path d="M13.1778 29.8232H12.3672V32.9222H13.1778V29.8232Z" fill={fill} />
      <path
        d="M10.6876 35.5719H9.87695V32.6787H14.3664V33.4893H10.6876V35.5719Z"
        fill={fill}
      />
      <path d="M22.5936 36.1084H13.3652V36.919H22.5936V36.1084Z" fill={fill} />
      <path
        d="M20.6204 36.1831H19.8098V32.4356H14.9649V36.0895H14.1543V31.625H20.6204V36.1831Z"
        fill={fill}
      />
      <path d="M22.586 32.6787H20.4473V33.4893H22.586V32.6787Z" fill={fill} />
      <path d="M22.9922 29.8232H22.1816V43.6845H22.9922V29.8232Z" fill={fill} />
      <path d="M26.7032 29.8232H25.8926V41.9573H26.7032V29.8232Z" fill={fill} />
      <path d="M29.2325 29.8232H28.4219V41.9573H29.2325V29.8232Z" fill={fill} />
      <path d="M37.0899 29.8232H36.2793V41.9573H37.0899V29.8232Z" fill={fill} />
      <path d="M39.5547 29.8232H38.7441V41.9573H39.5547V29.8232Z" fill={fill} />
      <path d="M36.6785 31.625H28.8281V32.4356H36.6785V31.625Z" fill={fill} />
      <path
        d="M24.3751 43.6842H23.5645V31.625H26.015V32.4356H24.3751V43.6842Z"
        fill={fill}
      />
      <path
        d="M20.1856 47.5938H19.375V45.2119H46.0687V47.413H45.2581V46.0225H20.1856V47.5938Z"
        fill={fill}
      />
      <path
        d="M44.238 45.6171H43.4274V44.2266H21.9278V45.3365H21.1172V43.416H44.238V45.6171Z"
        fill={fill}
      />
      <path
        d="M30.0125 43.6843H29.2019V42.3624H25.8223V43.4348H25.0117V41.5518H30.0125V43.6843Z"
        fill={fill}
      />
      <path
        d="M40.5066 43.6843H39.696V42.3624H36.3165V43.6843H35.5059V41.5518H40.5066V43.6843Z"
        fill={fill}
      />
      <path
        d="M41.7051 43.6842H40.8945V32.4356H39.5352V31.625H41.7051V43.6842Z"
        fill={fill}
      />
      <path d="M43.3575 29.8232H42.5469V43.6845H43.3575V29.8232Z" fill={fill} />
      <path d="M52.21 36.1084H43.125V36.919H52.21V36.1084Z" fill={fill} />
      <path d="M44.922 32.6787H43.3008V33.4893H44.922V32.6787Z" fill={fill} />
      <path
        d="M51.1368 36.3577H50.3262V32.4356H45.4688V36.3577H44.6582V31.625H51.1368V36.3577Z"
        fill={fill}
      />
      <path
        d="M55.3969 36.183H54.5863V33.4893H51.082V32.6787H55.3969V36.183Z"
        fill={fill}
      />
      <path d="M52.7911 29.6357H51.9805V33.0839H52.7911V29.6357Z" fill={fill} />
      <path
        d="M52.7911 47.5934H51.9805V35.7773H58.7084V38.259H52.7911V47.5934ZM52.7911 37.4484H57.8978V36.5879H52.7911V37.4484Z"
        fill={fill}
      />
      <path d="M55.6661 38.0166H54.8555V44.252H55.6661V38.0166Z" fill={fill} />
      <path
        d="M58.9195 47.4885H58.1089V44.6514H52.7402V43.8408H58.9195V47.4885Z"
        fill={fill}
      />
      <path d="M57.9276 38.0166H57.0547V44.252H57.9276V38.0166Z" fill={fill} />
      <path d="M11.9303 27.3486H11.0137V28.6518H11.9303V27.3486Z" fill={fill} />
      <path d="M14.5787 27.3486H13.6621V28.6518H14.5787V27.3486Z" fill={fill} />
      <path d="M17.2291 27.3486H16.3125V28.6518H17.2291V27.3486Z" fill={fill} />
      <path d="M19.8873 27.3486H18.9707V28.6518H19.8873V27.3486Z" fill={fill} />
      <path d="M22.5377 27.3486H21.6211V28.6518H22.5377V27.3486Z" fill={fill} />
      <path d="M25.192 27.3486H24.2754V28.6518H25.192V27.3486Z" fill={fill} />
      <path d="M27.8424 27.3486H26.9258V28.6518H27.8424V27.3486Z" fill={fill} />
      <path d="M29.2896 21.5928H28.373V22.765H29.2896V21.5928Z" fill={fill} />
      <path d="M32.7447 21.5918H31.8281V22.764H32.7447V21.5918Z" fill={fill} />
      <path d="M36.3248 21.5918H35.4082V22.764H36.3248V21.5918Z" fill={fill} />
      <path d="M30.4928 27.3486H29.5762V28.6518H30.4928V27.3486Z" fill={fill} />
      <path d="M33.149 27.3486H32.2324V28.6518H33.149V27.3486Z" fill={fill} />
      <path d="M35.7975 27.3486H34.8809V28.6518H35.7975V27.3486Z" fill={fill} />
      <path d="M38.4576 27.3486H37.541V28.6518H38.4576V27.3486Z" fill={fill} />
      <path d="M41.1061 27.3486H40.1895V28.6518H41.1061V27.3486Z" fill={fill} />
      <path d="M43.7623 27.3486H42.8457V28.6518H43.7623V27.3486Z" fill={fill} />
      <path d="M46.4127 27.3486H45.4961V28.6518H46.4127V27.3486Z" fill={fill} />
      <path d="M49.0631 27.3486H48.1465V28.6518H49.0631V27.3486Z" fill={fill} />
      <path d="M51.7174 27.3486H50.8008V28.6518H51.7174V27.3486Z" fill={fill} />
      <path d="M54.3678 27.3486H53.4512V28.6518H54.3678V27.3486Z" fill={fill} />
      <path
        d="M23.0149 26.0887C23.0149 26.0763 22.728 24.9976 21.9112 23.9375C20.845 22.5533 19.4108 21.8487 17.6462 21.8487C15.8816 21.8487 14.4537 22.5533 13.3999 23.9375C12.5955 24.9976 12.315 26.0763 12.315 26.0825L11.5293 25.8892C11.5418 25.8393 11.8411 24.6608 12.739 23.4699C13.5745 22.36 15.1084 21.0381 17.6462 21.0381C20.184 21.0381 21.7242 22.36 22.5722 23.4699C23.4763 24.6546 23.7881 25.8393 23.8005 25.8892L23.0149 26.0887Z"
        fill={fill}
      />
      <path
        d="M52.6477 26.0887C52.6477 26.0763 52.3609 24.9976 51.544 23.9375C50.4778 22.5533 49.0436 21.8487 47.279 21.8487C45.5144 21.8487 44.0865 22.5533 43.0327 23.9375C42.2284 24.9976 41.9478 26.0763 41.9478 26.0825L41.1621 25.8892C41.1746 25.8393 41.4739 24.6608 42.3718 23.4699C43.2073 22.36 44.7412 21.0381 47.279 21.0381C49.8168 21.0381 51.357 22.36 52.205 23.4699C53.1091 24.6546 53.4209 25.8393 53.4333 25.8892L52.6477 26.0887Z"
        fill={fill}
      />
      <path
        d="M43.3561 24.1241H22.1309V20.0898H43.3561V24.1241ZM22.9415 23.3135H42.5455V20.9004H22.9415V23.3135Z"
        fill={fill}
      />
      <path
        d="M36.2034 20.1266H35.3928V18.3682H29.9805V20.1266H29.1699V17.5576H36.2034V20.1266Z"
        fill={fill}
      />
      <path d="M27.3301 20.8691H26.5195V23.7187H27.3301V20.8691Z" fill={fill} />
      <path d="M37.9434 20.4951H37.1328V23.4008H37.9434V20.4951Z" fill={fill} />
      <path
        d="M16.1705 19.0551H10.752V15.7939H11.5626V18.2445H16.1705V19.0551Z"
        fill={fill}
      />
      <path d="M13.1778 19.042H12.3672V23.7185H13.1778V19.042Z" fill={fill} />
      <path d="M16.7188 19.9834H15.9082V21.4425H16.7188V19.9834Z" fill={fill} />
      <path d="M19.3751 19.7969H18.5645V21.4493H19.3751V19.7969Z" fill={fill} />
      <path
        d="M17.8894 20.7633C17.6088 20.7633 17.2845 20.7259 16.9042 20.6386C15.9938 20.4266 15.6758 19.9216 15.576 19.535C15.3703 18.768 15.8504 17.8826 16.3804 17.39C17.0102 16.8039 17.2284 16.2739 17.2284 16.2676L17.5589 15.457L17.9642 16.2302C18.1513 16.5856 18.3383 16.754 18.9619 17.3152C19.0741 17.4212 19.205 17.5334 19.3485 17.6644C19.8535 18.1258 20.0219 18.7368 19.8161 19.3791C19.6914 19.7657 19.4732 20.0525 19.3983 20.1398C19.2923 20.3082 18.8933 20.7633 17.8894 20.7633ZM17.64 17.1718C17.4778 17.4087 17.2471 17.6893 16.9354 17.9824C16.5675 18.3253 16.2495 18.9239 16.3617 19.3292C16.4303 19.5786 16.6735 19.7532 17.0912 19.853C18.3009 20.1336 18.6813 19.7594 18.7249 19.6971L18.7436 19.6472L18.7686 19.6347C18.7686 19.6347 18.9619 19.4103 19.0492 19.1172C19.1489 18.7805 19.0679 18.5124 18.7997 18.263C18.6563 18.132 18.5316 18.0198 18.4194 17.9138C18.0577 17.5958 17.8208 17.3775 17.64 17.1718Z"
        fill={fill}
      />
      <path
        d="M32.9227 12.5387C32.6484 12.5387 32.3241 12.5075 31.95 12.4265C31.0085 12.2269 30.6967 11.7343 30.6032 11.3602C30.4722 10.8427 30.678 10.0321 31.4325 9.3836C32.056 8.84736 32.2743 8.37347 32.2743 8.36724L32.5985 7.6377L32.9976 8.32982C33.1721 8.63536 33.3343 8.77877 33.989 9.31501C34.1012 9.40855 34.2322 9.51455 34.3756 9.63302C34.8183 10.0009 34.9305 10.4124 34.943 10.6868C34.9742 11.3353 34.5439 11.8528 34.4317 11.9775C34.3132 12.1272 33.9079 12.5387 32.9227 12.5387ZM32.6796 9.24643C32.5112 9.46466 32.2805 9.72655 31.9625 10.0009C31.6133 10.3002 31.3078 10.8177 31.3951 11.1607C31.4636 11.4413 31.8315 11.5722 32.1246 11.6346C33.328 11.8902 33.7209 11.5535 33.777 11.4974L33.8019 11.4475L33.8269 11.4413C33.9142 11.3477 34.1511 11.0297 34.1324 10.7304C34.1199 10.5621 34.0326 10.4062 33.858 10.2628C33.7146 10.1443 33.5899 10.0383 33.4777 9.94479C33.1036 9.63302 32.8604 9.43349 32.6796 9.24643Z"
        fill={fill}
      />
      <path
        d="M32.937 5.14956C32.6626 5.14956 32.3384 5.11838 31.9643 5.0498C30.9978 4.86273 30.686 4.38261 30.5987 4.02096C30.4366 3.34753 30.9105 2.62423 31.4467 2.20646C32.064 1.72633 32.276 1.30233 32.2823 1.29609L32.6003 0.628906L32.9931 1.25868C33.1677 1.53304 33.3236 1.65775 33.9783 2.15034C34.0905 2.23764 34.2215 2.33117 34.3649 2.4434C34.8201 2.79259 34.9323 3.17918 34.9448 3.44107C34.9759 4.0459 34.5457 4.51979 34.421 4.6445C34.2963 4.78791 33.8972 5.14956 32.937 5.14956ZM32.6751 2.13787C32.5067 2.3374 32.2698 2.58682 31.9455 2.83623C31.5777 3.12306 31.322 3.56577 31.3844 3.82142C31.4467 4.07708 31.8645 4.19555 32.1139 4.24543C33.3173 4.47614 33.7226 4.17684 33.785 4.12072L33.8099 4.07084L33.8349 4.0646C33.9159 3.98978 34.1404 3.70919 34.1279 3.46601C34.1279 3.41612 34.1155 3.26024 33.866 3.07318C33.7226 2.96718 33.5979 2.86741 33.4857 2.78635C33.1116 2.50576 32.8684 2.3187 32.6751 2.13787Z"
        fill={fill}
      />
      <path
        d="M23.5086 19.0542H19.6488V18.2436H22.698V16.3419H11.1562V15.5312H23.5086V19.0542Z"
        fill={fill}
      />
      <path
        d="M22.6852 15.7431H11.5176V15.3378C11.5176 13.1492 13.2947 11.3721 15.4833 11.3721H18.7132C20.9018 11.3721 22.6789 13.1492 22.6789 15.3378V15.7431H22.6852ZM12.3594 14.9325H21.8558C21.6563 13.3799 20.3282 12.1827 18.7257 12.1827H15.4958C13.887 12.1827 12.5589 13.3799 12.3594 14.9325Z"
        fill={fill}
      />
      <path
        d="M47.3796 20.6325C47.099 20.6325 46.7748 20.5951 46.3944 20.5078C45.4841 20.2958 45.166 19.7907 45.0663 19.4041C44.8605 18.6372 45.3406 17.7517 45.8706 17.2591C46.5004 16.673 46.7187 16.143 46.7187 16.1368L47.0491 15.3262L47.4544 16.0994C47.6415 16.4548 47.8286 16.6231 48.4521 17.1843C48.5643 17.2903 48.6953 17.4026 48.8387 17.5335C49.3438 17.9949 49.5121 18.606 49.3063 19.2482C49.1816 19.6348 48.9634 19.9217 48.8886 20.0089C48.7763 20.1773 48.3773 20.6325 47.3796 20.6325ZM47.124 17.0409C46.9618 17.2778 46.7311 17.5584 46.4194 17.8515C46.0515 18.1944 45.7335 18.793 45.8457 19.1983C45.9143 19.4478 46.1575 19.6224 46.5752 19.7221C47.7849 20.0027 48.1653 19.6286 48.2089 19.5662L48.2276 19.5164L48.2526 19.5039C48.2526 19.5039 48.4459 19.2794 48.5332 18.9863C48.6329 18.6496 48.5519 18.3815 48.2837 18.1321C48.1403 18.0012 48.0156 17.8889 47.9034 17.7829C47.548 17.4649 47.3048 17.2467 47.124 17.0409Z"
        fill={fill}
      />
      <path
        d="M45.4139 19.0543H42.0156V15.4004H54.7732V18.9234H49.0928V18.1128H53.9626V16.211H42.8262V18.2437H45.4139V19.0543Z"
        fill={fill}
      />
      <path
        d="M53.9488 15.6122H42.7812V15.2069C42.7812 13.0183 44.5583 11.2412 46.747 11.2412H49.9769C52.1655 11.2412 53.9426 13.0183 53.9426 15.2069V15.6122H53.9488ZM43.623 14.8016H53.1195C52.92 13.249 51.5918 12.0518 49.9894 12.0518H46.7594C45.1507 12.0518 43.8226 13.2552 43.623 14.8016Z"
        fill={fill}
      />
      <path
        d="M43.8493 15.9177H39.709V15.1071H43.0387V12.5381H22.0254V12.9809H21.2148V11.7275H43.8493V15.9177Z"
        fill={fill}
      />
      <path d="M25.7286 14.9326H22.5859V15.7432H25.7286V14.9326Z" fill={fill} />
      <path
        d="M23.2034 20.4133L22.4863 20.0392C22.505 20.008 24.1512 16.828 24.8308 15.4749C25.5853 13.9597 28.4972 13.1304 30.3242 13.0244L30.3741 13.835C29.5011 13.8849 28.4224 14.1218 27.5495 14.4461C26.5206 14.8327 25.8098 15.3253 25.5541 15.8428C24.8745 17.1959 23.2221 20.3822 23.2034 20.4133Z"
        fill={fill}
      />
      <path
        d="M42.1333 20.6506C41.6656 20.1954 41.5097 19.7839 41.3102 19.2663C41.0857 18.6864 40.7864 17.8883 39.8511 16.5165C38.2174 14.1159 35.3492 13.9662 35.3242 13.96L35.3554 13.1494C35.4863 13.1556 38.6539 13.3115 40.5245 16.0551C41.516 17.5079 41.8402 18.356 42.0709 18.9733C42.258 19.4596 42.3577 19.734 42.7007 20.0645L42.1333 20.6506Z"
        fill={fill}
      />
      <path
        d="M35.2894 14.5839H29.9395V12.3828H30.7501V13.7733H34.4788V12.3828H35.2894V14.5839Z"
        fill={fill}
      />
      <path
        d="M40.314 12.5268C39.9149 12.2836 39.678 11.7224 39.3787 11.0116C39.0357 10.201 38.6117 9.19087 37.851 8.47381C36.6226 7.31402 35.3257 7.17061 35.1636 7.1519C34.9516 7.16437 33.5548 7.22049 30.8986 7.1519C30.8611 7.1519 30.83 7.1519 30.7925 7.1519C28.2797 7.1519 26.7645 9.49641 26.4901 10.1387C26.1971 10.8245 25.3304 12.1589 25.2929 12.215L24.6133 11.7723C24.6195 11.7599 25.48 10.4442 25.7419 9.82065C26.1347 8.89158 27.9367 6.26648 30.911 6.34754C33.7107 6.42236 35.1261 6.34754 35.1386 6.34754H35.1573H35.176C35.2446 6.35377 36.8596 6.44731 38.3935 7.88768C39.2789 8.71699 39.7403 9.82065 40.1082 10.6998C40.314 11.18 40.5385 11.7287 40.7193 11.8347L40.314 12.5268Z"
        fill={fill}
      />
      <path
        d="M35.2881 6.39651H34.4775V5.6358H31.0294V6.39651H30.2188V4.8252H35.2881V6.39651Z"
        fill={fill}
      />
      <path d="M46.3555 19.7969H45.5449V21.5927H46.3555V19.7969Z" fill={fill} />
      <path d="M48.9629 19.7715H48.1523V21.7419H48.9629V19.7715Z" fill={fill} />
      <path d="M52.7911 18.8926H51.9805V24.4795H52.7911V18.8926Z" fill={fill} />
      <path
        d="M35.3128 43.6845H34.5022V35.8155H31.0665V43.6159H30.2559V35.0049H35.3128V43.6845Z"
        fill={fill}
      />
    </svg>
  );
}
export function MumbaiSvg({ fill = "#4E4B66" }: FillProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="56"
      viewBox="0 0 76 56"
      fill="none"
      className="max-w-full"
    >
      <path
        d="M75.8965 55.0195H0.833984V56.0001H75.8965V55.0195Z"
        fill={fill}
      />
      <path
        d="M3.515 32.4506L0.105469 26.499H24.1834V27.4796H1.79515L4.36739 31.9603L3.515 32.4506Z"
        fill={fill}
      />
      <path
        d="M24.1908 20.8789H0.648438V21.8595H24.1908V20.8789Z"
        fill={fill}
      />
      <path
        d="M4.42788 55.5101H3.44727V31.7188H24.1836V32.6994H4.42788V55.5101Z"
        fill={fill}
      />
      <path
        d="M30.9424 55.5106H29.9618V16.0822H24.674V55.5106H23.6934V15.1016H30.9424V55.5106Z"
        fill={fill}
      />
      <path d="M4.42788 21.3701H3.44727V26.9898H4.42788V21.3701Z" fill={fill} />
      <path
        d="M19.5658 55.5104H18.5852V42.5738C18.2986 41.7365 17.6121 40.5145 15.9375 39.3151C15.1455 38.7418 14.4063 38.3798 14.0895 38.2364C9.6465 39.9412 9.5409 42.785 9.53335 42.9057V55.518H8.55273V42.8906C8.55273 42.7397 8.63571 39.1869 13.931 37.2407L14.1121 37.1729L14.2856 37.2407C14.4591 37.3086 18.4645 38.9606 19.5432 42.34L19.5658 42.4154V55.5104Z"
        fill={fill}
      />
      <path
        d="M68.0951 55.5104H67.1145V42.5738C66.8279 41.7365 66.1414 40.5145 64.4668 39.3151C63.6748 38.7418 62.9356 38.3798 62.6188 38.2364C58.1381 39.9638 58.0626 42.868 58.0626 42.8982V55.518H57.082V42.8906C57.082 42.7397 57.165 39.1869 62.4603 37.2407L62.6414 37.1729L62.8149 37.2407C62.9884 37.3086 66.9938 38.9606 68.0725 42.34L68.0951 42.4154V55.5104Z"
        fill={fill}
      />
      <path
        d="M30.9027 39.0907L29.9824 38.7437C30.0051 38.6834 30.5482 37.2577 31.823 35.5001C32.9997 33.8784 35.059 31.6531 38.1668 30.2878L38.3705 30.1973L38.5741 30.2878C38.808 30.3934 44.2617 32.9656 46.7359 38.2534L45.8458 38.6683C43.779 34.2631 39.336 31.7813 38.3554 31.2759C32.962 33.7652 30.9253 39.0379 30.9027 39.0907Z"
        fill={fill}
      />
      <path
        d="M52.9819 55.5106H52.0013V16.0822H46.7814V55.5106H45.8008V15.1016H52.9819V55.5106Z"
        fill={fill}
      />
      <path d="M46.3014 26.9219H30.4531V27.9025H46.3014V26.9219Z" fill={fill} />
      <path d="M46.3014 20.8789H30.4531V21.8595H46.3014V20.8789Z" fill={fill} />
      <path d="M46.3014 16.3154H30.4531V17.296H46.3014V16.3154Z" fill={fill} />
      <path
        d="M73.1737 55.511H72.1931V32.4816H52.4902V31.501H73.1737V55.511Z"
        fill={fill}
      />
      <path
        d="M73.1209 32.2021L72.2384 31.7722L74.1317 27.9025H52.4902V26.9219H75.7007L73.1209 32.2021Z"
        fill={fill}
      />
      <path d="M75.8968 20.8789H52.4902V21.8595H75.8968V20.8789Z" fill={fill} />
      <path d="M73.1779 20.8789H72.1973V26.9286H73.1779V20.8789Z" fill={fill} />
      <path
        d="M29.8529 15.5927H28.8723V6.34474C28.8723 4.59471 27.5372 4.40613 27.3109 4.39104C25.6815 4.45893 25.4779 6.18633 25.4552 6.39V15.4041H24.4746V6.35228V6.33719C24.55 5.35658 25.2365 3.47831 27.3033 3.41797H27.3259H27.3486C27.3712 3.41797 29.8605 3.57638 29.8605 6.35228V15.5927H29.8529Z"
        fill={fill}
      />
      <path d="M29.3582 7.83594H25.0586V8.81656H29.3582V7.83594Z" fill={fill} />
      <path
        d="M27.3204 4.39015C27.0488 4.39015 26.8301 4.1714 26.8301 3.89984V0.490309C26.8301 0.218753 27.0488 0 27.3204 0C27.5919 0 27.8107 0.218753 27.8107 0.490309V3.89984C27.8032 4.1714 27.5844 4.39015 27.3204 4.39015Z"
        fill={fill}
      />
      <path
        d="M52.0834 15.5927H51.1028V6.34474C51.1028 4.59471 49.7676 4.40613 49.5413 4.39104C47.912 4.45893 47.7083 6.18633 47.6857 6.39V15.4041H46.7051V6.35228V6.33719C46.7805 5.35658 47.4669 3.47831 49.5338 3.41797H49.5564H49.579C49.6017 3.41797 52.0909 3.57638 52.0909 6.35228V15.5927H52.0834Z"
        fill={fill}
      />
      <path d="M51.5887 7.83594H47.2891V8.81656H51.5887V7.83594Z" fill={fill} />
      <path
        d="M49.5509 4.39015C49.2793 4.39015 49.0605 4.1714 49.0605 3.89984V0.490309C49.0605 0.218753 49.2793 0 49.5509 0C49.8224 0 50.0412 0.218753 50.0412 0.490309V3.89984C50.0412 4.1714 49.8149 4.39015 49.5509 4.39015Z"
        fill={fill}
      />
      <path d="M47.2935 10.1152H29.3633V11.0959H47.2935V10.1152Z" fill={fill} />
      <path d="M32.1562 21.3701H31.5527V27.4198H32.1562V21.3701Z" fill={fill} />
      <path d="M33.5585 21.3701H32.9551V27.4198H33.5585V21.3701Z" fill={fill} />
      <path d="M34.9726 21.3701H34.3691V27.4198H34.9726V21.3701Z" fill={fill} />
      <path d="M36.371 21.3701H35.7676V27.4198H36.371V21.3701Z" fill={fill} />
      <path d="M37.7773 21.3701H37.1738V27.4198H37.7773V21.3701Z" fill={fill} />
      <path d="M39.1874 21.3701H38.584V27.4198H39.1874V21.3701Z" fill={fill} />
      <path d="M40.5898 21.3701H39.9863V27.4198H40.5898V21.3701Z" fill={fill} />
      <path d="M41.996 21.3701H41.3926V27.4198H41.996V21.3701Z" fill={fill} />
      <path d="M43.4042 21.3701H42.8008V27.4198H43.4042V21.3701Z" fill={fill} />
      <path d="M44.8046 21.3701H44.2012V27.4198H44.8046V21.3701Z" fill={fill} />
      <path d="M7.55794 35.5518H6.33594V36.7738H7.55794V35.5518Z" fill={fill} />
      <path d="M21.7767 35.5518H20.5547V36.7738H21.7767V35.5518Z" fill={fill} />
      <path d="M56.0892 35.5518H54.8672V36.7738H56.0892V35.5518Z" fill={fill} />
      <path d="M70.3021 35.5518H69.0801V36.7738H70.3021V35.5518Z" fill={fill} />
      <path d="M14.6186 23.4668H13.5098V24.8849H14.6186V23.4668Z" fill={fill} />
      <path d="M10.5385 23.4668H9.42969V24.8849H10.5385V23.4668Z" fill={fill} />
      <path d="M18.6987 23.4668H17.5898V24.8849H18.6987V23.4668Z" fill={fill} />
      <path d="M63.144 23.6865H62.0352V25.1046H63.144V23.6865Z" fill={fill} />
      <path d="M59.0639 23.6865H57.9551V25.1046H59.0639V23.6865Z" fill={fill} />
      <path d="M67.2241 23.6865H66.1152V25.1046H67.2241V23.6865Z" fill={fill} />
    </svg>
  );
}
export function PuneSvg({ fill = "#4E4B66" }: FillProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="82"
      height="56"
      viewBox="0 0 82 56"
      fill="none"
      className="max-w-full"
    >
      <path
        d="M81.7235 54.9434H0.943359V56.0004H81.7235V54.9434Z"
        fill={fill}
      />
      <path
        d="M33.8447 54.869H32.7894V21.6938H8.90688V54.869H7.85156V20.6367H33.8447V54.869Z"
        fill={fill}
      />
      <path
        d="M75.4756 54.869H74.4203V21.6938H50.5377V54.869H49.4824V20.6367H75.4756V54.869Z"
        fill={fill}
      />
      <path d="M33.3168 26.2402H8.37891V27.2973H33.3168V26.2402Z" fill={fill} />
      <path d="M74.9457 26.2402H50.0078V27.2973H74.9457V26.2402Z" fill={fill} />
      <path d="M15.4166 30.1172H14.3613V37.6141H15.4166V30.1172Z" fill={fill} />
      <path d="M21.3756 30.1172H20.3203V37.6141H21.3756V30.1172Z" fill={fill} />
      <path d="M27.4323 30.1172H26.377V37.6141H27.4323V30.1172Z" fill={fill} />
      <path d="M56.9967 30.208H55.9414V37.7049H56.9967V30.208Z" fill={fill} />
      <path d="M62.9557 30.208H61.9004V37.7049H62.9557V30.208Z" fill={fill} />
      <path d="M69.0006 30.208H67.9453V37.7049H69.0006V30.208Z" fill={fill} />
      <path d="M50.0085 22.4736H33.3184V23.5307H50.0085V22.4736Z" fill={fill} />
      <path d="M13.3053 21.165H12.25V26.7755H13.3053V21.165Z" fill={fill} />
      <path d="M18.6237 21.165H17.5684V26.7755H18.6237V21.165Z" fill={fill} />
      <path d="M24.2155 21.165H23.1602V26.7755H24.2155V21.165Z" fill={fill} />
      <path d="M29.444 21.165H28.3887V26.7755H29.444V21.165Z" fill={fill} />
      <path d="M54.9342 21.165H53.8789V26.7755H54.9342V21.165Z" fill={fill} />
      <path d="M60.2526 21.165H59.1973V26.7755H60.2526V21.165Z" fill={fill} />
      <path d="M65.8463 21.165H64.791V26.7755H65.8463V21.165Z" fill={fill} />
      <path d="M71.0768 21.165H70.0215V26.7755H71.0768V21.165Z" fill={fill} />
      <path
        d="M12.5748 20.5237H11.5195V15.4092H28.1853V16.4662H12.5748V20.5237Z"
        fill={fill}
      />
      <path d="M28.7135 5.4082H27.6582V20.6216H28.7135V5.4082Z" fill={fill} />
      <path
        d="M57.4364 5.93576H25.3711V0H57.4364V5.93576ZM26.4183 4.8787H56.3729V1.05705H26.4183V4.8787Z"
        fill={fill}
      />
      <path d="M56.0534 5.4082H54.998V20.5322H56.0534V5.4082Z" fill={fill} />
      <path d="M55.5282 12.4316H28.1875V13.4887H55.5282V12.4316Z" fill={fill} />
      <path d="M55.5282 17.084H28.1875V18.141H55.5282V17.084Z" fill={fill} />
      <path d="M35.0846 5.4082H34.0293V12.9702H35.0846V5.4082Z" fill={fill} />
      <path d="M49.3815 5.4082H48.3262V12.9702H49.3815V5.4082Z" fill={fill} />
      <path
        d="M73.5245 21.1661H72.4692V16.4662H55.5273V15.4092H73.5245V21.1661Z"
        fill={fill}
      />
      <path
        d="M47.8718 55.4703H46.8164V34.7602C46.8814 29.5807 41.8646 29.4343 41.6535 29.4343C39.754 29.4343 38.2846 30.3369 37.3186 32.1257C36.6205 33.4105 36.4176 34.7277 36.3932 34.931V54.8686H35.3379V34.866V34.8334C35.346 34.7684 35.5246 33.2153 36.3689 31.646C37.5216 29.5075 39.3562 28.3691 41.6616 28.3691C41.7347 28.3691 43.2527 28.3935 44.7708 29.1823C46.2076 29.9222 47.9123 31.4996 47.8718 34.7521V55.4703Z"
        fill={fill}
      />
    </svg>
  );
}
