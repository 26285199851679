import urlService, { getFromLocalVisitObject } from "../../../lib/urlLocalServices";
import { LeadFormData } from "./utils";
import { leadFormApiBodyType } from "./utils";

export const getBodyForLeadsquare = (
  data: LeadFormData,
  submissionType: "form-submission" | "revisit-on-pageload" = "form-submission"
) => {
  const sessionLocalVisitObject = getFromLocalVisitObject("session_visit");
  for (const [key, value] of Object.entries(sessionLocalVisitObject)) {
    if (value == null)
      delete sessionLocalVisitObject[
        key as keyof typeof sessionLocalVisitObject
      ];
  }
  const {
    landing_page: landingPage = urlService().lpFromUrl,
    utm_source: leadSource = urlService().utmSource,
    utm_campaign: sourceCampaign = urlService().utmCampaign,
    utm_medium: sourceMedium = urlService().utmMedium,
    utm_keyword: searchKeyword = urlService().utmKeyword,
    utm_content: utmSourceContent = urlService().utmContent,
    g_clid: gclID = urlService().gClid,
    f_clid: fblID = urlService().fClid,
  } = sessionLocalVisitObject;
  const leadSquareBody: leadFormApiBodyType = {
    firstName: data["name"],
    phoneNumber: data["mobile"],
    cityName: data["city"],
    otherCity: data["otherCity"],
    referralCode: data["coupon"],
    apiSource: `${urlService().origin}${urlService().path} -- ${
      submissionType == "form-submission"
        ? "form-submission"
        : "revisit-on-pageload"
    }`,
    landingURL: `${window.location.href}`,
    landingPage,
    leadSource,
    sourceCampaign,
    searchKeyword,
    sourceMedium,
    utmSourceContent,
    gclID,
    fblID,
  };
  for (const [key, value] of Object.entries(leadSquareBody)) {
    if (!value) delete leadSquareBody[key as keyof leadFormApiBodyType];
  }
  return leadSquareBody;
};